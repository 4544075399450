var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "operator",
        { attrs: { title: "system_parameter_setting.basic_setting" } },
        [
          _vm.showEdit
            ? _c(
                "deBtn",
                { attrs: { type: "primary" }, on: { click: _vm.edit } },
                [_vm._v(_vm._s(_vm.$t("commons.edit")))]
              )
            : _vm._e(),
          _vm.showCancel
            ? _c(
                "deBtn",
                { attrs: { secondary: "" }, on: { click: _vm.cancel } },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
              )
            : _vm._e(),
          _vm.showSave
            ? _c(
                "deBtn",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabledSave,
                    size: "small",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save("formInline")
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.save")) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formInline",
          staticClass: "demo-form-inline de-form-item",
          attrs: {
            model: _vm.formInline,
            rules: _vm.rules,
            disabled: _vm.show,
            "label-width": "80px",
            "label-position": "right",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "frontTimeOut" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("system_parameter_setting.request_timeout")
                      ) +
                      "\n        "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t(
                          "system_parameter_setting.front_time_out"
                        ),
                        placement: "top",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "tips",
                        attrs: { "icon-class": "icon_info_outlined" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: _vm.$t("system_parameter_setting.empty_front"),
                  },
                  model: {
                    value: _vm.formInline.frontTimeOut,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "frontTimeOut", $$v)
                    },
                    expression: "formInline.frontTimeOut",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("panel.second"))),
                  ]),
                ],
                2
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "system_parameter_setting.message_retention_time"
                ),
                prop: "msgTimeOut",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: _vm.$t("system_parameter_setting.empty_msg"),
                  },
                  model: {
                    value: _vm.formInline.msgTimeOut,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "msgTimeOut", $$v)
                    },
                    expression: "formInline.msgTimeOut",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("components.day"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.log_retention_time"),
                prop: "logTimeOut",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: _vm.$t("system_parameter_setting.empty_msg"),
                  },
                  model: {
                    value: _vm.formInline.logTimeOut,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "logTimeOut", $$v)
                    },
                    expression: "formInline.logTimeOut",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("components.day"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.ds_check_time"),
              },
            },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline-ds",
                  attrs: { inline: true, disabled: _vm.show },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { size: "mini", type: "number", min: "1" },
                        on: {
                          change: function ($event) {
                            return _vm.onSimpleCronChange()
                          },
                        },
                        model: {
                          value: _vm.formInline.dsCheckInterval,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "dsCheckInterval", $$v)
                          },
                          expression: "formInline.dsCheckInterval",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", size: "mini" },
                          on: {
                            change: function ($event) {
                              return _vm.onSimpleCronChange()
                            },
                          },
                          model: {
                            value: _vm.formInline.dsCheckIntervalType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "dsCheckIntervalType",
                                $$v
                              )
                            },
                            expression: "formInline.dsCheckIntervalType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("cron.minute_default"),
                              value: "minute",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("cron.hour_default"),
                              value: "hour",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", {
                    staticClass: "form-item",
                    attrs: { label: _vm.$t("cron.every_exec") },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loginTypes.length > 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("system_parameter_setting.login_type"),
                    prop: "loginType",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formInline.loginType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "loginType", $$v)
                        },
                        expression: "formInline.loginType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0, size: "mini" } }, [
                        _vm._v(_vm._s(_vm.$t("login.default_login"))),
                      ]),
                      _vm.loginTypes.includes(1)
                        ? _c(
                            "el-radio",
                            { attrs: { label: 1, size: "mini" } },
                            [_vm._v("LDAP")]
                          )
                        : _vm._e(),
                      _vm.loginTypes.includes(2)
                        ? _c(
                            "el-radio",
                            { attrs: { label: 2, size: "mini" } },
                            [_vm._v("OIDC")]
                          )
                        : _vm._e(),
                      _vm.loginTypes.includes(3)
                        ? _c(
                            "el-radio",
                            { attrs: { label: 3, size: "mini" } },
                            [_vm._v("CAS")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isPluginLoaded
            ? _c("plugin-com", {
                ref: "LoginLimitSetting",
                attrs: {
                  form: _vm.formInline,
                  "component-name": "LoginLimitSetting",
                },
              })
            : _vm._e(),
          _vm.isPluginLoaded && _vm.scanOpen
            ? _c("plugin-com", {
                ref: "ScanLimitSetting",
                attrs: {
                  form: _vm.formInline,
                  "component-name": "ScanLimitSetting",
                },
              })
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.$t("commons.yes") +
                  _vm.$t("commons.no") +
                  _vm.$t("display.openMarketPage"),
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formInline.openMarketPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "openMarketPage", $$v)
                    },
                    expression: "formInline.openMarketPage",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "true", size: "mini" } }, [
                    _vm._v(_vm._s(_vm.$t("commons.yes"))),
                  ]),
                  _c("el-radio", { attrs: { label: "false", size: "mini" } }, [
                    _vm._v(_vm._s(_vm.$t("commons.no"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.$t("commons.yes") +
                  _vm.$t("commons.no") +
                  _vm.$t("display.openHomePage"),
                prop: "openHomePage",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formInline.openHomePage,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "openHomePage", $$v)
                    },
                    expression: "formInline.openHomePage",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "true", size: "mini" } }, [
                    _vm._v(_vm._s(_vm.$t("commons.yes"))),
                  ]),
                  _c("el-radio", { attrs: { label: "false", size: "mini" } }, [
                    _vm._v(_vm._s(_vm.$t("commons.no"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }