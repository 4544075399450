<template>
  <DeVideo />
</template>

<script>

import DeVideo from '@/components/canvas/customComponent/DeVideo'
export default {
  name: 'VideoDe',
  components: { DeVideo }
}
</script>
