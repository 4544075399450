var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "de-template-list" },
    [
      _c(
        "el-input",
        {
          staticClass: "de-input-search",
          attrs: {
            placeholder: _vm.$t("system_parameter_setting.search_keywords"),
            size: "small",
            clearable: "",
          },
          model: {
            value: _vm.templateFilterText,
            callback: function ($$v) {
              _vm.templateFilterText = $$v
            },
            expression: "templateFilterText",
          },
        },
        [
          _c("svg-icon", {
            attrs: { slot: "prefix", "icon-class": "de-search" },
            slot: "prefix",
          }),
        ],
        1
      ),
      !_vm.templateListComputed.length && _vm.templateFilterText === ""
        ? _c("el-empty", {
            attrs: {
              image: _vm.noneImg,
              description: _vm.$t("components.no_classification"),
            },
          })
        : _vm._e(),
      !_vm.templateListComputed.length && _vm.templateFilterText !== ""
        ? _c("el-empty", {
            attrs: {
              image: _vm.nothingImg,
              description: _vm.$t("components.relevant_content_found"),
            },
          })
        : _vm._e(),
      _c(
        "ul",
        _vm._l(_vm.templateListComputed, function (ele) {
          return _c(
            "li",
            {
              key: ele.name,
              class: [{ select: _vm.activeTemplate === ele.id }],
              on: {
                click: function ($event) {
                  return _vm.nodeClick(ele)
                },
              },
            },
            [
              _c("img", {
                staticStyle: { "margin-right": "8px", "border-radius": "4px" },
                attrs: {
                  src: _vm.iconImgRul(ele.icon),
                  width: "24",
                  height: "24",
                },
              }),
              _c("span", [_vm._v(_vm._s(ele.name))]),
            ]
          )
        }),
        0
      ),
      _c(
        "el-row",
        { staticClass: "de-root-class" },
        [
          _c(
            "deBtn",
            {
              attrs: { secondary: "" },
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n    ")]
          ),
          _c(
            "deBtn",
            {
              attrs: { type: "primary", disabled: !_vm.activeTemplate },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }