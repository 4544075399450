var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout-content", [
    _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          display: "flex",
          "justify-content": "center",
        },
      },
      [
        _c(
          "el-card",
          { staticClass: "box-card about-card" },
          [
            _c("div", { staticClass: "form-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("user.change_password")))]),
            ]),
            _c("PasswordUpdateForm"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }