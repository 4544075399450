var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "demo_main" },
    [
      _c("el-row", { staticClass: "demo_content" }, [
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("wizard.enterprise_edition_hint1")) +
              "\n    "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("wizard.enterprise_edition_hint2")) +
              "\n    "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("wizard.enterprise_edition_hint3")) +
              "\n    "
          ),
        ]),
      ]),
      _c("el-row", { staticClass: "demo_bottom" }, [
        _c(
          "a",
          {
            attrs: { href: "https://jinshuju.net/f/TK5TTd", target: "_blank" },
          },
          [_vm._v(" " + _vm._s(_vm.$t("wizard.click_inner")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }