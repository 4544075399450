var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-table-detail" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "de-tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("datasource.data_source_configuration"),
              name: "detail",
            },
          }),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("datasource.data_source_table"),
              name: "table",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-tabs-content" },
        [
          _vm.activeName === "table"
            ? _c("ds-table-tabs", { attrs: { params: _vm.params } })
            : [
                _c(
                  "div",
                  { staticClass: "ds-top" },
                  [
                    _vm.privileges && _vm.canEdit
                      ? _c(
                          "deBtn",
                          {
                            key: "cancel",
                            attrs: { secondary: "" },
                            on: {
                              click: function ($event) {
                                return _vm.editDatasource(false)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.cancel")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.privileges && !_vm.canEdit
                      ? _c(
                          "deBtn",
                          {
                            key: "edit",
                            attrs: { secondary: "" },
                            on: {
                              click: function ($event) {
                                return _vm.editDatasource(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.edit")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("commons.no_target_permission"),
                          placement: "top",
                        },
                      },
                      [
                        !_vm.privileges
                          ? _c(
                              "span",
                              [
                                _c(
                                  "deBtn",
                                  { attrs: { disabled: "", secondary: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("commons.edit")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm.privileges
                      ? _c(
                          "deBtn",
                          {
                            staticStyle: { "margin-left": "12px" },
                            attrs: { secondary: "" },
                            on: { click: _vm.validaDatasource },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.validate")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.privileges && _vm.canEdit
                      ? _c(
                          "deBtn",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.save },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.save")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { height: "calc(100% - 36px)" } },
                  [
                    _c("ds-form-content", {
                      ref: "DsFormContent",
                      attrs: {
                        canEdit: _vm.canEdit,
                        "config-from-tabs": _vm.configFromTabs,
                      },
                      on: {
                        editeTodisable: _vm.editDatasource,
                        "refresh-type": _vm.refreshType,
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }