var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "de-dataset-search" },
    [
      _c(
        "el-col",
        [
          _c(
            "div",
            {
              staticClass: "title-css",
              staticStyle: { margin: "6px 0 16px 0" },
            },
            [
              _c("span", { staticClass: "title-text" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("dataset.datalist")) + "\n      "
                ),
              ]),
              _c(
                "el-dropdown",
                {
                  attrs: { size: "small", placement: "bottom-start" },
                  on: {
                    command: function (type) {
                      return _vm.clickAddData(type)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("i", {
                      staticClass: "el-icon-plus",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "de-dataset-dropdown de-card-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "db" } },
                        [
                          _c("svg-icon", {
                            staticClass: "ds-icon-db",
                            attrs: { "icon-class": "ds-db" },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("dataset.db_data")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "sql" } },
                        [
                          _c("svg-icon", {
                            staticClass: "ds-icon-sql",
                            attrs: { "icon-class": "ds-sql" },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("dataset.sql_data")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: {
                            command: "excel",
                            disabled:
                              !_vm.kettleRunning && _vm.engineMode !== "simple",
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "ds-icon-excel",
                            attrs: { "icon-class": "ds-excel" },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("dataset.excel_data")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "union" } },
                        [
                          _c("svg-icon", {
                            staticClass: "ds-icon-union",
                            attrs: { "icon-class": "ds-union" },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("dataset.union_data")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "api" } },
                        [
                          _c("svg-icon", {
                            staticClass: "ds-icon-api",
                            attrs: { "icon-class": "ds-api" },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("dataset.api_data")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "de-top-border",
                          attrs: { command: "group" },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "ds-icon-db",
                            attrs: { "icon-class": "scene" },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("deDataset.new_folder")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "6px" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "main-area-input",
                  attrs: {
                    size: "small",
                    placeholder: _vm.$t("deDataset.search_by_name"),
                    "prefix-icon": "el-icon-search",
                    clearable: "",
                  },
                  model: {
                    value: _vm.filterText,
                    callback: function ($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        slot: "append",
                        placeholder: _vm.searchMap[_vm.searchType],
                      },
                      slot: "append",
                      model: {
                        value: _vm.searchType,
                        callback: function ($$v) {
                          _vm.searchType = $$v
                        },
                        expression: "searchType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("commons.all"), value: "all" },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("commons.folder"),
                          value: "folder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-col", { staticClass: "custom-tree-container de-tree" }, [
            _c(
              "div",
              { staticClass: "block" },
              [
                !_vm.tData.length && !_vm.treeLoading
                  ? _c("div", { staticClass: "no-tdata" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("deDataset.no_dataset_click")) +
                          "\n          "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "no-tdata-new",
                          on: {
                            click: function () {
                              return _vm.clickAdd()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("deDataset.create")))]
                      ),
                    ])
                  : _c("el-tree", {
                      ref: "datasetTreeRef",
                      attrs: {
                        "default-expanded-keys": _vm.expandedArray,
                        data: _vm.tData,
                        "node-key": "id",
                        "highlight-current": "",
                        "expand-on-click-node": true,
                        "filter-node-method": _vm.filterNode,
                      },
                      on: {
                        "node-expand": _vm.nodeExpand,
                        "node-collapse": _vm.nodeCollapse,
                        "node-click": _vm.nodeClick,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return data.modelInnerType === "group"
                                ? _c(
                                    "span",
                                    { staticClass: "custom-tree-node father" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                            width: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "scene",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px",
                                                "white-space": "nowrap",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                              },
                                              attrs: { title: data.name },
                                            },
                                            [_vm._v(_vm._s(data.name))]
                                          ),
                                        ]
                                      ),
                                      _vm.hasDataPermission(
                                        "manage",
                                        data.privileges
                                      )
                                        ? _c("span", { staticClass: "child" }, [
                                            data.modelInnerType === "group"
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-dropdown",
                                                      {
                                                        attrs: {
                                                          size: "small",
                                                          placement:
                                                            "bottom-start",
                                                        },
                                                        on: {
                                                          command: function (
                                                            type
                                                          ) {
                                                            return _vm.clickAddData(
                                                              type,
                                                              data
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-dropdown-link",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-plus",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-dropdown-menu",
                                                          {
                                                            staticClass:
                                                              "de-dataset-dropdown de-card-dropdown",
                                                            attrs: {
                                                              slot: "dropdown",
                                                            },
                                                            slot: "dropdown",
                                                          },
                                                          [
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                attrs: {
                                                                  command: "db",
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "ds-icon-db",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "ds-db",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "dataset.db_data"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                attrs: {
                                                                  command:
                                                                    "sql",
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "ds-icon-sql",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "ds-sql",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "dataset.sql_data"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                attrs: {
                                                                  command:
                                                                    "excel",
                                                                  disabled:
                                                                    !_vm.kettleRunning &&
                                                                    _vm.engineMode !==
                                                                      "simple",
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "ds-icon-excel",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "ds-excel",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "dataset.excel_data"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                attrs: {
                                                                  command:
                                                                    "union",
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "ds-icon-union",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "ds-union",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "dataset.union_data"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                attrs: {
                                                                  command:
                                                                    "api",
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "ds-icon-api",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "ds-api",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "dataset.api_data"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                staticClass:
                                                                  "de-top-border",
                                                                attrs: {
                                                                  command:
                                                                    "group",
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "ds-icon-db",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "scene",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "deDataset.new_folder"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "12px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-dropdown",
                                                  {
                                                    attrs: {
                                                      trigger: "click",
                                                      size: "small",
                                                      placement: "bottom-start",
                                                    },
                                                    on: {
                                                      command: function (type) {
                                                        return _vm.clickMore(
                                                          type,
                                                          data,
                                                          node
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-dropdown-link",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-more",
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-dropdown-menu",
                                                      {
                                                        staticClass:
                                                          "de-card-dropdown",
                                                        attrs: {
                                                          slot: "dropdown",
                                                        },
                                                        slot: "dropdown",
                                                      },
                                                      [
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "rename",
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "de-ds-rename",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dataset.rename"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "move",
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "de-ds-move",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dataset.move_to"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "delete",
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "de-ds-trash",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dataset.delete"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "custom-tree-node-list father",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                            width: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              data.modelInnerType === "db"
                                                ? _c("svg-icon", {
                                                    staticClass: "ds-icon-db",
                                                    attrs: {
                                                      "icon-class": "ds-db",
                                                    },
                                                  })
                                                : _vm._e(),
                                              data.modelInnerType === "sql"
                                                ? _c("svg-icon", {
                                                    staticClass: "ds-icon-sql",
                                                    attrs: {
                                                      "icon-class": "ds-sql",
                                                    },
                                                  })
                                                : _vm._e(),
                                              data.modelInnerType === "excel"
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "ds-icon-excel",
                                                    attrs: {
                                                      "icon-class": "ds-excel",
                                                    },
                                                  })
                                                : _vm._e(),
                                              data.modelInnerType === "custom"
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "ds-icon-custom",
                                                    attrs: {
                                                      "icon-class": "ds-custom",
                                                    },
                                                  })
                                                : _vm._e(),
                                              data.modelInnerType === "union"
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "ds-icon-union",
                                                    attrs: {
                                                      "icon-class": "ds-union",
                                                    },
                                                  })
                                                : _vm._e(),
                                              data.modelInnerType === "api"
                                                ? _c("svg-icon", {
                                                    staticClass: "ds-icon-api",
                                                    attrs: {
                                                      "icon-class": "ds-api",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          ["db", "sql"].includes(
                                            data.modelInnerType
                                          )
                                            ? _c("span", [
                                                data.mode === 0
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "6px",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-s-operation",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                data.mode === 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "6px",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-alarm-clock",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              class: [
                                                {
                                                  "de-fill-block": ![
                                                    "db",
                                                    "sql",
                                                  ].includes(
                                                    data.modelInnerType
                                                  ),
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-left": "6px",
                                                "white-space": "nowrap",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                              },
                                              attrs: { title: data.name },
                                            },
                                            [_vm._v(_vm._s(data.name))]
                                          ),
                                        ]
                                      ),
                                      _vm.hasDataPermission(
                                        "manage",
                                        data.privileges
                                      )
                                        ? _c("span", { staticClass: "child" }, [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "12px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-dropdown",
                                                  {
                                                    attrs: {
                                                      trigger: "click",
                                                      size: "small",
                                                      placement: "bottom-start",
                                                    },
                                                    on: {
                                                      command: function (type) {
                                                        return _vm.clickMore(
                                                          type,
                                                          data,
                                                          node
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-dropdown-link",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-more",
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-dropdown-menu",
                                                      {
                                                        staticClass:
                                                          "de-card-dropdown",
                                                        attrs: {
                                                          slot: "dropdown",
                                                        },
                                                        slot: "dropdown",
                                                      },
                                                      [
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command:
                                                                "editTable",
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "de-ds-rename",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dataset.rename"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "moveDs",
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "de-ds-move",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dataset.move_to"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command:
                                                                "deleteTable",
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "de-ds-trash",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dataset.delete"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
              ],
              1
            ),
          ]),
          _c(
            "el-dialog",
            {
              staticClass: "de-dialog-form",
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.editGroup,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editGroup = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "groupForm",
                  staticClass: "de-form-item",
                  attrs: {
                    model: _vm.groupForm,
                    rules: _vm.groupFormRules,
                    "before-close": _vm.close,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.saveGroup(_vm.groupForm)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("deDataset.folder_name"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入文件夹名称" },
                        model: {
                          value: _vm.groupForm.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.groupForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "groupForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "deBtn",
                    {
                      attrs: { secondary: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n        ")]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveGroup(_vm.groupForm)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "de-dialog-form",
          attrs: {
            title: _vm.$t("deDataset.edit_dataset"),
            visible: _vm.editTable,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editTable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "tableForm",
              staticClass: "de-form-item",
              attrs: { model: _vm.tableForm, rules: _vm.tableFormRules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.saveTable(_vm.tableForm)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("dataset.name"), prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.tableForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableForm, "name", $$v)
                      },
                      expression: "tableForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "deBtn",
                {
                  attrs: { secondary: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeTable()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n      ")]
              ),
              _c(
                "deBtn",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveTable(_vm.tableForm)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.moveGroup,
            "custom-class": "de-user-drawer sql-dataset-drawer",
            size: "600px",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moveGroup = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("dataset.m1")) + "\n      "),
            _c(
              "span",
              {
                staticClass: "text-overflow",
                attrs: { title: _vm.moveDialogTitle },
              },
              [_vm._v(_vm._s(_vm.moveDialogTitle))]
            ),
            _vm._v("\n      " + _vm._s(_vm.$t("dataset.m2")) + "\n    "),
          ]),
          _c("group-move-selector", {
            attrs: { "move-dir": "", item: _vm.groupForm },
            on: { targetGroup: _vm.targetGroup },
          }),
          _c(
            "div",
            { staticClass: "de-foot" },
            [
              _c(
                "deBtn",
                {
                  attrs: { secondary: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeMoveGroup()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n      ")]
              ),
              _c(
                "deBtn",
                {
                  attrs: {
                    disabled: _vm.groupMoveConfirmDisabled,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveMoveGroup(_vm.tGroup)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.moveDs,
            "custom-class": "de-user-drawer sql-dataset-drawer",
            size: "600px",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moveDs = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("dataset.m1")) + "\n      "),
            _c(
              "span",
              {
                staticClass: "text-overflow",
                attrs: { title: _vm.moveDialogTitle },
              },
              [_vm._v(_vm._s(_vm.moveDialogTitle))]
            ),
            _vm._v("\n      " + _vm._s(_vm.$t("dataset.m2")) + "\n    "),
          ]),
          _c("group-move-selector", {
            attrs: { item: _vm.groupForm },
            on: { targetGroup: _vm.targetDs },
          }),
          _c(
            "div",
            { staticClass: "de-foot" },
            [
              _c(
                "deBtn",
                {
                  attrs: { secondary: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeMoveDs()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n      ")]
              ),
              _c(
                "deBtn",
                {
                  attrs: {
                    disabled: _vm.dsMoveConfirmDisabled,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveMoveDs(_vm.tDs)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("CreatDsGroup", { ref: "CreatDsGroup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }