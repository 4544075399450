var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "ds-node", on: { click: _vm.nodeClick } },
        [
          _vm.currentNode.currentDs.modelInnerType === "db"
            ? _c("svg-icon", {
                staticClass: "ds-icon-db",
                attrs: { "icon-class": "ds-db" },
              })
            : _vm.currentNode.currentDs.modelInnerType === "sql"
            ? _c("svg-icon", {
                staticClass: "ds-icon-sql",
                attrs: { "icon-class": "ds-sql" },
              })
            : _vm.currentNode.currentDs.modelInnerType === "excel"
            ? _c("svg-icon", {
                staticClass: "ds-icon-excel",
                attrs: { "icon-class": "ds-excel" },
              })
            : _vm.currentNode.currentDs.modelInnerType === "api"
            ? _c("svg-icon", {
                staticClass: "ds-icon-api",
                attrs: { "icon-class": "ds-api" },
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "node-name",
              attrs: { title: _vm.currentNode.currentDs.name },
            },
            [_vm._v(_vm._s(_vm.currentNode.currentDs.name))]
          ),
          _c(
            "span",
            {
              staticClass: "node-menu",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click", size: "small" },
                  on: { command: _vm.nodeMenuClick },
                },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link" },
                    [
                      _c("el-button", {
                        attrs: {
                          icon: "el-icon-more",
                          type: "text",
                          size: "small",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: {
                            disabled:
                              _vm.currentNode.currentDs.mode === 0 &&
                              _vm.currentNode.currentDs.modelInnerType ===
                                "sql",
                            icon: "el-icon-copy-document",
                            command: _vm.beforeNodeMenuClick(
                              "union",
                              _vm.currentNode
                            ),
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v(_vm._s(_vm.$t("dataset.union"))),
                          ]),
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: {
                            icon: "el-icon-edit-outline",
                            command: _vm.beforeNodeMenuClick(
                              "edit",
                              _vm.currentNode
                            ),
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v(_vm._s(_vm.$t("dataset.edit"))),
                          ]),
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: {
                            icon: "el-icon-delete",
                            command: _vm.beforeNodeMenuClick(
                              "delete",
                              _vm.currentNode
                            ),
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v(_vm._s(_vm.$t("dataset.delete"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectDsDialog
        ? _c(
            "el-drawer",
            {
              directives: [{ name: "closePress", rawName: "v-closePress" }],
              attrs: {
                title: _vm.$t("chart.select_dataset"),
                visible: _vm.selectDsDialog,
                "custom-class": "de-user-drawer sql-dataset-drawer",
                size: "600px",
                direction: "rtl",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.selectDsDialog = $event
                },
              },
            },
            [
              _c("dataset-tree", {
                attrs: {
                  "fix-height": true,
                  "show-mode": "union",
                  "custom-type": _vm.customType,
                  "clear-empty-dir": true,
                  mode: _vm.currentNode.currentDs.mode,
                },
                on: { getTable: _vm.firstDs },
              }),
              _c(
                "div",
                { staticClass: "de-foot" },
                [
                  _c(
                    "deBtn",
                    {
                      attrs: { secondary: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeSelectDs()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { disabled: !_vm.tempDs.id, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSelectDs()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.editField
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "dialog-css",
              attrs: {
                title: _vm.$t("dataset.field_select"),
                visible: _vm.editField,
                "show-close": false,
                width: "400px",
              },
            },
            [
              _c("union-field-edit", { attrs: { node: _vm.currentNode } }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.closeEditField()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmEditField()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }