var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.noLayout && _vm.menuid !== 740
    ? _c(
        "de-layout-content",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value:
                _vm.jsname &&
                !_vm.innerLoadingNames.includes(_vm.jsname) &&
                _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
              expression:
                "jsname && !innerLoadingNames.includes(jsname) && $store.getters.loadingMap[$store.getters.currentPath]",
            },
          ],
          attrs: { header: _vm.header, "back-name": _vm.backName },
        },
        [
          _vm.showAsync
            ? _c("async-component", {
                attrs: { url: _vm.url },
                on: {
                  "execute-axios": _vm.executeAxios,
                  "on-add-languages": _vm.addLanguages,
                  "on-plugin-layout": _vm.setLayoutInfo,
                  "plugin-call-back": _vm.pluginCallBack,
                },
              })
            : _c("div", [_c("h1", [_vm._v("未知组件无法展示")])]),
        ],
        1
      )
    : _vm.menuid === 740
    ? _c(
        "div",
        [
          _vm.showAsync
            ? _c("async-component", {
                attrs: { url: _vm.url },
                on: {
                  "execute-axios": _vm.executeAxios,
                  "on-add-languages": _vm.addLanguages,
                  "on-plugin-layout": _vm.setLayoutInfo,
                  "plugin-call-back": _vm.pluginCallBack,
                },
              })
            : _c("div", [_c("h1", [_vm._v("未知组件无法展示")])]),
        ],
        1
      )
    : _c(
        "div",
        [
          _vm.showAsync
            ? _c("async-component", {
                attrs: { url: _vm.url },
                on: {
                  "execute-axios": _vm.executeAxios,
                  "on-add-languages": _vm.addLanguages,
                  "on-plugin-layout": _vm.setLayoutInfo,
                  "plugin-call-back": _vm.pluginCallBack,
                },
              })
            : _c("div", [_c("h1", [_vm._v("未知组件无法展示")])]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }