var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-container" },
    [
      _vm.$slots.header || _vm.header
        ? _c(
            "div",
            { staticClass: "content-container__header" },
            [
              _vm._t("header", [
                _vm.showBack
                  ? _c("back-button", {
                      attrs: {
                        path: _vm.backPath,
                        name: _vm.backName,
                        to: _vm.backTo,
                      },
                    })
                  : _vm._e(),
                _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.$slots.toolbar
        ? _c(
            "div",
            { staticClass: "content-container__toolbar" },
            [_vm._t("toolbar")],
            2
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }