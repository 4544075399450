var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-row", { staticClass: "video_main" }, [
    _c("div", { staticClass: "video_top" }, [
      _c(
        "a",
        { attrs: { href: _vm.details.href, target: "_blank" } },
        [
          _c("img", {
            attrs: { src: require("@/assets/" + _vm.details.img), alt: "404" },
          }),
          _c("svg-icon", {
            staticClass: "play-icon",
            attrs: { "icon-class": "round_play" },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "video_content" }, [
      _c("a", { attrs: { href: _vm.details.href, target: "_blank" } }, [
        _vm._v("\n      " + _vm._s(_vm.details.content) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }