var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "user-editer-form",
      attrs: {
        title:
          _vm.formType == "add" ? _vm.$t("user.create") : _vm.$t("user.modify"),
        visible: _vm.dialogVisible,
        width: "840px",
        "before-close": _vm.reset,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.formType === "add"
        ? _c(
            "div",
            { staticClass: "editer-form-title" },
            [
              _c("i", { staticClass: "el-icon-info" }),
              _c("span", { staticClass: "pwd", attrs: { type: "text" } }, [
                _vm._v(
                  _vm._s(_vm.$t("commons.default_pwd") + "：" + _vm.defaultPWD)
                ),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.defaultPWD,
                      expression: "defaultPWD",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error",
                    },
                  ],
                  staticClass: "btn-text",
                  attrs: { type: "text" },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.copy")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "createUserForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rule,
            size: "small",
            "label-width": "80px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("commons.nick_name"),
                        prop: "nickName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("user.input_name") },
                        model: {
                          value: _vm.form.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nickName", $$v)
                          },
                          expression: "form.nickName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ID", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("user.input_id"),
                          disabled: _vm.formType !== "add",
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("commons.email"), prop: "email" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("user.input_email") },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("commons.mobile_phone_number"),
                        prop: "phone",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: _vm.$t("commons.mobile_phone"),
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                slot: "prepend",
                                placeholder: _vm.$t(
                                  "fu.search_bar.please_select"
                                ),
                              },
                              slot: "prepend",
                              model: {
                                value: _vm.form.phonePrefix,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "phonePrefix", $$v)
                                },
                                expression: "form.phonePrefix",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "+86", value: "+86" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("commons.gender"),
                        prop: "gender",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "de-form-gender-select",
                          attrs: { placeholder: _vm.$t("user.select_gender") },
                          model: {
                            value: _vm.form.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("commons.man"),
                              value: "男",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("commons.woman"),
                              value: "女",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("commons.keep_secret"),
                              value: "保密",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPluginLoaded,
                          expression: "isPluginLoaded",
                        },
                      ],
                      attrs: {
                        label: _vm.$t("commons.organization"),
                        prop: "deptId",
                      },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            "popper-class": "user-popper dept",
                            width: "384",
                            trigger: "click",
                          },
                        },
                        [
                          _vm.dialogVisible
                            ? _c("el-tree", {
                                attrs: {
                                  load: _vm.loadNode,
                                  lazy: true,
                                  "expand-on-click-node": false,
                                  data: _vm.depts,
                                  props: _vm.defaultProps,
                                },
                                on: { "node-click": _vm.handleNodeClick },
                              })
                            : _vm._e(),
                          _c(
                            "el-select",
                            {
                              staticClass: "de-form-gender-select",
                              attrs: {
                                slot: "reference",
                                clearable: "",
                                "popper-class": "tree-select",
                                placeholder: _vm.$t("commons.please_select"),
                              },
                              slot: "reference",
                              model: {
                                value: _vm.form.deptId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "deptId", $$v)
                                },
                                expression: "form.deptId",
                              },
                            },
                            _vm._l(_vm.selectDepts, function (item) {
                              return _c("el-option", {
                                key: item.label,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isPluginLoaded,
                  expression: "isPluginLoaded",
                },
              ],
              attrs: { label: _vm.$t("commons.role"), prop: "roleIds" },
            },
            [
              _c(
                "el-select",
                {
                  ref: "roleSelect",
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.formType !== "add" && _vm.form.isAdmin,
                    multiple: "",
                    filterable: "",
                    placeholder: _vm.$t("user.input_roles"),
                  },
                  on: { "remove-tag": _vm.deleteTag, change: _vm.changeRole },
                  model: {
                    value: _vm.form.roleIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleIds", $$v)
                    },
                    expression: "form.roleIds",
                  },
                },
                _vm._l(_vm.roles, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("commons.status"), prop: "enabled" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.formType !== "add" && _vm.form.isAdmin,
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.form.enabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "enabled", $$v)
                  },
                  expression: "form.enabled",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("deBtn", { attrs: { secondary: "" }, on: { click: _vm.reset } }, [
            _vm._v(_vm._s(_vm.$t("commons.cancel"))),
          ]),
          _c("deBtn", { attrs: { type: "primary" }, on: { click: _vm.save } }, [
            _vm._v(_vm._s(_vm.$t("commons.confirm"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }