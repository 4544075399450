var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _vm.hasDataPermission("manage", _vm.param.privileges)
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.showUnionEdit },
            },
            [_vm._v(_vm._s(_vm.$t("dataset.add_union")))]
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                size: "mini",
                data: _vm.unionData,
                height: _vm.height,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "sourceTableName",
                  label: _vm.$t("dataset.source_table"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sourceTableFieldName",
                  label: _vm.$t("dataset.source_field"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sourceUnionRelation",
                  label: _vm.$t("dataset.union_relation"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { "font-size": "12px" } }, [
                          scope.row.sourceUnionRelation === "1:N"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("dataset.left_join"))),
                              ])
                            : _vm._e(),
                          scope.row.sourceUnionRelation === "N:1"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("dataset.right_join"))),
                              ])
                            : _vm._e(),
                          scope.row.sourceUnionRelation === "1:1"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("dataset.inner_join"))),
                              ])
                            : _vm._e(),
                          scope.row.sourceUnionRelation === "N:N"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("dataset.full_join"))),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetTableName",
                  label: _vm.$t("dataset.target_table"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetTableFieldName",
                  label: _vm.$t("dataset.target_field"),
                },
              }),
              _c("el-table-column", {
                attrs: { align: "left", label: _vm.$t("dataset.operate") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.hasDataPermission("manage", _vm.param.privileges)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("dataset.edit")))]
                            )
                          : _vm._e(),
                        _vm.hasDataPermission("manage", _vm.param.privileges)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteUnion(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("dataset.delete")))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "dialog-css",
          attrs: {
            title: _vm.$t("dataset.union_setting"),
            visible: _vm.editUnion,
            "show-close": false,
            width: "600px",
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "table-name-css",
                      attrs: { title: _vm.table.name },
                    },
                    [_vm._v(_vm._s(_vm.table.name))]
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("dataset.pls_slc_union_field"),
                        filterable: "",
                        clearable: "",
                        size: "mini",
                      },
                      model: {
                        value: _vm.union.sourceTableFieldId,
                        callback: function ($$v) {
                          _vm.$set(_vm.union, "sourceTableFieldId", $$v)
                        },
                        expression: "union.sourceTableFieldId",
                      },
                    },
                    _vm._l(_vm.sourceFieldOption, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c("span", [
                            item.deType === 0
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 0
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-text",
                                          attrs: { "icon-class": "field_text" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.deType === 1
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 1
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-time",
                                          attrs: { "icon-class": "field_time" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.deType === 2 || item.deType === 3
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 2 || item.deType === 3
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-value",
                                          attrs: {
                                            "icon-class": "field_value",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.deType === 5
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 5
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-location",
                                          attrs: {
                                            "icon-class": "field_location",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.name) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: {
                        display: "block",
                        width: "100%",
                        "text-align": "center",
                      },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.union.sourceUnionRelation,
                        callback: function ($$v) {
                          _vm.$set(_vm.union, "sourceUnionRelation", $$v)
                        },
                        expression: "union.sourceUnionRelation",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "union-relation-css",
                          attrs: { label: "1:N" },
                        },
                        [_vm._v(_vm._s(_vm.$t("dataset.left_join")))]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticClass: "union-relation-css",
                          attrs: { label: "N:1" },
                        },
                        [_vm._v(_vm._s(_vm.$t("dataset.right_join")))]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticClass: "union-relation-css",
                          attrs: { label: "1:1" },
                        },
                        [_vm._v(_vm._s(_vm.$t("dataset.inner_join")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "targetTable",
                      attrs: {
                        placement: "bottom",
                        width: "500",
                        trigger: "click",
                      },
                    },
                    [
                      _c("dataset-group-selector-tree", {
                        attrs: {
                          "fix-height": true,
                          "show-mode": "union",
                          table: _vm.table,
                          "custom-type": _vm.customType,
                          mode: _vm.table.mode,
                        },
                        on: { getTable: _vm.getTable },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { slot: "reference", size: "mini" },
                          slot: "reference",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "table-name-css",
                              attrs: {
                                title:
                                  _vm.targetTable.name ||
                                  _vm.$t("dataset.pls_slc_union_table"),
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.targetTable.name ||
                                      _vm.$t("dataset.pls_slc_union_table")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("dataset.pls_slc_union_field"),
                        filterable: "",
                        clearable: "",
                        size: "mini",
                      },
                      model: {
                        value: _vm.union.targetTableFieldId,
                        callback: function ($$v) {
                          _vm.$set(_vm.union, "targetTableFieldId", $$v)
                        },
                        expression: "union.targetTableFieldId",
                      },
                    },
                    _vm._l(_vm.targetFieldOption, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c("span", [
                            item.deType === 0
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 0
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-text",
                                          attrs: { "icon-class": "field_text" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.deType === 1
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 1
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-time",
                                          attrs: { "icon-class": "field_time" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.deType === 2 || item.deType === 3
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 2 || item.deType === 3
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-value",
                                          attrs: {
                                            "icon-class": "field_value",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.deType === 5
                              ? _c(
                                  "span",
                                  [
                                    item.deType === 5
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-location",
                                          attrs: {
                                            "icon-class": "field_location",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.name) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.closeUnion } },
                [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.saveUnion },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }