var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-col",
        [
          _c(
            "el-form",
            {
              ref: "colorForm",
              attrs: {
                model: _vm.colorForm,
                "label-width": "80px",
                size: "mini",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.chart.type &&
                            !_vm.chart.type.includes("table") &&
                            !_vm.chart.type.includes("text") &&
                            _vm.chart.type !== "label",
                          expression:
                            "chart.type && !chart.type.includes('table') && !chart.type.includes('text') && chart.type !== 'label'",
                        },
                      ],
                      staticClass: "form-item",
                      attrs: { label: _vm.$t("chart.color_case") },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "400",
                            trigger: "click",
                          },
                        },
                        [
                          _c("div", { staticStyle: { padding: "6px 10px" } }, [
                            _c(
                              "div",
                              [
                                _c("span", { staticClass: "color-label" }, [
                                  _vm._v(_vm._s(_vm.$t("chart.system_case"))),
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "chart.pls_slc_color_case"
                                      ),
                                      size: "mini",
                                    },
                                    on: { change: _vm.changeColorOption },
                                    model: {
                                      value: _vm.colorForm.value,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.colorForm, "value", $$v)
                                      },
                                      expression: "colorForm.value",
                                    },
                                  },
                                  _vm._l(_vm.colorCases, function (option) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: option.value,
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                        attrs: {
                                          label: option.name,
                                          value: option.value,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { float: "left" } },
                                          _vm._l(
                                            option.colors,
                                            function (c, index) {
                                              return _c("span", {
                                                key: index,
                                                style: {
                                                  width: "20px",
                                                  height: "20px",
                                                  float: "left",
                                                  backgroundColor: c,
                                                },
                                              })
                                            }
                                          ),
                                          0
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "4px",
                                            },
                                          },
                                          [_vm._v(_vm._s(option.name))]
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "2px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: { click: _vm.resetCustomColor },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("commons.reset")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-top": "10px",
                                },
                              },
                              [
                                _c("span", { staticClass: "color-label" }),
                                _c(
                                  "span",
                                  _vm._l(
                                    _vm.colorForm.colors,
                                    function (c, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: index,
                                          staticStyle: { padding: "2px" },
                                        },
                                        [
                                          _c("span", {
                                            style: {
                                              width: "20px",
                                              height: "20px",
                                              display: "inline-block",
                                              backgroundColor: c,
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "custom-color-style" },
                              _vm._l(
                                _vm.colorForm.seriesColors,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        margin: "2px 0",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "span-label",
                                          attrs: { title: item.name },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c("el-color-picker", {
                                        staticClass: "color-picker-style",
                                        attrs: {
                                          predefine: _vm.predefineColors,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.switchCustomColor(index)
                                          },
                                        },
                                        model: {
                                          value: item.color,
                                          callback: function ($$v) {
                                            _vm.$set(item, "color", $$v)
                                          },
                                          expression: "item.color",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                "margin-top": "2px",
                                width: "180px",
                              },
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            _vm._l(_vm.colorForm.colors, function (c, index) {
                              return _c("span", {
                                key: index,
                                style: {
                                  width: "20px",
                                  height: "20px",
                                  display: "inline-block",
                                  backgroundColor: c,
                                },
                              })
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.chart.type &&
                              (_vm.chart.type.includes("text") ||
                                _vm.chart.type === "label")) ||
                            _vm.sourceType === "panelTable",
                          expression:
                            "(chart.type && (chart.type.includes('text') || chart.type === 'label')) || sourceType==='panelTable'",
                        },
                      ],
                      staticClass: "form-item",
                      attrs: { label: _vm.$t("chart.quota_color") },
                    },
                    [
                      _c("el-color-picker", {
                        staticClass: "color-picker-style",
                        attrs: { predefine: _vm.predefineColors },
                        on: { change: _vm.changeColorCase },
                        model: {
                          value: _vm.colorForm.quotaColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.colorForm, "quotaColor", $$v)
                          },
                          expression: "colorForm.quotaColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.chart.type &&
                              _vm.chart.type.includes("text")) ||
                            _vm.chart.type === "label" ||
                            _vm.sourceType === "panelTable",
                          expression:
                            "(chart.type && chart.type.includes('text') || chart.type === 'label') || sourceType==='panelTable'",
                        },
                      ],
                      staticClass: "form-item",
                      attrs: { label: _vm.$t("chart.dimension_color") },
                    },
                    [
                      _c("el-color-picker", {
                        staticClass: "color-picker-style",
                        attrs: { predefine: _vm.predefineColors },
                        on: { change: _vm.changeColorCase },
                        model: {
                          value: _vm.colorForm.dimensionColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.colorForm, "dimensionColor", $$v)
                          },
                          expression: "colorForm.dimensionColor",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.chart.type &&
                        !_vm.chart.type.includes("text") &&
                        _vm.chart.type !== "label",
                      expression:
                        "chart.type && !chart.type.includes('text') && chart.type !== 'label'",
                    },
                  ],
                  staticClass: "form-item form-item-slider",
                  attrs: { label: _vm.$t("chart.not_alpha") },
                },
                [
                  _c("el-slider", {
                    attrs: {
                      "show-input": "",
                      "show-input-controls": false,
                      "input-size": "mini",
                    },
                    on: { change: _vm.changeColorCase },
                    model: {
                      value: _vm.colorForm.alpha,
                      callback: function ($$v) {
                        _vm.$set(_vm.colorForm, "alpha", $$v)
                      },
                      expression: "colorForm.alpha",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }