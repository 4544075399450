var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "operator",
        {
          attrs: { title: "system_parameter_setting.mailbox_service_settings" },
        },
        [
          _vm.showCancel
            ? _c(
                "deBtn",
                { attrs: { secondary: "" }, on: { click: _vm.cancel } },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
              )
            : _vm._e(),
          _c(
            "deBtn",
            {
              attrs: { secondary: "", disabled: _vm.disabledConnection },
              on: {
                click: function ($event) {
                  return _vm.testConnection("formInline")
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("system_parameter_setting.test_connection")) +
                  "\n    "
              ),
            ]
          ),
          _vm.showEdit
            ? _c(
                "deBtn",
                { attrs: { type: "primary" }, on: { click: _vm.edit } },
                [_vm._v(_vm._s(_vm.$t("commons.edit")))]
              )
            : _vm._e(),
          _vm.showSave
            ? _c(
                "deBtn",
                {
                  attrs: { type: "primary", disabled: _vm.disabledSave },
                  on: {
                    click: function ($event) {
                      return _vm.save("formInline")
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.save")) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formInline",
          staticClass: "demo-form-inline de-form-item",
          attrs: {
            model: _vm.formInline,
            rules: _vm.rules,
            disabled: _vm.show,
            size: "small",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.SMTP_host"),
                prop: "host",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("system_parameter_setting.SMTP_host"),
                },
                on: {
                  input: function ($event) {
                    return _vm.change()
                  },
                },
                model: {
                  value: _vm.formInline.host,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "host", $$v)
                  },
                  expression: "formInline.host",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.SMTP_port"),
                prop: "port",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("system_parameter_setting.SMTP_port"),
                },
                on: {
                  input: function ($event) {
                    return _vm.change()
                  },
                },
                model: {
                  value: _vm.formInline.port,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "port", $$v)
                  },
                  expression: "formInline.port",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.SMTP_account"),
                prop: "account",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("system_parameter_setting.SMTP_account"),
                },
                on: {
                  input: function ($event) {
                    return _vm.change()
                  },
                },
                model: {
                  value: _vm.formInline.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "account", $$v)
                  },
                  expression: "formInline.account",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.SMTP_password"),
                prop: "password",
              },
            },
            [
              _c("dePwd", {
                attrs: {
                  placeholder: _vm.$t("system_parameter_setting.SMTP_password"),
                },
                model: {
                  value: _vm.formInline.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "password", $$v)
                  },
                  expression: "formInline.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("ç.test_recipients") } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("system_parameter_setting.test_recipients")
                      ) +
                      "\n        "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t(
                          "system_parameter_setting.test_mail_recipient"
                        ),
                        placement: "top",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "tips-not-absolute",
                        attrs: { "icon-class": "icon_info_outlined" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("dePwd", {
                attrs: {
                  placeholder: _vm.$t(
                    "system_parameter_setting.test_recipients"
                  ),
                },
                model: {
                  value: _vm.formInline.recipient,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "recipient", $$v)
                  },
                  expression: "formInline.recipient",
                },
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("system_parameter_setting.email_server_config"),
              },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.formInline.ssl,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "ssl", $$v)
                    },
                    expression: "formInline.ssl",
                  },
                },
                [
                  _vm._v(_vm._s(_vm.$t("chart.open")) + "SSL\n        "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t(
                          "system_parameter_setting.to_enable_ssl"
                        ),
                        placement: "top",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "tips-not-absolute",
                        attrs: { "icon-class": "icon_info_outlined" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.formInline.tls,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "tls", $$v)
                    },
                    expression: "formInline.tls",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("chart.open")) +
                      "TSL\n        "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t(
                          "system_parameter_setting.to_enable_tsl"
                        ),
                        placement: "top",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "tips-not-absolute",
                        attrs: { "icon-class": "icon_info_outlined" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }