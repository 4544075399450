var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
      staticClass: "de-ds-container",
      class: [{ "is-driver-mgm": _vm.currentMgm === "driverMgm" }],
    },
    [
      _vm.currentMgm === "driverMgm"
        ? _c(
            "div",
            { staticClass: "dsr-route-title" },
            [
              _c("div", [
                _c("i", {
                  staticClass: "el-icon-arrow-left back-button",
                  on: { click: _vm.jump },
                }),
                _c("span", [_vm._v(_vm._s(_vm.$t("driver.mgm")))]),
              ]),
              _c(
                "deBtn",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addDriver },
                },
                [_vm._v(_vm._s(_vm.$t("driver.add")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "de-aside-container",
        { staticStyle: { padding: "0 0" }, attrs: { type: "datasource" } },
        [
          _c("ds-tree", {
            ref: "dsTree",
            attrs: { datasource: _vm.datasource },
            on: { "switch-mgm": _vm.switchMgm, "switch-main": _vm.switchMain },
          }),
        ],
        1
      ),
      _c(
        "de-main-container",
        [
          !!_vm.component
            ? _c(_vm.component, {
                tag: "component",
                attrs: {
                  params: _vm.param,
                  "t-data": _vm.tData,
                  "ds-types": _vm.dsTypes,
                },
                on: {
                  DataUpdate: _vm.dataUpdate,
                  "refresh-type": _vm.refreshType,
                  "switch-component": _vm.switchMain,
                },
              })
            : _c("el-empty", {
                attrs: {
                  "image-size": 125,
                  description: _vm.$t("datasource." + _vm.swTips),
                  image: _vm.image,
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }