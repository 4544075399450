var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "el-row",
        { staticClass: "title-text", staticStyle: { height: "26px" } },
        [
          _c("span", { staticStyle: { "line-height": "26px" } }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.param.tableId
                    ? _vm.$t("dataset.edit_custom_table")
                    : _vm.$t("dataset.add_custom_table")
                ) +
                "\n    "
            ),
          ]),
          _c(
            "el-row",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("dataset.cancel")) + "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.name || _vm.checkedList.length === 0,
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.save },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("dataset.confirm")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item",
                  attrs: { label: _vm.$t("commons.name") },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: _vm.$t("commons.name"),
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { staticStyle: { display: "flex", "flex-direction": "row" } },
        [
          _c(
            "el-col",
            {
              staticClass: "panel-height",
              staticStyle: {
                width: "220px",
                "border-right": "solid 1px #dcdfe6",
                "border-top": "solid 1px #dcdfe6",
                "padding-right": "15px",
                "overflow-y": "auto",
              },
            },
            [
              _c("dataset-group-selector", {
                attrs: {
                  "custom-type": _vm.customType,
                  table: _vm.table,
                  "checked-list": _vm.checkedList,
                  "union-data": _vm.unionData,
                },
                on: { getTable: _vm.getTable },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "panel-height",
              staticStyle: {
                width: "235px",
                "border-top": "solid 1px #dcdfe6",
                padding: "0 15px",
                "overflow-y": "auto",
              },
            },
            [
              _c("dataset-custom-field", {
                attrs: { table: _vm.table, "checked-list": _vm.checkedList },
                on: { getChecked: _vm.getChecked },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "panel-height",
              staticStyle: { flex: "1", overflow: "hidden" },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card dataPreview",
                  attrs: { shadow: "never" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "16px" } }, [
                        _vm._v(_vm._s(_vm.$t("dataset.data_preview"))),
                      ]),
                    ]
                  ),
                  _c(
                    "ux-grid",
                    {
                      ref: "plxTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        height: _vm.height,
                        "checkbox-config": { highlight: true },
                        "width-resize": true,
                      },
                    },
                    _vm._l(_vm.fields, function (field) {
                      return _c("ux-table-column", {
                        key: field.fieldName,
                        attrs: {
                          "min-width": "200px",
                          field: field.fieldName,
                          title: field.remarks,
                          resizable: true,
                        },
                      })
                    }),
                    1
                  ),
                  _c("span", { staticClass: "table-count" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dataset.preview_show")) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "span-number" }, [
                      _vm._v("1000"),
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dataset.preview_item")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }