var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "de-dataset-form" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "span",
        { staticClass: "name" },
        [
          _c("i", {
            staticClass: "el-icon-arrow-left",
            on: { click: _vm.logOutTips },
          }),
          _c("svg-icon", {
            staticStyle: { margin: "0 9.5px 0 16.2px" },
            attrs: { "icon-class": "de-" + _vm.datasetType + "-new" },
          }),
          _vm.showInput
            ? [
                _c("el-input", {
                  ref: "editerName",
                  on: { blur: _vm.nameBlur },
                  model: {
                    value: _vm.table.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.table, "name", $$v)
                    },
                    expression: "table.name",
                  },
                }),
                _vm.nameExist
                  ? _c(
                      "div",
                      {
                        staticClass: "el-form-item__error",
                        staticStyle: { left: "55px" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("deDataset.already_exists")) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : [
                _c("span", [_vm._v(_vm._s(_vm.datasetName))]),
                ["sql", "union"].includes(_vm.datasetType)
                  ? _c("i", {
                      staticClass: "el-icon-edit",
                      staticStyle: { "margin-left": "5px" },
                      on: { click: _vm.handleClick },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
      _c(
        "span",
        { staticClass: "oprate" },
        [
          ["db", "excel", "api"].includes(_vm.datasetType)
            ? _c("span", { staticClass: "table-num" }, [
                _vm._v(
                  _vm._s(_vm.$t("deDataset.selected")) +
                    " " +
                    _vm._s(_vm.tableNum) +
                    "\n        " +
                    _vm._s(
                      ["excel"].includes(_vm.datasetType)
                        ? _vm.$t("deDataset.table")
                        : _vm.$t("deDataset.item")
                    )
                ),
              ])
            : _vm._e(),
          _c(
            "deBtn",
            {
              attrs: {
                disabled:
                  ["db", "excel", "api"].includes(_vm.datasetType) &&
                  !_vm.tableNum,
                type: "primary",
              },
              on: { click: _vm.datasetSave },
            },
            [_vm._v(_vm._s(_vm.$t("commons.save")))]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(_vm.component, {
          ref: "addDataset",
          tag: "component",
          attrs: {
            param: _vm.table,
            "origin-name": _vm.originName,
            "name-list": _vm.nameList,
          },
          on: {
            setTableNum: function (val) {
              return (_vm.tableNum = val)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }