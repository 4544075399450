var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-content",
    {
      attrs: { header: _vm.headName },
      scopedSlots: _vm._u(
        [
          !_vm.optType
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c("el-icon", {
                      staticClass: "back-button",
                      attrs: { name: "back" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.backToList($event)
                        },
                      },
                    }),
                    _vm._v(
                      "\n    " +
                        _vm._s(
                          _vm.params &&
                            _vm.params.id &&
                            _vm.params.showModel &&
                            _vm.params.showModel === "show" &&
                            !_vm.canEdit
                            ? _vm.$t("datasource.show_info")
                            : _vm.formType === "add"
                            ? _vm.$t("datasource.create")
                            : _vm.$t("datasource.modify")
                        ) +
                        "\n  "
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm.optType === "appApply"
        ? [_c("span", [_vm._v("模板信息")])]
        : _vm._e(),
      _vm.optType === "appApply"
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "attachParamsForm",
                  attrs: {
                    model: _vm.attachForm,
                    rules: _vm.rule,
                    size: "small",
                    "label-width": "180px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仪表板位置", prop: "panelId" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          clearable: false,
                          options: _vm.panelGroupList,
                          normalizer: _vm.normalizer,
                          placeholder: _vm.$t("chart.select_group"),
                          "no-children-text": _vm.$t(
                            "commons.treeselect.no_children_text"
                          ),
                          "no-options-text": _vm.$t(
                            "commons.treeselect.no_options_text"
                          ),
                          "no-results-text": _vm.$t(
                            "commons.treeselect.no_results_text"
                          ),
                        },
                        model: {
                          value: _vm.attachForm.panelId,
                          callback: function ($$v) {
                            _vm.$set(_vm.attachForm, "panelId", $$v)
                          },
                          expression: "attachForm.panelId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "仪表板名称", prop: "panelName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attachForm.panelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.attachForm, "panelName", $$v)
                          },
                          expression: "attachForm.panelName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据集位置", prop: "desc" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          clearable: false,
                          options: _vm.datasetGroupList,
                          normalizer: _vm.normalizer,
                          placeholder: _vm.$t("chart.select_group"),
                          "no-children-text": _vm.$t(
                            "commons.treeselect.no_children_text"
                          ),
                          "no-options-text": _vm.$t(
                            "commons.treeselect.no_options_text"
                          ),
                          "no-results-text": _vm.$t(
                            "commons.treeselect.no_results_text"
                          ),
                        },
                        model: {
                          value: _vm.attachForm.datasetGroupId,
                          callback: function ($$v) {
                            _vm.$set(_vm.attachForm, "datasetGroupId", $$v)
                          },
                          expression: "attachForm.datasetGroupId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数据集分组名称",
                        prop: "datasetGroupName",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attachForm.datasetGroupName,
                          callback: function ($$v) {
                            _vm.$set(_vm.attachForm, "datasetGroupName", $$v)
                          },
                          expression: "attachForm.datasetGroupName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.optType === "appApply"
        ? [_c("span", [_vm._v("数据源信息")])]
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "dsForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rule,
                size: "small",
                disabled:
                  _vm.params &&
                  _vm.params.id &&
                  _vm.params.showModel &&
                  _vm.params.showModel === "show" &&
                  !_vm.canEdit,
                "label-width": "180px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("commons.name"), prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("commons.description"), prop: "desc" },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.type"), prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-width",
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("datasource.please_choose_type"),
                        disabled:
                          _vm.formType === "modify" ||
                          (_vm.formType === "add" &&
                            _vm.params &&
                            !!_vm.params.type),
                        filterable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeType()
                        },
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.dsTypes, function (item) {
                      return _c("el-option", {
                        key: item.type,
                        attrs: { label: item.name, value: item.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.datasourceType.isJdbc
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("driver.driver") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-width",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("driver.please_choose_driver"),
                            filterable: "",
                          },
                          model: {
                            value: _vm.form.configuration.customDriver,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.configuration,
                                "customDriver",
                                $$v
                              )
                            },
                            expression: "form.configuration.customDriver",
                          },
                        },
                        _vm._l(_vm.driverList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                              disabled: !item.driverClass,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.datasourceType.isPlugin
                ? _c("ds-configuration", {
                    ref: "dsConfig",
                    attrs: {
                      "datasource-type": _vm.datasourceType,
                      form: _vm.form,
                      disabled:
                        _vm.params &&
                        _vm.params.id &&
                        _vm.params.showModel &&
                        _vm.params.showModel === "show" &&
                        !_vm.canEdit,
                    },
                  })
                : _vm._e(),
              _vm.datasourceType.isPlugin
                ? _c("plugin-com", {
                    ref: "pluginDsConfig",
                    attrs: {
                      "component-name": _vm.datasourceType.type,
                      obj: { form: _vm.form, disabled: _vm.disabled },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.canEdit
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  (
                    _vm.formType === "add"
                      ? true
                      : _vm.hasDataPermission("manage", _vm.params.privileges)
                  )
                    ? _c("el-button", { on: { click: _vm.validaDatasource } }, [
                        _vm._v(_vm._s(_vm.$t("commons.validate")) + "\n      "),
                      ])
                    : _vm._e(),
                  (
                    _vm.formType === "add"
                      ? true
                      : _vm.hasDataPermission("manage", _vm.params.privileges)
                  )
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.save } },
                        [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  (
                    _vm.formType === "add"
                      ? true
                      : _vm.hasDataPermission("manage", _vm.params.privileges)
                  )
                    ? _c("el-button", { on: { click: _vm.validaDatasource } }, [
                        _vm._v(_vm._s(_vm.$t("commons.validate")) + "\n      "),
                      ])
                    : _vm._e(),
                  (
                    _vm.formType === "add"
                      ? true
                      : _vm.hasDataPermission("manage", _vm.params.privileges)
                  )
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.changeEdit },
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.edit")) + "\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }