var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout-content", [
    _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          display: "flex",
          "justify-content": "center",
        },
      },
      [
        _c(
          "el-card",
          { staticClass: "box-card about-card" },
          [
            _c("div", { staticClass: "form-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("commons.personal_info")))]),
            ]),
            _c(
              "el-form",
              {
                ref: "createUserForm",
                attrs: {
                  model: _vm.form,
                  rules: _vm.rule,
                  size: "small",
                  "label-width": "auto",
                  "label-position": "right",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "ID", prop: "username" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "username", $$v)
                        },
                        expression: "form.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("commons.phone"), prop: "phone" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: _vm.formType !== "modify" },
                      model: {
                        value: _vm.form.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("commons.nick_name"),
                      prop: "nickName",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: _vm.formType !== "modify" },
                      model: {
                        value: _vm.form.nickName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "nickName", $$v)
                        },
                        expression: "form.nickName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("commons.email"), prop: "email" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: _vm.formType !== "modify" },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("commons.status") } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.enabled,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "enabled", $$v)
                          },
                          expression: "form.enabled",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("commons.enable"))),
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("commons.disable"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      disabled: "",
                      label: _vm.$t("commons.organization"),
                      prop: "dept",
                    },
                  },
                  [
                    _c("treeselect", {
                      attrs: {
                        disabled: "",
                        options: _vm.depts,
                        "load-options": _vm.loadDepts,
                        "auto-load-root-options": false,
                        placeholder: _vm.$t("user.choose_org"),
                        "no-children-text": _vm.$t(
                          "commons.treeselect.no_children_text"
                        ),
                        "no-options-text": _vm.$t(
                          "commons.treeselect.no_options_text"
                        ),
                        "no-results-text": _vm.$t(
                          "commons.treeselect.no_results_text"
                        ),
                      },
                      model: {
                        value: _vm.form.deptId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deptId", $$v)
                        },
                        expression: "form.deptId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("commons.role"), prop: "roleIds" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: "",
                          multiple: "",
                          placeholder: _vm.$t("commons.please_select"),
                        },
                        on: {
                          "remove-tag": _vm.deleteTag,
                          change: _vm.changeRole,
                        },
                        model: {
                          value: _vm.form.roleIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roleIds", $$v)
                          },
                          expression: "form.roleIds",
                        },
                      },
                      _vm._l(_vm.roles, function (item) {
                        return _c("el-option", {
                          key: item.name,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.isPluginLoaded
                  ? _c("plugin-com", {
                      ref: "AuthenticationBind",
                      attrs: {
                        "user-id": _vm.form.userId,
                        "form-type": _vm.formType,
                        "component-name": "AuthenticationBind",
                      },
                    })
                  : _vm._e(),
                _vm.formType !== "modify"
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.formType = "modify"
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("member.modify_personal_info"))
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-form-item",
                      [
                        _vm.formType === "modify"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.save },
                              },
                              [_vm._v(_vm._s(_vm.$t("commons.save")))]
                            )
                          : _vm._e(),
                        _vm.formType === "modify"
                          ? _c("el-button", { on: { click: _vm.reset } }, [
                              _vm._v(_vm._s(_vm.$t("commons.cancel"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                staticStyle: { "margin-left": "30px" },
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _vm.formType === "modify"
                  ? _c(
                      "el-button",
                      { attrs: { type: "text" }, on: { click: _vm.reset } },
                      [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
                    )
                  : _vm._e(),
                _vm.formType === "modify"
                  ? _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.save } },
                      [_vm._v(_vm._s(_vm.$t("commons.confirm")))]
                    )
                  : _vm._e(),
                _vm.formType !== "modify"
                  ? _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.edit } },
                      [_vm._v(_vm._s(_vm.$t("commons.edit")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }