var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "demo_main" },
    [
      _c(
        "el-row",
        { staticClass: "demo_content" },
        _vm._l(_vm.blogsInfo, function (details, index) {
          return _c("el-row", { key: index }, [
            _c("a", { attrs: { href: details.href, target: "_blank" } }, [
              _vm._v(_vm._s(details.title)),
            ]),
            _c("br"),
            _c("span", { staticStyle: { color: "lightgray" } }, [
              _vm._v(_vm._s(details.time)),
            ]),
          ])
        }),
        1
      ),
      _c("el-row", { staticClass: "demo_bottom" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://blog.fit2cloud.com/?cat=321",
              target: "_blank",
            },
          },
          [_vm._v(_vm._s(_vm.$t("wizard.show_more")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }