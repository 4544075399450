var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.unionParam.type
    ? _c(
        "div",
        { staticStyle: { height: "calc(100% - 55px)", "overflow-y": "auto" } },
        [
          _c("div", { staticClass: "field-style" }, [
            _c(
              "div",
              { staticClass: "fields" },
              [
                _c(
                  "p",
                  { attrs: { title: _vm.unionParam.parent.currentDs.name } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.unionParam.parent.currentDs.name) +
                        "\n      "
                    ),
                  ]
                ),
                _c("union-field-list", {
                  attrs: {
                    "field-list": _vm.parentField,
                    node: _vm.unionParam.parent,
                  },
                  on: { checkedFields: _vm.changeParentFields },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "fields" },
              [
                _c(
                  "p",
                  { attrs: { title: _vm.unionParam.parent.currentDs.name } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.unionParam.node.currentDs.name) +
                        "\n      "
                    ),
                  ]
                ),
                _c("union-field-list", {
                  attrs: {
                    "field-list": _vm.nodeField,
                    node: _vm.unionParam.node,
                  },
                  on: { checkedFields: _vm.changeNodeFields },
                }),
              ],
              1
            ),
          ]),
          _c("union-item-edit", {
            attrs: {
              "parent-field-list": _vm.parentField,
              "node-field-list": _vm.nodeField,
              "union-param": _vm.unionParam,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }