var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      directives: [
        { name: "closePress", rawName: "v-closePress" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
      attrs: {
        title: _vm.$t("app_template.apply_template"),
        visible: _vm.applyDrawer,
        "custom-class": "de-user-drawer",
        size: "600px",
        "wrapper-closable": false,
        direction: "rtl",
      },
      on: {
        "update:visible": function ($event) {
          _vm.applyDrawer = $event
        },
      },
    },
    [
      _vm.applyDrawer
        ? _c("ds-form", {
            attrs: {
              "reference-position": "appMarket",
              "outer-params": _vm.outerParams,
            },
            on: { closeDraw: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }