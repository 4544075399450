var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "el-row",
        { staticStyle: { margin: "6px 0 16px 0" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm.hasDataPermission("manage", _vm.param.privileges)
                ? _c(
                    "deBtn",
                    {
                      attrs: { secondary: "", icon: "el-icon-plus" },
                      on: {
                        click: function () {
                          return _vm.addTask()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.add_task")))]
                  )
                : _vm._e(),
              _vm._v("\n       \n    "),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-document" },
                  on: { click: _vm.showConfig },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("dataset.task.record")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-task-container" },
        [
          _c(
            "grid-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                "table-data": _vm.taskData,
                columns: [],
                pagination: _vm.paginationConfig,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("el-table-column", {
                key: "name",
                attrs: {
                  "min-width": "178",
                  prop: "name",
                  label: _vm.$t("dataset.task_name"),
                },
              }),
              _c("el-table-column", {
                key: "rate",
                attrs: {
                  "min-width": "100",
                  prop: "rate",
                  label: _vm.$t("dataset.execute_rate"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.rate === "SIMPLE"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("dataset.execute_once"))),
                            ])
                          : _vm._e(),
                        scope.row.rate === "CRON"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("dataset.cron_config"))),
                            ])
                          : _vm._e(),
                        scope.row.rate === "SIMPLE_CRON"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("dataset.simple_cron"))),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "lastExecStatus",
                attrs: {
                  prop: "lastExecStatus",
                  "min-width": "140",
                  label: _vm.$t("dataset.task.last_exec_status"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.lastExecStatus
                          ? _c(
                              "span",
                              {
                                class: [
                                  "de-" + scope.row.lastExecStatus + "-pre",
                                  "de-status",
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "dataset." +
                                        scope.row.lastExecStatus.toLocaleLowerCase()
                                    )
                                  ) + "\n            "
                                ),
                                scope.row.lastExecStatus === "Error"
                                  ? _c("svg-icon", {
                                      staticClass: "field-icon-location",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { "icon-class": "icon-maybe" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showErrorMassage(
                                            scope.row.msg
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "lastExecTime",
                attrs: {
                  prop: "lastExecTime",
                  "min-width": "178",
                  label: _vm.$t("dataset.task.last_exec_time"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("timestampFormatDate")(
                                  scope.row.lastExecTime
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "nextExecTime",
                attrs: {
                  prop: "nextExecTime",
                  "min-width": "178",
                  label: _vm.$t("dataset.task.next_exec_time"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.nextExecTime &&
                        scope.row.nextExecTime !== -1 &&
                        scope.row.rate !== "SIMPLE" &&
                        scope.row.status !== "Pending"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("timestampFormatDate")(
                                      scope.row.nextExecTime
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        !scope.row.nextExecTime || scope.row.rate === "SIMPLE"
                          ? _c("span", [_vm._v("-")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "status",
                attrs: {
                  "min-width": "120",
                  prop: "status",
                  label: _vm.$t("dataset.task.task_status"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            class: [
                              "de-" + scope.row.status + "-result",
                              "de-status",
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "dataset.task." +
                                    scope.row.status.toLocaleLowerCase()
                                )
                              ) + "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "__operation",
                attrs: {
                  slot: "__operation",
                  label: _vm.$t("commons.operating"),
                  fixed: "right",
                  "min-width": "180",
                },
                slot: "__operation",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "de-text-btn",
                            staticStyle: { "margin-left": "-4px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.selectDataset(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.disableEdit(scope.row)
                                    ? "auth.view"
                                    : "commons.edit"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "de-text-btn mar3 mar6",
                            attrs: {
                              disabled: _vm.disableExec(scope.row),
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.execTask(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("emailtask.execute_now")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "el-dropdown",
                          {
                            attrs: { size: "medium", trigger: "click" },
                            on: {
                              command: function (type) {
                                return _vm.handleCommand(type, scope.row)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              staticClass: "el-icon-more de-text-btn",
                              staticStyle: { "margin-left": "8px" },
                              attrs: { type: "text" },
                            }),
                            _c(
                              "el-dropdown-menu",
                              {
                                staticClass: "de-card-dropdown",
                                attrs: { slot: "dropdown" },
                                slot: "dropdown",
                              },
                              [
                                !["Exec"].includes(scope.row.status)
                                  ? [
                                      scope.row.status === "Pending"
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "continue" } },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.continue"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      scope.row.status === "Underway"
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "pause" } },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "dataset.task.pending"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      disabled: _vm.disableDelete(scope.row),
                                      command: "delete",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("commons.delete")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "de-dialog-form",
          attrs: {
            title: _vm.$t("dataset.detail"),
            visible: _vm.show_error_massage,
            "show-close": false,
            width: "50%",
          },
        },
        [
          _c("span", { staticClass: "err-msg" }, [
            _vm._v(_vm._s(_vm.error_massage) + " "),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_error_massage = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          directives: [{ name: "closePress", rawName: "v-closePress" }],
          attrs: {
            title: _vm.$t("dataset.task.record"),
            visible: _vm.userDrawer,
            "custom-class": "de-user-drawer",
            size: "840px",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDrawer = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "margin-top": "12px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "top-border",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.taskLogData, height: _vm.height },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: _vm.$t("dataset.task_name") },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startTime",
                      label: _vm.$t("dataset.start_time"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("timestampFormatDate")(
                                    scope.row.startTime
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endTime",
                      label: _vm.$t("dataset.end_time"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("timestampFormatDate")(
                                    scope.row.endTime
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: _vm.$t("dataset.status") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      "de-" + scope.row.status + "-pre",
                                      "de-status",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dataset." +
                                            scope.row.status.toLocaleLowerCase()
                                        )
                                      ) + "\n              "
                                    ),
                                    scope.row.status === "Error"
                                      ? _c("svg-icon", {
                                          staticClass: "field-icon-location",
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { "icon-class": "icon-maybe" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showErrorMassage(
                                                scope.row.info
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px", "text-align": "right" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.page.currentPage,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.page.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.page.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChangeLog,
                      "current-change": _vm.handleCurrentChangeLog,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          directives: [{ name: "closePress", rawName: "v-closePress" }],
          attrs: {
            title: _vm.header,
            visible: _vm.update_task,
            "custom-class": "de-user-drawer update-drawer-task",
            size: "680px",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.update_task = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "taskForm",
              staticClass: "de-form-item",
              attrs: {
                form: _vm.taskForm,
                model: _vm.taskForm,
                "label-width": "100px",
                disabled: _vm.disableForm,
                rules: _vm.taskFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("dataset.task_name"), prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder:
                        _vm.$t("fu.search_bar.please_input") +
                        _vm.$t("dataset.task_name"),
                    },
                    model: {
                      value: _vm.taskForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskForm, "name", $$v)
                      },
                      expression: "taskForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("dataset.update_type"), prop: "type" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.taskForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskForm, "type", $$v)
                        },
                        expression: "taskForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "all_scope" } }, [
                        _vm._v(_vm._s(_vm.$t("dataset.all_scope"))),
                      ]),
                      _c("el-radio", { attrs: { label: "add_scope" } }, [
                        _vm._v(
                          "\n            " + _vm._s(_vm.$t("dataset.add_scope"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.taskForm.type === "add_scope" && _vm.table.type !== "api"
                ? _c(
                    "div",
                    { staticClass: "add-scope-cont" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "type",
                            label: _vm.$t("dataset.incremental_update_type"),
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              on: { change: _vm.incrementalUpdateTypeChange },
                              model: {
                                value: _vm.incrementalUpdateType,
                                callback: function ($$v) {
                                  _vm.incrementalUpdateType = $$v
                                },
                                expression: "incrementalUpdateType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "incrementalAdd" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataset.incremental_add"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "incrementalDelete" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataset.incremental_delete"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "param-title" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("dataset.param"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "param-title-btn" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.insertParamToCodeMirror(
                                          "${__last_update_time__}"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataset.last_update_time"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.insertParamToCodeMirror(
                                          "${__current_update_time__}"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("dataset.current_update_time")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "codemirror-cont" },
                            [
                              _c("codemirror", {
                                ref: "myCm",
                                staticClass: "codemirror",
                                attrs: { options: _vm.sqlOption },
                                on: {
                                  ready: _vm.onCmReady,
                                  focus: _vm.onCmFocus,
                                  input: _vm.onCmCodeChange,
                                },
                                model: {
                                  value: _vm.sql,
                                  callback: function ($$v) {
                                    _vm.sql = $$v
                                  },
                                  expression: "sql",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("dataset.execute_rate"),
                    prop: "rate",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onRateChange },
                      model: {
                        value: _vm.taskForm.rate,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskForm, "rate", $$v)
                        },
                        expression: "taskForm.rate",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "SIMPLE" } }, [
                        _vm._v(_vm._s(_vm.$t("dataset.execute_once"))),
                      ]),
                      _c("el-radio", { attrs: { label: "CRON" } }, [
                        _vm._v(_vm._s(_vm.$t("dataset.cron_config"))),
                      ]),
                      _c("el-radio", { attrs: { label: "SIMPLE_CRON" } }, [
                        _vm._v(_vm._s(_vm.$t("dataset.simple_cron"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.taskForm.rate !== "SIMPLE"
                ? _c(
                    "div",
                    { staticClass: "execute-rate-cont" },
                    [
                      _vm.taskForm.rate === "SIMPLE_CRON"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("dataset.execute_rate"),
                                prop: "rate",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "simple-cron" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("cron.every")) +
                                      "\n            "
                                  ),
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 1,
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onSimpleCronChange()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.taskForm.extraData
                                          .simple_cron_value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskForm.extraData,
                                          "simple_cron_value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "taskForm.extraData.simple_cron_value",
                                    },
                                  }),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { filterable: "", size: "small" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onSimpleCronChange()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.taskForm.extraData
                                            .simple_cron_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.taskForm.extraData,
                                            "simple_cron_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "taskForm.extraData.simple_cron_type",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t("components.minute"),
                                          value: "minute",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t("components.hour"),
                                          value: "hour",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t("components.day"),
                                          value: "day",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("cron.every_exec")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.taskForm.rate === "CRON" && _vm.showCron
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "cron",
                                label: _vm.$t("emailtask.cron_exp"),
                              },
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  model: {
                                    value: _vm.cronEdit,
                                    callback: function ($$v) {
                                      _vm.cronEdit = $$v
                                    },
                                    expression: "cronEdit",
                                  },
                                },
                                [
                                  _c("cron", {
                                    attrs: {
                                      "is-rate": _vm.taskForm.rate === "CRON",
                                    },
                                    on: {
                                      close: function ($event) {
                                        _vm.cronEdit = false
                                      },
                                    },
                                    model: {
                                      value: _vm.taskForm.cron,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "cron", $$v)
                                      },
                                      expression: "taskForm.cron",
                                    },
                                  }),
                                  _c("el-input", {
                                    staticStyle: { width: "50%" },
                                    attrs: { slot: "reference", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        _vm.cronEdit = true
                                      },
                                    },
                                    slot: "reference",
                                    model: {
                                      value: _vm.taskForm.cron,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "cron", $$v)
                                      },
                                      expression: "taskForm.cron",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskForm.rate !== "SIMPLE"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("dataset.start_time"),
                                prop: "startTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "de-date-picker",
                                attrs: {
                                  type: "datetime",
                                  placeholder: _vm.$t("dataset.start_time"),
                                  size: "small",
                                },
                                model: {
                                  value: _vm.taskForm.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.taskForm, "startTime", $$v)
                                  },
                                  expression: "taskForm.startTime",
                                },
                              }),
                              _c("svg-icon", {
                                staticClass: "icon-calendar-outlined",
                                attrs: {
                                  "icon-class": "icon_calendar_outlined",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskForm.rate !== "SIMPLE"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("dataset.end_time"),
                                prop: "end",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.taskForm.end,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.taskForm, "end", $$v)
                                    },
                                    expression: "taskForm.end",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v(_vm._s(_vm.$t("dataset.no_limit"))),
                                  ]),
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("dataset.set_end_time"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm.taskForm.end === "1"
                                ? _c("el-date-picker", {
                                    staticClass: "de-date-picker",
                                    attrs: {
                                      type: "datetime",
                                      placeholder: _vm.$t("dataset.end_time"),
                                      size: "small",
                                    },
                                    model: {
                                      value: _vm.taskForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "endTime", $$v)
                                      },
                                      expression: "taskForm.endTime",
                                    },
                                  })
                                : _vm._e(),
                              _vm.taskForm.end === "1"
                                ? _c("svg-icon", {
                                    staticClass: "icon-calendar-outlined",
                                    attrs: {
                                      "icon-class": "icon_calendar_outlined",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "de-foot" },
            [
              _c(
                "deBtn",
                { attrs: { secondary: "" }, on: { click: _vm.closeTask } },
                [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
              ),
              !_vm.disableForm
                ? _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveTask(_vm.taskForm)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }