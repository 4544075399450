var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testcase-template" },
    [
      _c("div", {
        staticClass: "template-img",
        style: _vm.classBackground,
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.templatePreview($event)
          },
        },
      }),
      _c(
        "el-row",
        { staticClass: "bottom-area" },
        [
          _c("el-row", [
            _c("span", { staticClass: "demonstration" }, [
              _vm._v(_vm._s(_vm.template.title)),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "template-button" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "141px" },
              attrs: { size: "mini" },
              on: { click: _vm.templatePreview },
            },
            [_vm._v(_vm._s(_vm.$t("panel.preview")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "141px" },
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.apply },
            },
            [_vm._v(_vm._s(_vm.$t("panel.apply")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }