var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "demo_main" },
    [
      _c("el-row", { staticClass: "demo_content" }, [
        _c("span", { staticClass: "title" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://live.vhall.com/v3/lives/subscribe/533874762",
                target: "_blank",
              },
            },
            [_vm._v("40分钟带你玩转 DataEase ")]
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "title" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://live.vhall.com/v3/lives/subscribe/903960272",
                target: "_blank",
              },
            },
            [_vm._v("用DataEase开源工具可视化 ClickHouse数据 ")]
          ),
        ]),
      ]),
      _c("el-row", { staticClass: "demo_bottom" }, [
        _c("span", { staticStyle: { color: "black" } }, [
          _vm._v(_vm._s(_vm.$t("wizard.teaching_video_bottom_hint"))),
        ]),
        _c(
          "a",
          {
            attrs: {
              href: "https://e.vhall.com/v3/user/home/45637107",
              target: "_blank",
            },
          },
          [_vm._v(_vm._s(_vm.$t("wizard.click_inner")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }