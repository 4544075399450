var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-content",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("el-icon", {
                staticClass: "back-button",
                attrs: { name: "back" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.backToList($event)
                  },
                },
              }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.params &&
                      _vm.params.id &&
                      _vm.params.showModel &&
                      _vm.params.showModel === "show" &&
                      !_vm.canEdit
                      ? _vm.$t("driver.show_info")
                      : _vm.$t("driver.modify")
                  ) +
                  "\n    "
              ),
              _vm.canEdit
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n    ")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.changeEdit },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.edit")) + "\n    ")]
                  ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "driverForm",
              attrs: {
                model: _vm.driverForm,
                rules: _vm.rule,
                size: "small",
                disabled:
                  _vm.params &&
                  _vm.params.id &&
                  _vm.params.showModel &&
                  _vm.params.showModel === "show" &&
                  !_vm.canEdit,
                "label-width": "180px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("commons.name"), prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.driverForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.driverForm, "name", $$v)
                      },
                      expression: "driverForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("commons.description") } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.driverForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.driverForm, "desc", $$v)
                      },
                      expression: "driverForm.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.type"), prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-width",
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("datasource.please_choose_type"),
                        disabled: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.driverForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.driverForm, "type", $$v)
                        },
                        expression: "driverForm.type",
                      },
                    },
                    _vm._l(_vm.dsTypes, function (item) {
                      return _c("el-option", {
                        key: item.type,
                        attrs: { label: item.name, value: item.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("driver.driver"),
                    prop: "driverClass",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.driverForm.driverClass,
                      callback: function ($$v) {
                        _vm.$set(_vm.driverForm, "driverClass", $$v)
                      },
                      expression: "driverForm.driverClass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-upload",
            {
              staticStyle: { float: "right" },
              attrs: {
                action: _vm.baseUrl + "driver/file/upload",
                multiple: true,
                "show-file-list": false,
                "file-list": _vm.fileList,
                data: _vm.params,
                accept: ".jar",
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.uploadSuccess,
                "on-error": _vm.uploadFail,
                name: "file",
                headers: _vm.headers,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: _vm.uploading,
                  },
                },
                [
                  !_vm.uploading
                    ? _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v(_vm._s(_vm.$t("dataset.upload_file"))),
                      ])
                    : _vm._e(),
                  _vm.uploading
                    ? _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _c("i", { staticClass: "el-icon-loading" }),
                        _vm._v(" " + _vm._s(_vm.$t("dataset.uploading"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "fu-table",
            { attrs: { data: _vm.driverFiles } },
            [
              _c("el-table-column", {
                attrs: { prop: "fileName", label: _vm.$t("driver.file_name") },
              }),
              _c("fu-table-operations", {
                attrs: {
                  buttons: _vm.buttons,
                  label: _vm.$t("commons.operating"),
                  fix: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }