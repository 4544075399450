var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-content",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
    },
    [
      _c("div", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { id: "maptest" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }