var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "el-icon-arrow-left back-button",
    on: { click: _vm.jump },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }