var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "el-row",
        { staticStyle: { "margin-top": "5px" } },
        [
          _c(
            "el-row",
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("panel.filter_keywords"),
                  size: "mini",
                  clearable: "",
                  "prefix-icon": "el-icon-search",
                },
                model: {
                  value: _vm.templateFilterText,
                  callback: function ($$v) {
                    _vm.templateFilterText = $$v
                  },
                  expression: "templateFilterText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "5px" } },
            [
              _c("el-tree", {
                ref: "templateTree",
                attrs: {
                  "default-expanded-keys": _vm.defaultExpandedKeys,
                  data: _vm.templateList,
                  "node-key": "id",
                  "expand-on-click-node": true,
                  "filter-node-method": _vm.filterNode,
                  "highlight-current": true,
                },
                on: { "node-click": _vm.nodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var data = ref.data
                      return _c("span", { staticClass: "custom-tree-node" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              flex: "1 1 0%",
                              width: "0px",
                            },
                          },
                          [
                            data.nodeType === "template"
                              ? _c(
                                  "span",
                                  [
                                    _c("svg-icon", {
                                      staticClass: "ds-icon-scene",
                                      attrs: { "icon-class": "panel" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            data.nodeType === "folder"
                              ? _c("span", [
                                  _c("i", { staticClass: "el-icon-folder" }),
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "6px",
                                  "white-space": "nowrap",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [_vm._v(_vm._s(data.name))]
                            ),
                          ]
                        ),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }