var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [_vm._v(_vm._s(_vm.node.currentDs.name))]),
      _c("union-field-list", {
        attrs: { "field-list": _vm.fieldList, node: _vm.node },
        on: { checkedFields: _vm.changeFields },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }