var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "card_main", attrs: { span: 8 } },
    [_c("el-row", { staticClass: "card_content" }, [_vm._t("default")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }