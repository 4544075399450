var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.positionCheck("datasource") ? _vm.formLoading : false,
          expression: "positionCheck('datasource')?formLoading:false",
        },
      ],
      class: _vm.positionCheck("datasource") ? "de-ds-form" : "de-ds-form-app",
    },
    [
      _vm.positionCheck("datasource")
        ? _c("div", { staticClass: "de-ds-top" }, [
            _c("span", { staticClass: "name" }, [
              _c("i", {
                staticClass: "el-icon-arrow-left",
                on: { click: _vm.logOutTips },
              }),
              _vm._v("\n      " + _vm._s(_vm.tips) + "\n    "),
            ]),
            _c(
              "div",
              { staticClass: "apply" },
              [
                _vm.canEdit
                  ? [
                      _c(
                        "deBtn",
                        {
                          attrs: { secondary: "" },
                          on: { click: _vm.logOutTips },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("commons.cancel")) + "\n        "
                          ),
                        ]
                      ),
                      _vm.addOrHasDataPermission
                        ? _c(
                            "deBtn",
                            {
                              attrs: { secondary: "" },
                              on: { click: _vm.validaDatasource },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("commons.validate")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.addOrHasDataPermission
                        ? _c(
                            "deBtn",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.save },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("commons.save")) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : [
                      _vm.addOrHasDataPermission
                        ? _c("deBtn", { on: { click: _vm.validaDatasource } }, [
                            _vm._v(
                              _vm._s(_vm.$t("commons.validate")) + "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 56px)" } },
        [
          _c("DsFormContent", {
            ref: "DsFormContent",
            attrs: {
              "outer-params": _vm.outerParams,
              "reference-position": _vm.referencePosition,
              "form-type": _vm.formType,
              "type-map": _vm.typeMap,
              "can-edit": _vm.canEdit,
              "form-loading": _vm.formLoading,
            },
            on: {
              editeTodisable: _vm.backToList,
              "update:formType": function ($event) {
                _vm.formType = $event
              },
              "update:form-type": function ($event) {
                _vm.formType = $event
              },
              "update:typeMap": function ($event) {
                _vm.typeMap = $event
              },
              "update:type-map": function ($event) {
                _vm.typeMap = $event
              },
              "update:canEdit": function ($event) {
                _vm.canEdit = $event
              },
              "update:can-edit": function ($event) {
                _vm.canEdit = $event
              },
              "update:formLoading": function ($event) {
                _vm.formLoading = $event
              },
              "update:form-loading": function ($event) {
                _vm.formLoading = $event
              },
              refreshType: _vm.refreshType,
              closeDraw: _vm.closeDraw,
              setParams: _vm.setParams,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }