var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "box" },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: _vm.tips, placement: "right" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.exec()
                },
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }