var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "tree-main",
    },
    [
      _vm.showExtent
        ? _c(
            "el-row",
            { staticClass: "tree-head" },
            [
              _c(
                "span",
                { staticStyle: { float: "left", "padding-left": "10px" } },
                [_vm._v(_vm._s(_vm.dataInfo.head))]
              ),
              _vm._l(_vm.defaultAuthDetails, function (auth) {
                return _c(
                  "span",
                  { key: auth.privilegeName, staticClass: "auth-span" },
                  [_vm._v("\n      " + _vm._s(auth.privilegeName) + "\n    ")]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "5px" } },
        [
          _c("el-tree", {
            attrs: {
              props: _vm.defaultProps,
              load: _vm.loadNodes,
              data: _vm.treeData,
              "node-key": _vm.defaultProps.id,
              "highlight-current": _vm.highlightCurrent,
              "default-expanded-keys": _vm.expandedKey,
              lazy: "",
            },
            on: { "node-click": _vm.nodeClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var data = ref.data
                  return _c("span", { staticClass: "custom-tree-node" }, [
                    _c("span", [
                      _c("span", {
                        staticStyle: { "margin-left": "6px" },
                        domProps: { innerHTML: _vm._s(data.name) },
                      }),
                    ]),
                    _vm.showExtent
                      ? _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _vm.authDetails[data.id]
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.authDetails[data.id],
                                    function (auth) {
                                      return _c(
                                        "span",
                                        {
                                          key: auth.privilegeType,
                                          staticClass: "auth-span",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickAuth(
                                                    data.id,
                                                    auth
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  width: "25px",
                                                  height: "25px",
                                                },
                                                attrs: {
                                                  "icon-class":
                                                    auth.privilegeValue === 1
                                                      ? "lock_open"
                                                      : "lock_closed",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "div",
                                  _vm._l(
                                    _vm.defaultAuthDetails,
                                    function (auth) {
                                      return _c(
                                        "span",
                                        {
                                          key: auth.privilegeType,
                                          staticClass: "auth-span",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickAuth(
                                                    data.id,
                                                    auth
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  width: "25px",
                                                  height: "25px",
                                                },
                                                attrs: {
                                                  "icon-class":
                                                    auth.privilegeValue === 1
                                                      ? "lock_open"
                                                      : "lock_closed",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                          ]
                        )
                      : _vm._e(),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }