var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tabStatus
    ? _c("div", { staticClass: "info-tab" }, [
        _vm.type === "chart" && _vm.detail.chart
          ? _c("div", { staticClass: "info-card" }, [
              _c("div", { staticClass: "title-type" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("chart.datalist")) + "\n    "
                ),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("chart.datalist") + _vm.$t("desearchbutton.text")
                      ) +
                      "\n      "
                  ),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.chart.name)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "info-item" },
                [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v(_vm._s(_vm.$t("chart.chart_type"))),
                  ]),
                  _vm.detail.chart.type
                    ? _c("svg-icon", {
                        attrs: { "icon-class": _vm.detail.chart.type },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("chart.title"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.chart.title || "N/A")),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataset.create_by"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.chart.createBy)),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataset.create_time"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("timestampFormatDate")(
                          _vm.detail.chart.createTime
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.detail.table
          ? _c("div", { staticClass: "info-card" }, [
              _c("div", { staticClass: "title-type" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("dataset.datalist")) + "\n    "
                ),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("dataset.datalist") +
                          _vm.$t("desearchbutton.text")
                      ) +
                      "\n      "
                  ),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.table.name)),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataset.type"))),
                ]),
                _vm.detail.table.type === "db"
                  ? _c("p", { staticClass: "info-content" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.db_data")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm.detail.table.type === "sql"
                  ? _c("p", { staticClass: "info-content" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.sql_data")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm.detail.table.type === "excel"
                  ? _c("p", { staticClass: "info-content" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.excel_data")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm.detail.table.type === "custom"
                  ? _c("p", { staticClass: "info-content" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.custom_data")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm.detail.table.type === "union"
                  ? _c("p", { staticClass: "info-content" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.union_data")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm.detail.table.type === "api"
                  ? _c("p", { staticClass: "info-content" }, [_vm._v("Api")])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detail.table.type === "db",
                      expression: "detail.table.type === 'db'",
                    },
                  ],
                  staticClass: "info-item",
                },
                [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v(_vm._s(_vm.$t("dataset.table"))),
                  ]),
                  _c("p", { staticClass: "info-content" }, [
                    _vm._v(_vm._s(_vm.info.table)),
                  ]),
                ]
              ),
              _vm.detail.table.type === "db" || _vm.detail.table.type === "sql"
                ? _c("div", { staticClass: "info-item" }, [
                    _c("p", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("dataset.mode"))),
                    ]),
                    _vm.detail.table.mode === 0
                      ? _c("p", { staticClass: "info-content" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("dataset.direct_connect")) +
                              "\n      "
                          ),
                        ])
                      : _vm._e(),
                    _vm.detail.table.mode === 1
                      ? _c("p", { staticClass: "info-content" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("dataset.sync_data")) +
                              "\n      "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataset.create_by"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.table.creatorName || "N/A")),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataset.create_time"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("timestampFormatDate")(
                          _vm.detail.table.createTime
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.detail.datasource
          ? _c("div", { staticClass: "info-card" }, [
              _c("div", { staticClass: "title-type" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("datasource.datasource")) +
                    "\n    "
                ),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("datasource.datasource") +
                          _vm.$t("desearchbutton.text")
                      ) +
                      "\n      "
                  ),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.datasource.name)),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("commons.description"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.detail.datasource.desc || "-")),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("datasource.type"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.detail.datasource.type) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("p", { staticClass: "info-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataset.create_time"))),
                ]),
                _c("p", { staticClass: "info-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("timestampFormatDate")(
                          _vm.detail.datasource.createTime
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }