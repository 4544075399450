var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          attrs: { size: "mini" },
          model: {
            value: _vm.body.type,
            callback: function ($$v) {
              _vm.$set(_vm.body, "type", $$v)
            },
            expression: "body.type",
          },
        },
        [
          _c(
            "el-radio",
            {
              attrs: { disabled: _vm.isReadOnly, label: _vm.type.FORM_DATA },
              on: { change: _vm.modeChange },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("datasource.body_form_data")) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "el-radio",
            {
              attrs: { disabled: _vm.isReadOnly, label: _vm.type.WWW_FORM },
              on: { change: _vm.modeChange },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("datasource.body_x_www_from_urlencoded")) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "el-radio",
            {
              attrs: { disabled: _vm.isReadOnly, label: _vm.type.JSON },
              on: { change: _vm.modeChange },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("datasource.body_json")) + "\n    "
              ),
            ]
          ),
          _c(
            "el-radio",
            {
              attrs: { disabled: _vm.isReadOnly, label: _vm.type.XML },
              on: { change: _vm.modeChange },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("datasource.body_xml")) + "\n    "
              ),
            ]
          ),
          _c(
            "el-radio",
            {
              attrs: { disabled: _vm.isReadOnly, label: _vm.type.RAW },
              on: { change: _vm.modeChange },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("datasource.body_raw")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.body.type == "Form_Data" || _vm.body.type == "WWW_FORM"
        ? _c(
            "div",
            { staticStyle: { "min-width": "1200px" } },
            [
              _c("api-variable", {
                attrs: {
                  "with-more-setting": true,
                  "is-read-only": _vm.isReadOnly,
                  parameters: _vm.body.kvs,
                  "is-show-enable": _vm.isShowEnable,
                  type: "body",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.body.type == "JSON"
        ? _c(
            "div",
            [
              _c("code-edit", {
                ref: "codeEdit",
                attrs: {
                  "read-only": _vm.isReadOnly,
                  data: _vm.body.raw,
                  modes: _vm.modes,
                  mode: "json",
                  height: "400px",
                },
                on: {
                  "update:data": function ($event) {
                    return _vm.$set(_vm.body, "raw", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.body.type == "XML"
        ? _c(
            "div",
            { staticClass: "ms-body" },
            [
              _c("code-edit", {
                ref: "codeEdit",
                attrs: {
                  "read-only": _vm.isReadOnly,
                  data: _vm.body.raw,
                  modes: _vm.modes,
                  mode: "text",
                },
                on: {
                  "update:data": function ($event) {
                    return _vm.$set(_vm.body, "raw", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.body.type == "Raw"
        ? _c(
            "div",
            { staticClass: "ms-body" },
            [
              _c("code-edit", {
                ref: "codeEdit",
                attrs: {
                  "read-only": _vm.isReadOnly,
                  data: _vm.body.raw,
                  modes: _vm.modes,
                },
                on: {
                  "update:data": function ($event) {
                    return _vm.$set(_vm.body, "raw", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }