<template>
  <div style="width: 100%" />
</template>

<script>
export default {
  name: 'SizeSelector',
  props: {
    param: {
      type: Object,
      required: true
    },
    chart: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
