var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "5px" } }, [
      _c("div", [_vm._v(" 导出时间：$TIME$")]),
      _c("div", { attrs: { contenteditable: "true" } }, [
        _vm._v(" 可以输入内容"),
      ]),
      _c("div", [_c("img", { attrs: { width: "100%", src: "$snapshot$" } })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }