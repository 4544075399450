var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("de-layout-content", [
    _c(
      "div",
      { staticClass: "de-task-record" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.changeTab },
            model: {
              value: _vm.tabActive,
              callback: function ($$v) {
                _vm.tabActive = $$v
              },
              expression: "tabActive",
            },
          },
          [
            _c("el-tab-pane", {
              attrs: {
                label: _vm.$t("dataset.task.list"),
                name: "DatasetTaskList",
              },
            }),
            _c("el-tab-pane", {
              attrs: {
                label: _vm.$t("dataset.task.record"),
                name: "TaskRecord",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tabs-container" },
          [
            _vm.tabActive == "DatasetTaskList"
              ? _c("dataset-task-list", {
                  attrs: {
                    param: _vm.task,
                    "trans-condition": _vm.transCondition,
                  },
                  on: { jumpTaskRecord: _vm.jumpTaskRecord },
                })
              : _vm._e(),
            _vm.tabActive == "TaskRecord"
              ? _c("task-record", {
                  ref: "task_record",
                  attrs: {
                    param: _vm.task,
                    "trans-condition": _vm.transCondition,
                  },
                  on: { jumpTask: _vm.jumpTask },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }