var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-table" },
    [
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              ref: "table",
              style: { width: "100%" },
              attrs: { height: "2000", data: _vm.tableData },
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.tableEvent
        ),
        [
          _c(
            "table-body",
            { attrs: { columns: _vm.columns } },
            [_vm._t("default")],
            2
          ),
          _vm._t("__operation"),
        ],
        2
      ),
      _vm.showPagination
        ? _c(
            "div",
            { staticClass: "pagination-cont" },
            [
              _c(
                "el-pagination",
                _vm._g(
                  _vm._b(
                    { attrs: { background: "" } },
                    "el-pagination",
                    _vm.paginationDefault,
                    false
                  ),
                  _vm.paginationEvent
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }