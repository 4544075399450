var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "main_outer",
        staticStyle: {
          position: "absolute",
          top: "55px",
          width: "100%",
          height: "calc(100% - 55px)",
        },
      })
    : _vm.homeLink
    ? _c("div", [
        _c("iframe", {
          staticStyle: {
            position: "absolute",
            top: "55px",
            width: "100%",
            height: "calc(100% - 55px)",
          },
          attrs: { id: "mobsf", src: _vm.homeLink, frameborder: "0" },
        }),
      ])
    : _c(
        "el-row",
        { staticClass: "main_container_outer" },
        [
          _c(
            "el-row",
            { staticClass: "main_container" },
            [
              _c(
                "el-row",
                { staticClass: "main_content" },
                [
                  _c(
                    "el-row",
                    { staticClass: "content_top_banner" },
                    [
                      _c("div", { staticClass: "top_banner_content" }, [
                        _c("div", { staticClass: "hint_head" }, [
                          _vm._v(_vm._s(_vm.$t("wizard.welcome_title"))),
                        ]),
                        _c("div", { staticClass: "hint_content" }, [
                          _vm._v(_vm._s(_vm.$t("wizard.welcome_hint"))),
                        ]),
                      ]),
                      _c("svg-icon", {
                        staticClass: "top_banner_img",
                        attrs: { "icon-class": "wizard_main_bg_inner" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "top_banner_card" },
                    [
                      _c("wizard-card", {
                        attrs: { details: _vm.cardList[0] },
                      }),
                      _c("wizard-card", {
                        staticStyle: { margin: "0 24px 0 24px" },
                        attrs: { details: _vm.cardList[1] },
                      }),
                      _c("wizard-card-enterprise", {
                        attrs: { details: _vm.cardList[2] },
                      }),
                    ],
                    1
                  ),
                  _c("el-row", { staticClass: "content_middle" }, [
                    _c(
                      "div",
                      { staticClass: "content_middle_left" },
                      [
                        _c("el-row", [
                          _c("span", { staticClass: "content_middle_title" }, [
                            _vm._v(_vm._s(_vm.$t("wizard.teaching_video"))),
                          ]),
                          _c("div", { staticClass: "content_middle_more" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: "https://space.bilibili.com/510493147/channel/collectiondetail?sid=262774",
                                },
                              },
                              [
                                _vm._v(_vm._s(_vm.$t("wizard.more"))),
                                _c("i", { staticClass: "el-icon-arrow-right" }),
                              ]
                            ),
                          ]),
                        ]),
                        _c(
                          "el-row",
                          { staticStyle: { "margin-top": "12px" } },
                          [
                            _c("video-card", {
                              attrs: { details: _vm.videoList[0] },
                            }),
                            _c("video-card", {
                              staticStyle: { margin: "0 12px 0 12px" },
                              attrs: { details: _vm.videoList[1] },
                            }),
                            _c("video-card", {
                              attrs: { details: _vm.videoList[2] },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "content_middle_right" },
                      [
                        _c("el-row", [
                          _c("span", { staticClass: "content_middle_title" }, [
                            _vm._v(
                              _vm._s(_vm.$t("wizard.latest_developments"))
                            ),
                          ]),
                          _c("div", { staticClass: "content_middle_more" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: "https://blog.fit2cloud.com/?cat=321",
                                },
                              },
                              [
                                _vm._v(_vm._s(_vm.$t("wizard.more"))),
                                _c("i", { staticClass: "el-icon-arrow-right" }),
                              ]
                            ),
                          ]),
                        ]),
                        _c("el-row", [
                          _c(
                            "ul",
                            { staticClass: "ul-custom" },
                            _vm._l(_vm.blogsInfo, function (blogItem) {
                              return _c(
                                "li",
                                {
                                  key: blogItem.title,
                                  staticClass: "li-custom",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "li-a",
                                      attrs: {
                                        target: "_blank",
                                        href: blogItem.href,
                                        title: blogItem.title,
                                      },
                                    },
                                    [_vm._v(_vm._s(blogItem.title))]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("el-row", { staticClass: "content_bottom" }, [
                    _c(
                      "div",
                      { staticClass: "content_bottom_contact" },
                      [
                        _c("el-row", { staticClass: "contact_title" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("wizard.contact_us"))),
                          ]),
                        ]),
                        _c("el-row", { staticClass: "contact_content" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("wizard.email")) +
                                "dataease@fit2cloud.com"
                            ),
                          ]),
                        ]),
                        _c("el-row", { staticClass: "contact_content" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("wizard.tel")) + "400-052-0755"
                            ),
                          ]),
                        ]),
                        _c("el-row", { staticClass: "contact_content" }, [
                          _vm._v(
                            "\n            " + _vm._s(_vm.$t("wizard.web"))
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              attrs: {
                                target: "_blank",
                                href: "https://www.dataease.io",
                              },
                            },
                            [_vm._v("www.dataease.io")]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "content_bottom_qr_code" }, [
                      _c("div", { staticClass: "contact_wechat_train" }, [
                        _c("div", { staticClass: "contact_title_qr" }, [
                          _vm._v(_vm._s(_vm.$t("wizard.f2c_train"))),
                        ]),
                        _c("img", {
                          staticClass: "contact_wechat_train_img",
                          attrs: {
                            src: require("@/assets/wizard_wechat-train.png"),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "contact_wechat_official" }, [
                        _c("div", { staticClass: "contact_title_qr" }, [
                          _vm._v(
                            _vm._s(_vm.$t("wizard.weChat_official_account"))
                          ),
                        ]),
                        _c("img", {
                          staticClass: "contact_wechat_official_img",
                          attrs: {
                            src: require("@/assets/wizard_wechat-official.jpeg"),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "contact_wechat_group" }, [
                        _c("div", { staticClass: "contact_title_qr" }, [
                          _vm._v(_vm._s(_vm.$t("wizard.technical_group"))),
                        ]),
                        _c("img", {
                          staticClass: "contact_wechat_group_img",
                          attrs: {
                            src: require("@/assets/wizard_wechat-group.png"),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }