var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.contentShow,
            expression: "contentShow",
          },
        ],
        staticClass: "login-background",
      },
      [
        _c(
          "div",
          { staticClass: "login-container" },
          [
            _c(
              "el-row",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { type: "flex" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.qrTypes.length,
                            expression: "qrTypes.length",
                          },
                        ],
                        class: _vm.codeShow ? "trans-pc" : "trans",
                        on: { click: _vm.showQr },
                      },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imgAppShow,
                              expression: "imgAppShow",
                            },
                          ],
                          staticClass: "imgApp",
                        }),
                      ]
                    ),
                    _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.codeShow,
                            expression: "!codeShow",
                          },
                        ],
                        ref: "loginForm",
                        attrs: {
                          model: _vm.loginForm,
                          rules: _vm.loginRules,
                          size: "default",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "login-logo" },
                          [
                            !_vm.loginLogoUrl && _vm.axiosFinished
                              ? _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "DataEase",
                                    "custom-class": "login-logo-icon",
                                  },
                                })
                              : _vm._e(),
                            _vm.loginLogoUrl && _vm.axiosFinished
                              ? _c("img", {
                                  attrs: { src: _vm.loginLogoUrl, alt: "" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.uiInfo &&
                        _vm.uiInfo["ui.loginTitle"] &&
                        _vm.uiInfo["ui.loginTitle"].paramValue
                          ? _c("div", { staticClass: "login-welcome" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.uiInfo["ui.loginTitle"].paramValue
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _c("div", { staticClass: "login-welcome" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("login.welcome") +
                                      ((_vm.uiInfo &&
                                        _vm.uiInfo["ui.title"] &&
                                        _vm.uiInfo["ui.title"].paramValue) ||
                                        " DataEase")
                                  ) +
                                  "\n            "
                              ),
                            ]),
                        _c(
                          "div",
                          { staticClass: "login-form" },
                          [
                            _vm.radioTypes.length > 1
                              ? _c(
                                  "el-form-item",
                                  [
                                    _vm.radioTypes.length > 1
                                      ? _c(
                                          "el-radio-group",
                                          {
                                            on: { change: _vm.changeLoginType },
                                            model: {
                                              value: _vm.loginForm.loginType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.loginForm,
                                                  "loginType",
                                                  $$v
                                                )
                                              },
                                              expression: "loginForm.loginType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: 0,
                                                  size: "mini",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "login.default_login"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.loginTypes.includes(1)
                                              ? _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: 1,
                                                      size: "mini",
                                                    },
                                                  },
                                                  [_vm._v("LDAP")]
                                                )
                                              : _vm._e(),
                                            _vm.loginTypes.includes(2)
                                              ? _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: 2,
                                                      size: "mini",
                                                    },
                                                  },
                                                  [_vm._v("OIDC")]
                                                )
                                              : _vm._e(),
                                            _vm.loginTypes.includes(7)
                                              ? _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: 7,
                                                      size: "mini",
                                                    },
                                                  },
                                                  [_vm._v("Lark")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "ID",
                                    autofocus: "",
                                    disabled:
                                      _vm.loginTypes.includes(2) &&
                                      _vm.loginForm.loginType === 2,
                                  },
                                  model: {
                                    value: _vm.loginForm.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginForm, "username", $$v)
                                    },
                                    expression: "loginForm.username",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: _vm.$t("login.password"),
                                    "show-password": "",
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    autocomplete: "new-password",
                                    disabled:
                                      _vm.loginTypes.includes(2) &&
                                      _vm.loginForm.loginType === 2,
                                  },
                                  nativeOn: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleLogin($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.loginForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginForm, "password", $$v)
                                    },
                                    expression: "loginForm.password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "login-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "submit",
                                attrs: {
                                  type: "primary",
                                  size: "default",
                                  disabled:
                                    _vm.loginTypes.includes(2) &&
                                    _vm.loginForm.loginType === 2,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleLogin($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("commons.login")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm.uiInfo &&
                            _vm.uiInfo["ui.demo.tips"] &&
                            _vm.uiInfo["ui.demo.tips"].paramValue
                              ? _c("div", { staticClass: "demo-tips" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.uiInfo["ui.demo.tips"].paramValue
                                      ) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "login-msg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.msg) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.codeShow,
                            expression: "codeShow",
                          },
                        ],
                        staticClass: "code",
                      },
                      [
                        _c(
                          "el-row",
                          { staticClass: "code-contaniner" },
                          [
                            _vm.codeShow &&
                            _vm.loginTypes.includes(4) &&
                            _vm.codeIndex === 4
                              ? _c("plugin-com", {
                                  ref: "WecomQr",
                                  attrs: { "component-name": "WecomQr" },
                                })
                              : _vm._e(),
                            _vm.codeShow &&
                            _vm.loginTypes.includes(5) &&
                            _vm.codeIndex === 5
                              ? _c("plugin-com", {
                                  ref: "DingtalkQr",
                                  attrs: { "component-name": "DingtalkQr" },
                                })
                              : _vm._e(),
                            _vm.codeShow &&
                            _vm.loginTypes.includes(6) &&
                            _vm.codeIndex === 6
                              ? _c("plugin-com", {
                                  ref: "LarkQr",
                                  attrs: { "component-name": "LarkQr" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.qrTypes.length > 1
                          ? _c("div", { staticClass: "login-third-items" }, [
                              _vm.qrTypes.includes(4)
                                ? _c("span", {
                                    staticClass:
                                      "login-third-item login-third-wecom",
                                    on: {
                                      click: function ($event) {
                                        return _vm.switchCodeIndex(4)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.qrTypes.includes(5)
                                ? _c("span", {
                                    staticClass:
                                      "login-third-item login-third-dingtalk",
                                    on: {
                                      click: function ($event) {
                                        return _vm.switchCodeIndex(5)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.qrTypes.includes(6)
                                ? _c("span", {
                                    staticClass:
                                      "login-third-item login-third-lark",
                                    on: {
                                      click: function ($event) {
                                        return _vm.switchCodeIndex(6)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: !_vm.axiosFinished,
                        expression: "!axiosFinished",
                      },
                    ],
                    attrs: { span: 12 },
                  },
                  [
                    !_vm.loginImageUrl && _vm.axiosFinished
                      ? _c("div", { staticClass: "login-image" })
                      : _vm._e(),
                    _vm.loginImageUrl && _vm.axiosFinished
                      ? _c("div", {
                          staticClass: "login-image-de",
                          style: {
                            background:
                              "url(" + _vm.loginImageUrl + ") no-repeat",
                            backgroundSize: "contain",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loginTypes.includes(2) && _vm.loginForm.loginType === 2
          ? _c("plugin-com", {
              ref: "SSOComponent",
              attrs: { "component-name": "SSOComponent" },
            })
          : _vm._e(),
        _vm.loginTypes.includes(7) && _vm.loginForm.loginType === 7
          ? _c("plugin-com", {
              ref: "LarksuiteQr",
              attrs: { "component-name": "LarksuiteQr" },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.showFoot
      ? _c("div", {
          staticClass: "dynamic-login-foot",
          domProps: { innerHTML: _vm._s(_vm.footContent) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }