var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
      staticClass: "de-earth",
      staticStyle: { height: "calc(100vh - 150px)" },
    },
    [
      _c(
        "de-aside-container",
        { staticStyle: { height: "100%" }, attrs: { type: "mapset" } },
        [
          _c("map-setting-left", {
            ref: "map_setting_tree",
            attrs: { "tree-data": _vm.treeData },
            on: {
              "emit-add": _vm.emitAdd,
              "refresh-tree": _vm.refreshTree,
              "show-node-info": _vm.loadForm,
            },
          }),
        ],
        1
      ),
      _c(
        "de-main-container",
        { staticStyle: { height: "100%" } },
        [
          _c("map-setting-right", {
            ref: "map_setting_form",
            attrs: { "tree-data": _vm.treeData, status: _vm.formStatus },
            on: { "refresh-tree": _vm.refreshTree },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }