var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-row", { staticClass: "demo_main" }, [
    _c("div", { staticClass: "demo_title" }),
    _c("div", { staticClass: "demo_content" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.bilibili.com/video/BV1i34y1v7hq/",
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(_vm.$t("wizard.demo_video_hint")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }