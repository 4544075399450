var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c(
          "el-popover",
          { attrs: { placement: "right", width: "250", trigger: "click" } },
          [
            _c(
              "el-col",
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "yes" },
                    on: { change: _vm.onChangePanelStyle },
                    model: {
                      value: _vm.panel.gap,
                      callback: function ($$v) {
                        _vm.$set(_vm.panel, "gap", $$v)
                      },
                      expression: "panel.gap",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("panel.gap")))]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "no" },
                    on: { change: _vm.onChangePanelStyle },
                    model: {
                      value: _vm.panel.gap,
                      callback: function ($$v) {
                        _vm.$set(_vm.panel, "gap", $$v)
                      },
                      expression: "panel.gap",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("panel.no_gap")))]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "shape-item",
                attrs: { slot: "reference", size: "mini" },
                slot: "reference",
              },
              [
                _vm._v(_vm._s(_vm.$t("panel.component_gap")) + " "),
                _c("i", { staticClass: "el-icon-setting el-icon--right" }),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }