var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field-block-style" }, [
    _c(
      "div",
      { staticClass: "field-block-option" },
      [
        _c("span", { staticClass: "option-field" }, [
          _vm._v(
            _vm._s(_vm.$t("dataset.field_select")) +
              "(" +
              _vm._s(_vm.checkedFields.length) +
              "/" +
              _vm._s(_vm.fieldList.length) +
              ")"
          ),
        ]),
        _c("el-input", {
          staticClass: "option-input",
          attrs: {
            size: "small",
            placeholder: _vm.$t("auth.search_by_field"),
            "prefix-icon": "el-icon-search",
            clearable: "",
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "field-block-head" },
      [
        _c(
          "el-checkbox",
          {
            staticClass: "check-style",
            attrs: { indeterminate: _vm.isIndeterminate },
            on: { change: _vm.handleCheckAllChange },
            model: {
              value: _vm.checkAll,
              callback: function ($$v) {
                _vm.checkAll = $$v
              },
              expression: "checkAll",
            },
          },
          [_vm._v(" ")]
        ),
        _c("span", { staticClass: "label-style" }, [
          _c("span", { staticClass: "field-origin-style" }, [
            _vm._v(_vm._s(_vm.$t("panel.column_name"))),
          ]),
          _c("span", { staticClass: "field-style" }, [
            _vm._v(_vm._s(_vm.$t("deDataset.original_name"))),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "field-block-body" },
      [
        _c(
          "el-checkbox-group",
          {
            on: { change: _vm.handleCheckedCitiesChange },
            model: {
              value: _vm.checkedFields,
              callback: function ($$v) {
                _vm.checkedFields = $$v
              },
              expression: "checkedFields",
            },
          },
          _vm._l(_vm.fieldSearchList, function (field) {
            return _c(
              "div",
              { key: field.id, staticClass: "field-item-style" },
              [
                _c(
                  "el-checkbox",
                  { staticClass: "check-style", attrs: { label: field.id } },
                  [_vm._v(" ")]
                ),
                _c("span", { staticClass: "label-style" }, [
                  _c(
                    "span",
                    {
                      staticClass: "field-origin-style value",
                      attrs: { title: field.originName },
                    },
                    [
                      _c(
                        "span",
                        [
                          field.deType === 0
                            ? _c("svg-icon", {
                                staticClass: "field-icon-text",
                                attrs: { "icon-class": "field_text" },
                              })
                            : _vm._e(),
                          field.deType === 1
                            ? _c("svg-icon", {
                                staticClass: "field-icon-time",
                                attrs: { "icon-class": "field_time" },
                              })
                            : _vm._e(),
                          field.deType === 2 || field.deType === 3
                            ? _c("svg-icon", {
                                staticClass: "field-icon-value",
                                attrs: { "icon-class": "field_value" },
                              })
                            : _vm._e(),
                          field.deType === 5
                            ? _c("svg-icon", {
                                staticClass: "field-icon-location",
                                attrs: { "icon-class": "field_location" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "origin-style" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(field.originName) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "field-style value",
                      attrs: { title: field.name },
                    },
                    [_vm._v(_vm._s(field.name))]
                  ),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }