var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-row",
        { staticStyle: { height: "56px" } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "text-align": "left", "line-height": "56px" },
              attrs: { span: 12 },
            },
            [
              _c("svg-icon", {
                staticClass: "toolbar-icon-active icon20 margin-left20",
                attrs: { "icon-class": "icon_left_outlined" },
                on: { click: _vm.closePreview },
              }),
              _c("span", { staticClass: "text16 margin-left12" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.templateInfo.name) + "\n      "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: {
                "text-align": "right",
                "line-height": "56px",
                "padding-right": "24px",
              },
              attrs: { span: 12 },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary", width: "80px" },
                  on: { click: _vm.appApply },
                },
                [_vm._v(_vm._s(_vm.$t("panel.apply")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-row", { staticClass: "img-main" }, [
        _c("img", {
          attrs: { width: "100%", src: _vm.templateInfo.snapshot, alt: "" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }