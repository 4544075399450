var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-move-tree" },
    [
      _c("el-input", {
        staticClass: "main-area-input",
        staticStyle: { "margin-bottom": "16px" },
        attrs: {
          size: "small",
          placeholder: _vm.$t("commons.search"),
          "prefix-icon": "el-icon-search",
          clearable: "",
        },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "tree",
        },
        [
          _c("el-tree", {
            ref: "datasetTreeRef",
            staticClass: "de-tree",
            attrs: {
              "current-node-key": _vm.checkedTable ? _vm.checkedTable.id : "",
              "default-expanded-keys": _vm.expandedArray,
              data: _vm.treeData,
              "node-key": "id",
              "highlight-current": "",
              "expand-on-click-node": true,
              "filter-node-method": _vm.filterNode,
            },
            on: { "node-click": _vm.nodeClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var data = ref.data
                    return data.modelInnerType === "group"
                      ? _c("span", { staticClass: "custom-tree-node" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1",
                                width: "0",
                              },
                            },
                            [
                              data.modelInnerType === "group"
                                ? _c(
                                    "span",
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "scene" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "6px",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                  attrs: { title: data.name },
                                },
                                [_vm._v(_vm._s(data.name))]
                              ),
                            ]
                          ),
                        ])
                      : _c("span", { staticClass: "custom-tree-node-list" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1",
                                width: "0",
                              },
                              attrs: { id: data.id },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("svg-icon", {
                                    class: "ds-icon-" + data.modelInnerType,
                                    attrs: {
                                      "icon-class": "ds-" + data.modelInnerType,
                                    },
                                  }),
                                ],
                                1
                              ),
                              ["db", "sql"].includes(data.modelInnerType)
                                ? _c("span", [
                                    data.mode === 0
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-s-operation",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    data.mode === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-alarm-clock",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "6px",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                  attrs: { title: data.name },
                                },
                                [_vm._v(_vm._s(data.name))]
                              ),
                            ]
                          ),
                        ])
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }