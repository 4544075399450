var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c("div", { staticClass: "de-ds-cont" }, [
      _c("div", { staticClass: "de-ds-inner" }, [
        _c(
          "div",
          { staticClass: "w600" },
          [
            _vm.positionCheck("appMarket")
              ? _c(
                  "el-form",
                  {
                    ref: "attachParamsForm",
                    staticClass: "de-form-item",
                    attrs: {
                      model: _vm.attachForm,
                      rules: _vm.attachRule,
                      "label-width": "180px",
                      "label-position": "right",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "de-row-rules",
                        staticStyle: { margin: "0 0 16px 0" },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("datasource.basic_info"))),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("app_template.panel_position"),
                          prop: "panelGroupPid",
                        },
                      },
                      [
                        _c("treeselect", {
                          attrs: {
                            disabled: _vm.baseInfoDisabledCheck(
                              _vm.outerParams.panelPrivileges
                            ),
                            clearable: false,
                            options: _vm.panelGroupList,
                            normalizer: _vm.normalizer,
                            placeholder: _vm.$t("chart.select_group"),
                            "no-children-text": _vm.$t(
                              "commons.treeselect.no_children_text"
                            ),
                            "no-options-text": _vm.$t(
                              "commons.treeselect.no_options_text"
                            ),
                            "no-results-text": _vm.$t(
                              "commons.treeselect.no_results_text"
                            ),
                          },
                          model: {
                            value: _vm.attachForm.panelGroupPid,
                            callback: function ($$v) {
                              _vm.$set(_vm.attachForm, "panelGroupPid", $$v)
                            },
                            expression: "attachForm.panelGroupPid",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("app_template.panel_name"),
                          prop: "panelName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.baseInfoDisabledCheck(
                              _vm.outerParams.panelPrivileges
                            ),
                          },
                          model: {
                            value: _vm.attachForm.panelName,
                            callback: function ($$v) {
                              _vm.$set(_vm.attachForm, "panelName", $$v)
                            },
                            expression: "attachForm.panelName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("app_template.dataset_group_position"),
                          prop: "datasetGroupPid",
                        },
                      },
                      [
                        _c("treeselect", {
                          attrs: {
                            disabled: _vm.baseInfoDisabledCheck(
                              _vm.outerParams.datasetPrivileges
                            ),
                            clearable: false,
                            options: _vm.datasetGroupList,
                            normalizer: _vm.normalizer,
                            placeholder: _vm.$t("chart.select_group"),
                            "no-children-text": _vm.$t(
                              "commons.treeselect.no_children_text"
                            ),
                            "no-options-text": _vm.$t(
                              "commons.treeselect.no_options_text"
                            ),
                            "no-results-text": _vm.$t(
                              "commons.treeselect.no_results_text"
                            ),
                          },
                          model: {
                            value: _vm.attachForm.datasetGroupPid,
                            callback: function ($$v) {
                              _vm.$set(_vm.attachForm, "datasetGroupPid", $$v)
                            },
                            expression: "attachForm.datasetGroupPid",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("app_template.dataset_group_name"),
                          prop: "datasetGroupName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.baseInfoDisabledCheck(
                              _vm.outerParams.datasetPrivileges
                            ),
                          },
                          model: {
                            value: _vm.attachForm.datasetGroupName,
                            callback: function ($$v) {
                              _vm.$set(_vm.attachForm, "datasetGroupName", $$v)
                            },
                            expression: "attachForm.datasetGroupName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "de-row-rules" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.positionCheck("appMarket")
                      ? _vm.$t("app_template.datasource_info")
                      : _vm.$t("datasource.basic_info")
                  )
                ),
              ]),
            ]),
            _c(
              "el-form",
              {
                ref: "historyDsForm",
                staticClass: "de-form-item",
                attrs: {
                  model: _vm.attachForm,
                  rules: _vm.historyFormRule,
                  size: "small",
                  disabled: _vm.formType === "modify",
                  "label-width": "180px",
                  "label-position": "right",
                },
              },
              [
                _vm.positionCheck("appMarket")
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("app_template.datasource_from"),
                          prop: "datasourceFrom",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            model: {
                              value: _vm.attachForm.datasourceFrom,
                              callback: function ($$v) {
                                _vm.$set(_vm.attachForm, "datasourceFrom", $$v)
                              },
                              expression: "attachForm.datasourceFrom",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "new" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("app_template.datasource_new")
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _c("el-radio", { attrs: { label: "history" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("app_template.datasource_history")
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.positionCheck("appMarket") &&
                _vm.attachForm.datasourceFrom === "history"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("app_template.datasource"),
                          prop: "datasourceHistoryId",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              placeholder: _vm.$t(
                                "dataset.pls_slc_data_source"
                              ),
                            },
                            model: {
                              value: _vm.attachForm.datasourceHistoryId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.attachForm,
                                  "datasourceHistoryId",
                                  $$v
                                )
                              },
                              expression: "attachForm.datasourceHistoryId",
                            },
                          },
                          _vm._l(_vm.appMarketDatasource, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              },
                              [
                                item.status !== "Error" &&
                                item.status !== "Warning"
                                  ? _c(
                                      "span",
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "db-de" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.status === "Error"
                                  ? _c(
                                      "span",
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ds-icon-scene",
                                          attrs: {
                                            "icon-class": "de-ds-error",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.status === "Warning"
                                  ? _c(
                                      "span",
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ds-icon-scene",
                                          attrs: {
                                            "icon-class": "de-ds-warning",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            !_vm.positionCheck("appMarket") ||
            (_vm.positionCheck("appMarket") &&
              _vm.attachForm.datasourceFrom === "new")
              ? _c(
                  "el-form",
                  {
                    ref: "dsForm",
                    staticClass: "de-form-item",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rule,
                      size: "small",
                      disabled: _vm.disabled,
                      "label-width": "180px",
                      "label-position": "right",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("datasource.display_name"),
                          prop: "name",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            autocomplete: "off",
                            placeholder: _vm.$t("datasource.input_name"),
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("datasource.type"),
                          prop: "type",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t(
                                "datasource.please_choose_type"
                              ),
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          },
                          _vm._l(_vm.dsTypes, function (item) {
                            return _c("el-option", {
                              key: item.type,
                              attrs: { label: item.name, value: item.type },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("commons.description"),
                          prop: "desc",
                        },
                      },
                      [
                        _c("deTextarea", {
                          staticClass: "w100-textarea",
                          attrs: { maxlength: 50 },
                          model: {
                            value: _vm.form.desc,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "desc", $$v)
                            },
                            expression: "form.desc",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.datasourceType.isJdbc
                      ? _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("driver.driver") } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "select-width",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "driver.please_choose_driver"
                                  ),
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.form.configuration.customDriver,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.configuration,
                                      "customDriver",
                                      $$v
                                    )
                                  },
                                  expression: "form.configuration.customDriver",
                                },
                              },
                              _vm._l(_vm.driverList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.name,
                                    value: item.id,
                                    disabled: !item.driverClass,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.datasourceType.isPlugin
                      ? _c("ds-configuration", {
                          ref: "dsConfig",
                          attrs: {
                            "datasource-type": _vm.datasourceType,
                            form: _vm.form,
                            disabled: _vm.disabled,
                          },
                        })
                      : _vm._e(),
                    _vm.datasourceType.isPlugin
                      ? _c("plugin-com", {
                          ref: "pluginDsConfig",
                          attrs: {
                            "component-name": _vm.datasourceType.type,
                            obj: { form: _vm.form, disabled: _vm.disabled },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _vm.positionCheck("appMarket") && _vm.attachForm.datasourceFrom === "new"
      ? _c("div", { staticClass: "de-ds-bottom" }, [
          _c(
            "div",
            { staticClass: "apply", staticStyle: { width: "100%" } },
            [
              _vm.canEdit
                ? [
                    _c(
                      "deBtn",
                      {
                        attrs: { secondary: "" },
                        on: { click: _vm.closeDraw },
                      },
                      [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n        ")]
                    ),
                    _vm.formType === "add" ||
                    _vm.hasDataPermission("manage", _vm.params.privileges)
                      ? _c(
                          "deBtn",
                          {
                            attrs: { secondary: "" },
                            on: { click: _vm.validaDatasource },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.validate")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.formType === "add" ||
                    _vm.hasDataPermission("manage", _vm.params.privileges)
                      ? _c(
                          "deBtn",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.save },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.save")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    (
                      _vm.formType === "add"
                        ? true
                        : _vm.hasDataPermission(
                            "manage",
                            _vm.outerParams.panelPrivileges
                          ) ||
                          _vm.hasDataPermission(
                            "manage",
                            _vm.outerParams.datasetPrivileges
                          )
                    )
                      ? _c("deBtn", { on: { click: _vm.validaDatasource } }, [
                          _vm._v(
                            _vm._s(_vm.$t("commons.validate")) + "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm.positionCheck("appMarket") &&
    _vm.attachForm.datasourceFrom === "history"
      ? _c("div", { staticClass: "de-ds-bottom" }, [
          _c(
            "div",
            { staticClass: "apply", staticStyle: { width: "100%" } },
            [
              _vm.canEdit
                ? [
                    _c(
                      "deBtn",
                      {
                        attrs: { secondary: "" },
                        on: { click: _vm.closeDraw },
                      },
                      [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n        ")]
                    ),
                    (
                      _vm.formType === "add"
                        ? true
                        : _vm.hasDataPermission("manage", _vm.params.privileges)
                    )
                      ? _c(
                          "deBtn",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.saveAppMarketHistory },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("commons.save")) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }