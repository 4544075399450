var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "outer-body" },
    [
      _vm.previewModel
        ? _c("AppMarketPreview", {
            attrs: { "template-info": _vm.previewItem },
            on: {
              closePreview: function ($event) {
                _vm.previewModel = false
              },
              appApply: _vm.appApply,
            },
          })
        : _vm._e(),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.previewModel,
              expression: "!previewModel",
            },
          ],
          staticClass: "market-main",
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "title-left" }, [
                  _vm._v(_vm._s(_vm.$t("app_template.app_manager"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.marketActiveTab,
                    callback: function ($$v) {
                      _vm.marketActiveTab = $$v
                    },
                    expression: "marketActiveTab",
                  },
                },
                _vm._l(_vm.marketTabs, function (tabItem, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: tabItem.label, name: tabItem.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.marketActiveTab === "apps",
                  expression: "marketActiveTab==='apps'",
                },
              ],
            },
            [
              _c("app-template-content", {
                ref: "appTemplateContent",
                staticClass: "template-main-content",
                attrs: { "show-position": "market-manage" },
                on: { previewApp: _vm.previewApp, applyNew: _vm.applyNew },
              }),
            ],
            1
          ),
          _vm.marketActiveTab === "apply_logs"
            ? _c(
                "el-row",
                { staticClass: "main-log-area template-main" },
                [_c("app-template-log", { staticClass: "log-area" })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value:
                _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
              expression:
                "$store.getters.loadingMap[$store.getters.currentPath]",
            },
          ],
          staticClass: "market-dialog-css",
          attrs: {
            title: _vm.$t("panel.apply_template"),
            visible: _vm.folderSelectShow,
            width: "80%",
            top: "5vh",
            "append-to-body": true,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.folderSelectShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "panelForm",
              attrs: {
                model: _vm.panelForm,
                rules: _vm.rule,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("panel.name"), prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: true,
                      placeholder: _vm.$t("panel.enter_name_tips"),
                    },
                    model: {
                      value: _vm.panelForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.panelForm, "name", $$v)
                      },
                      expression: "panelForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("commons.folder"), prop: "pid" } },
                [
                  _c("treeselect", {
                    attrs: {
                      clearable: false,
                      options: _vm.panelGroupList,
                      normalizer: _vm.normalizer,
                      placeholder: _vm.$t("chart.select_group"),
                      "no-children-text": _vm.$t(
                        "commons.treeselect.no_children_text"
                      ),
                      "no-options-text": _vm.$t(
                        "commons.treeselect.no_options_text"
                      ),
                      "no-results-text": _vm.$t(
                        "commons.treeselect.no_results_text"
                      ),
                    },
                    model: {
                      value: _vm.panelForm.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.panelForm, "pid", $$v)
                      },
                      expression: "panelForm.pid",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer dialog-footer-self",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.folderSelectShow = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: !_vm.panelForm.name || !_vm.panelForm.pid,
                  },
                  on: { click: _vm.apply },
                },
                [_vm._v(_vm._s(_vm.$t("commons.confirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("keep-alive", [_c("app-template-apply", { ref: "templateApply" })], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }