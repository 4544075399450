var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "div",
        { staticClass: "table-count" },
        [
          _c(
            "span",
            { staticClass: "title-text", staticStyle: { width: "100px" } },
            [
              _vm._v(
                _vm._s(_vm.$t("deDataset.display")) +
                  " " +
                  _vm._s(_vm.form.row) +
                  "\n      " +
                  _vm._s(_vm.$t("deDataset.row"))
              ),
            ]
          ),
          _c(
            "el-popover",
            {
              ref: "setCount",
              attrs: {
                "popper-class": "de-set-count de-card-dropdown",
                placement: "right-start",
                width: "306",
                trigger: "click",
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("deDataset.show_rows")) + "\n      "
              ),
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.rowNum,
                  callback: function ($$v) {
                    _vm.rowNum = $$v
                  },
                  expression: "rowNum",
                },
              }),
              _c(
                "div",
                { staticClass: "foot" },
                [
                  _c(
                    "deBtn",
                    { attrs: { secondary: "" }, on: { click: _vm.cancel } },
                    [_vm._v(_vm._s(_vm.$t("commons.cancel")) + " ")]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.searchRow },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("commons.confirm")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("i", {
                staticClass: "el-icon-edit",
                attrs: { slot: "reference" },
                slot: "reference",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "ux-grid",
        {
          ref: "plxTable",
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            height: _vm.height,
            "checkbox-config": { highlight: true },
            "width-resize": true,
          },
        },
        _vm._l(_vm.fields, function (field) {
          return _c(
            "ux-table-column",
            {
              key: field.id,
              attrs: {
                "min-width": "200px",
                field: field.dataeaseName,
                resizable: true,
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  field.deType === 0
                    ? _c("svg-icon", {
                        staticClass: "field-icon-text",
                        attrs: { "icon-class": "field_text" },
                      })
                    : _vm._e(),
                  field.deType === 1
                    ? _c("svg-icon", {
                        staticClass: "field-icon-time",
                        attrs: { "icon-class": "field_time" },
                      })
                    : _vm._e(),
                  field.deType === 2 || field.deType === 3
                    ? _c("svg-icon", {
                        staticClass: "field-icon-value",
                        attrs: { "icon-class": "field_value" },
                      })
                    : _vm._e(),
                  field.deType === 5
                    ? _c("svg-icon", {
                        staticClass: "field-icon-location",
                        attrs: { "icon-class": "field_location" },
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(field.name))]),
                ],
                1
              ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }