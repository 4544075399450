var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "de-dialog-form",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.createDataset,
        width: "600px",
        "before-close": _vm.resetForm,
      },
      on: {
        "update:visible": function ($event) {
          _vm.createDataset = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "datasetForm",
          staticClass: "de-form-item",
          attrs: { model: _vm.datasetForm, rules: _vm.datasetFormRules },
        },
        [
          _vm.datasetFormRules.name
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("dataset.name"), prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.datasetForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.datasetForm, "name", $$v)
                      },
                      expression: "datasetForm.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("deDataset.folder"), prop: "id" } },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    "popper-class": "user-popper dataset-filed",
                    width: "552",
                    trigger: "click",
                  },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "de-tree",
                    attrs: {
                      data: _vm.tData,
                      "node-key": "id",
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "node-click": _vm.nodeClick },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node-dataset" },
                            [
                              data.modelInnerType === "group"
                                ? _c(
                                    "span",
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "scene" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "6px",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                  attrs: { title: data.name },
                                },
                                [_vm._v(_vm._s(data.name))]
                              ),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        slot: "reference",
                        filterable: "",
                        "popper-class": "tree-select-dataset",
                        "filter-method": _vm.filterMethod,
                        placeholder: _vm.$t("commons.please_select"),
                      },
                      slot: "reference",
                      model: {
                        value: _vm.datasetForm.id,
                        callback: function ($$v) {
                          _vm.$set(_vm.datasetForm, "id", $$v)
                        },
                        expression: "datasetForm.id",
                      },
                    },
                    _vm._l(_vm.selectDatasets, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "deBtn",
            { attrs: { secondary: "" }, on: { click: _vm.resetForm } },
            [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n    ")]
          ),
          _c(
            "deBtn",
            { attrs: { type: "primary" }, on: { click: _vm.saveDataset } },
            [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }