var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
    },
    [
      _c(
        "de-aside-container",
        [
          _c("group", {
            ref: "group",
            attrs: { "save-status": _vm.saveStatus },
            on: { switchComponent: _vm.switchComponent },
          }),
        ],
        1
      ),
      _c(
        "de-main-container",
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { param: _vm.param },
            on: {
              switchComponent: _vm.switchComponent,
              saveSuccess: _vm.saveSuccess,
              typeChange: _vm.typeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }