var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "de-dialog-form",
      attrs: {
        title: _vm.$t("chart.select_dataset"),
        visible: _vm.selectDatasetFlag,
        width: "1200px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.selectDatasetFlag = $event
        },
      },
    },
    [
      _c("div", { staticClass: "dataset-task-table" }, [
        _c(
          "div",
          { staticClass: "dataset-tree" },
          [
            _c("el-input", {
              attrs: {
                size: "mini",
                placeholder: _vm.$t("commons.search"),
                "prefix-icon": "el-icon-search",
                clearable: "",
              },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.treeLoading,
                    expression: "treeLoading",
                  },
                ],
                staticClass: "tree",
              },
              [
                _c("el-tree", {
                  ref: "datasetTreeRef",
                  attrs: {
                    "current-node-key": "id",
                    data: _vm.treeData,
                    "node-key": "id",
                    "highlight-current": "",
                    "expand-on-click-node": true,
                    "filter-node-method": _vm.filterNode,
                  },
                  on: { "node-click": _vm.nodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          data.modelInnerType === "group"
                            ? _c(
                                "span",
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "scene" },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "6px",
                                        "white-space": "nowrap",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      },
                                      attrs: { title: data.name },
                                    },
                                    [_vm._v(_vm._s(data.name))]
                                  ),
                                ],
                                1
                              )
                            : _c("span", [
                                _c(
                                  "span",
                                  [
                                    _c("svg-icon", {
                                      class: "ds-icon-" + data.modelInnerType,
                                      attrs: {
                                        "icon-class":
                                          "ds-" + data.modelInnerType,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-left": "6px",
                                      "white-space": "nowrap",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                    },
                                    attrs: { title: data.name },
                                  },
                                  [_vm._v(_vm._s(data.name))]
                                ),
                              ]),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticClass: "dataset-tree-table",
          },
          [
            _vm.tableName
              ? _c("p", { staticClass: "table-name" }, [
                  _vm._v("\n        " + _vm._s(_vm.tableName) + " "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("chart.preview_100_data"))),
                  ]),
                ])
              : _vm._e(),
            _vm.table.length
              ? _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { border: "", data: _vm.table },
                  },
                  _vm._l(_vm.fields, function (field) {
                    return _c(
                      "el-table-column",
                      {
                        key: field.dataeaseName,
                        attrs: {
                          "min-width": "200",
                          prop: field.dataeaseName,
                          resizable: true,
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c("svg-icon", {
                              class: _vm.iconFormat(field.deType).class,
                              attrs: {
                                "icon-class": _vm.iconFormat(field.deType)
                                  .iconClass,
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(field.name))]),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  }),
                  1
                )
              : _c("el-empty", { attrs: { description: _vm.$t("暂无数据") } }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "deBtn",
            {
              attrs: { secondary: "" },
              on: {
                click: function ($event) {
                  _vm.selectDatasetFlag = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("chart.cancel")))]
          ),
          _c(
            "deBtn",
            {
              attrs: { disabled: !_vm.tableName, type: "primary" },
              on: { click: _vm.setIdName },
            },
            [_vm._v(_vm._s(_vm.$t("fu.table.ok")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }