<template>
  <div style="margin-top: 5px">
    <div> 导出时间：$TIME$</div>
    <div contenteditable="true"> 可以输入内容</div>
    <div>
      <img
        width="100%"
        src="$snapshot$"
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {

  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
