var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "el-row",
        { staticClass: "custom-position", staticStyle: { height: "100%" } },
        [_vm._v("\n    " + _vm._s(_vm.$t("chart.pls_slc_tbl_left")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }