var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-layout-content",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "top-install" },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("components.by_plugin_name"),
              size: "small",
              "prefix-icon": "el-icon-search",
              clearable: "",
            },
            on: { blur: _vm.search },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c(
            "el-upload",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["plugin:upload"],
                  expression: "['plugin:upload']",
                },
              ],
              attrs: {
                action: _vm.baseUrl + "api/plugin/upload",
                multiple: false,
                "show-file-list": false,
                "file-list": _vm.fileList,
                accept: ".zip",
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.uploadSuccess,
                "on-error": _vm.uploadFail,
                name: "file",
                headers: _vm.headers,
              },
            },
            [
              _c(
                "deBtn",
                {
                  attrs: {
                    icon: !_vm.uploading
                      ? "el-icon-upload2"
                      : "el-icon-loading",
                    type: "primary",
                    disabled: _vm.uploading,
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          !_vm.uploading
                            ? "plugin.local_install"
                            : "dataset.uploading"
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.data.length
        ? _c(
            "div",
            { staticClass: "plugin-cont" },
            [
              _c("el-empty", {
                staticStyle: { width: "100%" },
                attrs: { description: "没有找到相关内容" },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "plugin-cont" },
            _vm._l(_vm.data, function (ele) {
              return _c(
                "div",
                { key: ele.pluginId, staticClass: "de-card-plugin" },
                [
                  _c("div", { staticClass: "card-info" }, [
                    _c(
                      "div",
                      { staticClass: "info-top" },
                      [
                        _c(
                          "el-image",
                          {
                            attrs: {
                              src:
                                "/api/pluginCommon/staticInfo/" +
                                ele.moduleName +
                                "/svg",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [_c("img", { attrs: { src: _vm.imgDefault } })]
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(ele.name)),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: ele.descript,
                              placement: "top",
                            },
                          },
                          [
                            _c("p", { staticClass: "tips" }, [
                              _vm._v(_vm._s(ele.descript)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "info-left" },
                      _vm._l(_vm.listName, function (item) {
                        return _c(
                          "p",
                          { key: item, staticClass: "list name" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("components." + item)) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "info-right" },
                      _vm._l(_vm.listValue, function (item) {
                        return _c(
                          "p",
                          { key: item, staticClass: "list value" },
                          [
                            item === "cost" && !ele.cost
                              ? [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "mini", type: "success" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("components.free")))]
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(ele[item]) +
                                      "\n            "
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card-method",
                      class: "btn-" + _vm.numPlugin,
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("components.unable_to_update"),
                            placement: "top",
                          },
                        },
                        [
                          _vm.checkPermission(["plugin:upload"]) &&
                          _vm.updateDisabled(ele)
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-plugin update",
                                  class: [
                                    { "is-disable": _vm.updateDisabled(ele) },
                                  ],
                                },
                                [
                                  _c("i", { staticClass: "el-icon-more" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("dataset.update")) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.checkPermission(["plugin:upload"]) &&
                      !_vm.updateDisabled(ele)
                        ? _c(
                            "el-upload",
                            {
                              attrs: {
                                action:
                                  _vm.baseUrl +
                                  "api/plugin/update/" +
                                  ele.pluginId,
                                multiple: false,
                                "show-file-list": false,
                                "file-list": _vm.fileList,
                                accept: ".zip",
                                "before-upload": _vm.beforeUpload,
                                "on-success": _vm.uploadSuccess,
                                "on-error": _vm.uploadFail,
                                name: "file",
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c("div", { staticClass: "btn-plugin update" }, [
                                _c("i", { staticClass: "el-icon-more" }),
                                _vm._v(
                                  _vm._s(_vm.$t("dataset.update")) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.numPlugin === 2
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("components.unable_to_uninstall"),
                            placement: "top",
                          },
                        },
                        [
                          _vm.checkPermission(["plugin:uninstall"]) &&
                          _vm.btnDisabled(ele)
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-plugin uninstall",
                                  class: [
                                    { "is-disable": _vm.btnDisabled(ele) },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.del(ele)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-more" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("components.uninstall")) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.checkPermission(["plugin:uninstall"]) &&
                      !_vm.btnDisabled(ele)
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-plugin uninstall",
                              class: [{ "is-disable": _vm.btnDisabled(ele) }],
                              on: {
                                click: function ($event) {
                                  return _vm.del(ele)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-more" }),
                              _vm._v(
                                _vm._s(_vm.$t("components.uninstall")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }