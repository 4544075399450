var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-layout-content",
    { attrs: { header: _vm.$t("消息接收管理") } },
    [
      _c(
        "el-col",
        [
          _c(
            "el-row",
            { staticClass: "tree-head" },
            [
              _c("span", { staticStyle: { float: "left" } }, [
                _vm._v(_vm._s(_vm.$t("webmsg.type"))),
              ]),
              _vm._l(_vm.msg_channels, function (channel) {
                return _c(
                  "span",
                  { key: channel.msgChannelId, staticClass: "auth-span" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(channel.channelName)) +
                        "\n      "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "el-row",
            {
              staticClass: "msg-setting",
              staticStyle: { "margin-top": "5px" },
            },
            [
              _c("el-tree", {
                attrs: {
                  props: _vm.defaultProps,
                  data: _vm.treeData,
                  "default-expand-all": "",
                  "node-key": _vm.defaultProps.id,
                  "highlight-current": _vm.highlightCurrent,
                },
                on: { "node-click": _vm.nodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c("span", { staticClass: "custom-tree-node" }, [
                        _c("span", [
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "6px" } },
                            [_vm._v(_vm._s(_vm.$t("webmsg." + data.name)))]
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              _vm._l(_vm.msg_channels, function (channel) {
                                return _c(
                                  "span",
                                  {
                                    key: channel.msgChannelId,
                                    staticClass: "auth-span-check",
                                  },
                                  [
                                    data.children && data.children.length > 0
                                      ? _c("el-checkbox", {
                                          attrs: {
                                            indeterminate:
                                              data.indeterminate_map[
                                                channel.msgChannelId
                                              ],
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.parentBoxChange(
                                                node,
                                                channel
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              data.check_all_map[
                                                channel.msgChannelId
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                data.check_all_map,
                                                channel.msgChannelId,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.check_all_map[channel.msgChannelId]",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.childBoxChange(
                                                node,
                                                channel
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              data.check_map[
                                                channel.msgChannelId
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                data.check_map,
                                                channel.msgChannelId,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.check_map[channel.msgChannelId]",
                                          },
                                        }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }