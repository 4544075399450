var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-row", { staticClass: "demo_main" }, [
    _c("div", { staticClass: "demo_title" }, [
      _c("a", { attrs: { target: "_blank", href: _vm.details.href } }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/DataEase-" + _vm.imgIndex + ".jpg"),
            height: "100%",
          },
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "demo_content" },
      [
        _c("el-row", { staticClass: "head" }, [
          _c("span", [_vm._v(_vm._s(_vm.details.head))]),
        ]),
        _c("el-row", { staticClass: "content" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.details.content) } }),
        ]),
        _c("el-row", { staticClass: "bottom" }, [
          _c("span", { staticClass: "span-box" }, [
            _vm._v(_vm._s(_vm.details.bottom)),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }