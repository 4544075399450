var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-layout-content",
    {
      attrs: { header: _vm.header, "back-path": "/system/system-task/dataset" },
    },
    [
      _c("div", { staticClass: "dataset-editer-form" }, [
        _c(
          "div",
          { staticClass: "w600" },
          [
            _c(
              "el-form",
              {
                ref: "taskForm",
                staticClass: "de-form-item",
                attrs: {
                  form: _vm.taskForm,
                  model: _vm.taskForm,
                  disabled: _vm.disableForm,
                  "label-width": "100px",
                  rules: _vm.taskFormRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("chart.select_dataset"),
                      prop: "datasetName",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.selectDataset($event)
                      },
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        disabled: !!_vm.taskForm.id,
                        readonly: "",
                        placeholder: _vm.$t("dataset.task_name"),
                      },
                      model: {
                        value: _vm.taskForm.datasetName,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskForm, "datasetName", $$v)
                        },
                        expression: "taskForm.datasetName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("dataset.task_name"), prop: "name" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        placeholder: _vm.$t("dataset.task_name"),
                      },
                      model: {
                        value: _vm.taskForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskForm, "name", $$v)
                        },
                        expression: "taskForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("dataset.update_type"),
                      prop: "type",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.taskForm.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskForm, "type", $$v)
                          },
                          expression: "taskForm.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "all_scope" } }, [
                          _vm._v(_vm._s(_vm.$t("dataset.all_scope"))),
                        ]),
                        _c("el-radio", { attrs: { label: "add_scope" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dataset.add_scope"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.taskForm.type === "add_scope"
                  ? _c(
                      "div",
                      { staticClass: "add-scope-cont" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "type",
                              label: _vm.$t("dataset.incremental_update_type"),
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "small" },
                                on: { change: _vm.incrementalUpdateTypeChange },
                                model: {
                                  value: _vm.incrementalUpdateType,
                                  callback: function ($$v) {
                                    _vm.incrementalUpdateType = $$v
                                  },
                                  expression: "incrementalUpdateType",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: "incrementalAdd" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataset.incremental_add"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  { attrs: { label: "incrementalDelete" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("dataset.incremental_delete")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "param-title" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("dataset.param"))),
                              ]),
                              _c(
                                "div",
                                { staticClass: "param-title-btn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.insertParamToCodeMirror(
                                            "${__last_update_time__}"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("dataset.last_update_time")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.insertParamToCodeMirror(
                                            "${__current_update_time__}"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("dataset.current_update_time")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "codemirror-cont" },
                              [
                                _c("codemirror", {
                                  ref: "myCm",
                                  staticClass: "codemirror",
                                  attrs: { options: _vm.sqlOption },
                                  on: {
                                    ready: _vm.onCmReady,
                                    focus: _vm.onCmFocus,
                                    input: _vm.onCmCodeChange,
                                  },
                                  model: {
                                    value: _vm.sql,
                                    callback: function ($$v) {
                                      _vm.sql = $$v
                                    },
                                    expression: "sql",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("dataset.execute_rate"),
                      prop: "rate",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.onRateChange },
                        model: {
                          value: _vm.taskForm.rate,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskForm, "rate", $$v)
                          },
                          expression: "taskForm.rate",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "SIMPLE" } }, [
                          _vm._v(_vm._s(_vm.$t("dataset.execute_once"))),
                        ]),
                        _c("el-radio", { attrs: { label: "CRON" } }, [
                          _vm._v(_vm._s(_vm.$t("dataset.cron_config"))),
                        ]),
                        _c("el-radio", { attrs: { label: "SIMPLE_CRON" } }, [
                          _vm._v(_vm._s(_vm.$t("dataset.simple_cron"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.taskForm.rate !== "SIMPLE"
                  ? _c(
                      "div",
                      { staticClass: "execute-rate-cont" },
                      [
                        _vm.taskForm.rate === "SIMPLE_CRON"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("dataset.execute_rate"),
                                  prop: "rate",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "simple-cron" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("cron.every")) +
                                        "\n              "
                                    ),
                                    _c("el-input-number", {
                                      attrs: {
                                        "controls-position": "right",
                                        min: 1,
                                        size: "small",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onSimpleCronChange()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.taskForm.extraData
                                            .simple_cron_value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.taskForm.extraData,
                                            "simple_cron_value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "taskForm.extraData.simple_cron_value",
                                      },
                                    }),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          size: "small",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSimpleCronChange()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.taskForm.extraData
                                              .simple_cron_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.taskForm.extraData,
                                              "simple_cron_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "taskForm.extraData.simple_cron_type",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("components.minute"),
                                            value: "minute",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("components.hour"),
                                            value: "hour",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("components.day"),
                                            value: "day",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("cron.every_exec")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.taskForm.rate === "CRON" && _vm.showCron
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cron",
                                  label: _vm.$t("emailtask.cron_exp"),
                                },
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    model: {
                                      value: _vm.cronEdit,
                                      callback: function ($$v) {
                                        _vm.cronEdit = $$v
                                      },
                                      expression: "cronEdit",
                                    },
                                  },
                                  [
                                    _c("cron", {
                                      attrs: {
                                        "is-rate": _vm.taskForm.rate === "CRON",
                                      },
                                      on: {
                                        close: function ($event) {
                                          _vm.cronEdit = false
                                        },
                                      },
                                      model: {
                                        value: _vm.taskForm.cron,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.taskForm, "cron", $$v)
                                        },
                                        expression: "taskForm.cron",
                                      },
                                    }),
                                    _c("el-input", {
                                      staticStyle: { width: "50%" },
                                      attrs: {
                                        slot: "reference",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.cronEdit = true
                                        },
                                      },
                                      slot: "reference",
                                      model: {
                                        value: _vm.taskForm.cron,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.taskForm, "cron", $$v)
                                        },
                                        expression: "taskForm.cron",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.taskForm.rate !== "SIMPLE"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("dataset.start_time"),
                                  prop: "startTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticClass: "de-date-picker",
                                  attrs: {
                                    type: "datetime",
                                    placeholder: _vm.$t("dataset.start_time"),
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.taskForm.startTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.taskForm, "startTime", $$v)
                                    },
                                    expression: "taskForm.startTime",
                                  },
                                }),
                                _c("svg-icon", {
                                  staticClass: "icon-calendar-outlined",
                                  attrs: {
                                    "icon-class": "icon_calendar_outlined",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.taskForm.rate !== "SIMPLE"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("dataset.end_time"),
                                  prop: "end",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.taskForm.end,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "end", $$v)
                                      },
                                      expression: "taskForm.end",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("dataset.no_limit"))
                                      ),
                                    ]),
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("dataset.set_end_time"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.taskForm.end === "1"
                                  ? _c("el-date-picker", {
                                      staticClass: "de-date-picker",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: _vm.$t("dataset.end_time"),
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.taskForm.endTime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.taskForm, "endTime", $$v)
                                        },
                                        expression: "taskForm.endTime",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.taskForm.end === "1"
                                  ? _c("svg-icon", {
                                      staticClass: "icon-calendar-outlined",
                                      attrs: {
                                        "icon-class": "icon_calendar_outlined",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("table-selector", {
              ref: "tableSelector",
              attrs: {
                "preview-for-task": "true",
                privileges: "manage",
                mode: 1,
                "clear-empty-dir": true,
                "custom-type": ["db", "sql", "api"],
                "show-mode": "datasetTask",
              },
              on: { getTableId: _vm.getTableId },
            }),
          ],
          1
        ),
        !_vm.disableForm
          ? _c("div", { staticClass: "de-foot-layout" }, [
              _c(
                "div",
                { staticClass: "cont" },
                [
                  _c(
                    "deBtn",
                    { attrs: { secondary: "" }, on: { click: _vm.closeTask } },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveTask(_vm.taskForm)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }