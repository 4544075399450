var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticStyle: { height: "100%" } },
    [
      _c("el-empty", {
        staticStyle: { "padding-top": "202px" },
        attrs: {
          "image-size": 125,
          image: _vm.errImg,
          description: _vm.$t("deDataset.on_the_left"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }