<template>
  <el-row class="demo_main">
    <el-row class="demo_content">
      <span>
        {{ $t('wizard.enterprise_edition_hint1') }}
      </span>
      <br>
      <span>
        {{ $t('wizard.enterprise_edition_hint2') }}
      </span>
      <br>
      <span>
        {{ $t('wizard.enterprise_edition_hint3') }}
      </span>
    </el-row>
    <el-row class="demo_bottom">
      <a
        href="https://jinshuju.net/f/TK5TTd"
        target="_blank"
      > {{ $t('wizard.click_inner') }}</a>
    </el-row>
  </el-row>
</template>

<script>

export default {
  name: 'TeachingVideo',
  props: {
  },
  data() {
    return {}
  }
}

</script>

<style lang="scss" scoped>
  .demo_main{
    height: 100%;
    padding: 10px 20px 10px 20px;
  }
  .demo_content{
    padding-left: 10px;
    float: left;
    font-weight: 400;
    max-height: 80%;
    overflow: hidden;
  }

  .demo_bottom{
    position: absolute;
    left: 30px;
    bottom: 10px;
    font-weight: 500;
    color: var(--MenuActiveBG, #409EFF);
  }

  .title{
    line-height: 40px;
  }

</style>
