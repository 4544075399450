var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-table de-search-table" },
    [
      _c(
        "el-row",
        { staticClass: "top-operate" },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("span", { staticClass: "table-name-top" }, [
              _vm._v(_vm._s(_vm.params.name)),
            ]),
          ]),
          _c(
            "el-col",
            { staticClass: "right-user", attrs: { span: 14 } },
            [
              _c("el-input", {
                ref: "search",
                staticClass: "name-email-search",
                attrs: {
                  placeholder: _vm.$t(
                    "system_parameter_setting.search_keywords"
                  ),
                  "prefix-icon": "el-icon-search",
                  size: "small",
                  clearable: "",
                },
                on: { blur: _vm.initSearch, clear: _vm.initSearch },
                model: {
                  value: _vm.nickName,
                  callback: function ($$v) {
                    _vm.nickName = $$v
                  },
                  expression: "nickName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "grid-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                "table-data": _vm.pagingTable,
                pagination: _vm.paginationConfig,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("el-table-column", {
                key: "name",
                attrs: { prop: "name", label: _vm.$t("datasource.table_name") },
              }),
              _c("el-table-column", {
                key: "__operation",
                attrs: {
                  slot: "__operation",
                  label: _vm.$t("commons.operating"),
                  fixed: "right",
                  width: "108",
                },
                slot: "__operation",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "de-text-btn mar3",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.selectDataset(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("dataset.detail")) + "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "de-dialog-form ds-table-drawer",
          attrs: {
            title: _vm.$t("dataset.detail"),
            visible: _vm.userDrawer,
            width: "840px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDrawer = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "margin-top": "12px" }, attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("p", { staticClass: "table-name" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("datasource.table_name")) +
                      "\n        "
                  ),
                ]),
                _c("p", { staticClass: "table-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.dsTableDetail.name) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("p", { staticClass: "table-name" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("datasource.table_description")) +
                      "\n        "
                  ),
                ]),
                _c("p", { staticClass: "table-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.dsTableDetail.remark || "-") +
                      "\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dsTableData, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "fieldName",
                  label: _vm.$t("panel.column_name"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fieldType",
                  label: _vm.$t("dataset.field_type"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.fieldType === "0" && _vm.params.type === "api"
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("dataset.text")))])
                          : _vm._e(),
                        scope.row.fieldType === "2" && _vm.params.type === "api"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("dataset.value"))),
                            ])
                          : _vm._e(),
                        scope.row.fieldType === "3" && _vm.params.type === "api"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("dataset.value") +
                                    "(" +
                                    _vm.$t("dataset.float") +
                                    ")"
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm.params.type !== "api"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.fieldType) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remarks",
                  "show-overflow-tooltip": "",
                  label: _vm.$t("datasource.field_description"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }