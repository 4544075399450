var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "tree-style" },
    [
      _c(
        "el-col",
        [
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c("el-input", {
                staticClass: "main-area-input",
                attrs: {
                  size: "small",
                  placeholder: _vm.$t("commons.search"),
                  "prefix-icon": "el-icon-search",
                  clearable: "",
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
            ],
            1
          ),
          _c("el-col", { staticClass: "custom-tree-container" }, [
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-tree", {
                  ref: "tree",
                  staticClass: "filter-tree",
                  attrs: {
                    data: _vm.treeData,
                    props: _vm.defaultProps,
                    "filter-node-method": _vm.filterNode,
                    "expand-on-click-node": false,
                    "node-key": "code",
                    accordion: true,
                    "highlight-current": "",
                    "default-expanded-keys": _vm.expandedKeys,
                  },
                  on: { "current-change": _vm.nodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c(
                          "span",
                          { staticClass: "custom-tree-node father" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "flex",
                                  flex: "1",
                                  width: "0",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-left": "6px",
                                      "white-space": "nowrap",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                    },
                                    attrs: { title: data.name },
                                  },
                                  [_vm._v(_vm._s(node.data.name))]
                                ),
                              ]
                            ),
                            !_vm.isChina(data.code)
                              ? _c("span", { staticClass: "child" }, [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-plus",
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addHandler(
                                                  data,
                                                  node
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  !_vm.isGlobal(data.code)
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "12px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  icon: "el-icon-delete",
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeHandler(
                                                      data,
                                                      node
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }