var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: _vm.spanCount } }, [
            _c(
              "div",
              {
                staticStyle: {
                  border: "1px #DCDFE6 solid",
                  height: "100%",
                  "border-radius": "4px",
                  width: "100%",
                },
              },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "request-tabs",
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: _vm.$t("datasource.headers"),
                          name: "headers",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item-tabs",
                            attrs: {
                              slot: "label",
                              effect: "dark",
                              content: _vm.$t("datasource.headers"),
                              placement: "top-start",
                            },
                            slot: "label",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("datasource.headers")) +
                                  "\n                "
                              ),
                              _vm.headers.length > 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "el-step__icon is-text ms-api-col ms-header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "el-step__icon-inner" },
                                        [_vm._v(_vm._s(_vm.headers.length - 1))]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _c("api-key-value", {
                          attrs: {
                            "show-desc": true,
                            suggestions: _vm.headerSuggestions,
                            items: _vm.headers,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isBodyShow
                      ? _c(
                          "el-tab-pane",
                          {
                            staticStyle: { overflow: "auto" },
                            attrs: {
                              label: _vm.$t("datasource.request_body"),
                              name: "body",
                            },
                          },
                          [
                            _c("api-body", {
                              attrs: {
                                "is-show-enable": _vm.isShowEnable,
                                headers: _vm.headers,
                                body: _vm.request.body,
                              },
                              on: { headersChange: _vm.reloadBody },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: _vm.$t("datasource.auth_config"),
                          name: "authConfig",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item-tabs",
                            attrs: {
                              slot: "label",
                              effect: "dark",
                              content: _vm.$t("datasource.auth_config_info"),
                              placement: "top-start",
                            },
                            slot: "label",
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("datasource.auth_config"))),
                            ]),
                          ]
                        ),
                        _c("api-auth-config", {
                          attrs: { request: _vm.request },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }