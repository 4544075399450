var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "createUserForm",
      attrs: {
        model: _vm.form,
        rules: _vm.rule,
        size: "small",
        "label-width": "auto",
        "label-position": "right",
      },
    },
    [
      !_vm.oldPwd
        ? _c(
            "el-form-item",
            { attrs: { label: _vm.$t("user.origin_passwd"), prop: "oldPwd" } },
            [
              _c("dePwd", {
                model: {
                  value: _vm.form.oldPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldPwd", $$v)
                  },
                  expression: "form.oldPwd",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("user.new_passwd"), prop: "newPwd" } },
        [
          _c("dePwd", {
            model: {
              value: _vm.form.newPwd,
              callback: function ($$v) {
                _vm.$set(_vm.form, "newPwd", $$v)
              },
              expression: "form.newPwd",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("user.confirm_passwd"), prop: "repeatPwd" } },
        [
          _c("dePwd", {
            model: {
              value: _vm.form.repeatPwd,
              callback: function ($$v) {
                _vm.$set(_vm.form, "repeatPwd", $$v)
              },
              expression: "form.repeatPwd",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("commons.confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }