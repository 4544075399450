var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-top": "5px" } },
        [
          _c(
            "el-row",
            { staticStyle: { "margin-left": "5px", "margin-right": "5px" } },
            [
              _c(
                "el-col",
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("panel.filter_keywords"),
                      size: "mini",
                      clearable: "",
                      "prefix-icon": "el-icon-search",
                    },
                    model: {
                      value: _vm.templateFilterText,
                      callback: function ($$v) {
                        _vm.templateFilterText = $$v
                      },
                      expression: "templateFilterText",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "5px" } },
            [
              _c("el-tree", {
                ref: "templateTree",
                attrs: {
                  "default-expanded-keys": _vm.defaultExpandedKeys,
                  "show-checkbox": _vm.selectModel,
                  data: _vm.treeData,
                  "node-key": "id",
                  props: _vm.defaultProps,
                  draggable: "",
                  "allow-drop": _vm.allowDrop,
                  "allow-drag": _vm.allowDrag,
                  "expand-on-click-node": true,
                  "filter-node-method": _vm.filterNode,
                  "highlight-current": true,
                },
                on: {
                  "node-drag-start": _vm.handleDragStart,
                  check: _vm.checkChanged,
                  "node-drag-end": _vm.dragEnd,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "span",
                        { staticClass: "custom-tree-node-list father" },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1 1 0%",
                                width: "0px",
                              },
                            },
                            [
                              data.modelInnerType === "history"
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "el-icon-collection",
                                    }),
                                  ])
                                : data.nodeType === "spine"
                                ? _c("span", [
                                    _c("i", { staticClass: "el-icon-folder" }),
                                  ])
                                : data.modelType === "panel" &&
                                  data.nodeType === "leaf"
                                ? _c(
                                    "span",
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ds-icon-scene",
                                        attrs: { "icon-class": "panel" },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c("svg-icon", {
                                        staticStyle: {
                                          width: "14px",
                                          height: "14px",
                                        },
                                        attrs: {
                                          "icon-class":
                                            data.isPlugin &&
                                            data.modelInnerType &&
                                            data.modelInnerType !== "buddle-map"
                                              ? "/api/pluginCommon/staticInfo/" +
                                                data.modelInnerType +
                                                "/svg"
                                              : data.modelInnerType,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "6px",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                  attrs: { title: data.name },
                                },
                                [_vm._v(_vm._s(data.name))]
                              ),
                            ]
                          ),
                          data.mode === 1
                            ? _c("span", { staticClass: "child" }, [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        icon: "el-icon-delete",
                                        type: "text",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteHistory(data, node)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }