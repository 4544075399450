var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "operator",
        { attrs: { title: "system_parameter_setting.engine_mode_setting" } },
        [
          _vm.showCancel
            ? _c(
                "deBtn",
                { attrs: { secondary: "" }, on: { click: _vm.cancel } },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("commons.cancel")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "deBtn",
            { attrs: { secondary: "" }, on: { click: _vm.validaDatasource } },
            [_vm._v("\n      " + _vm._s(_vm.$t("commons.validate")) + "\n    ")]
          ),
          _vm.showEdit
            ? _c(
                "deBtn",
                { attrs: { type: "primary" }, on: { click: _vm.edit } },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.edit")) + "\n    ")]
              )
            : _vm._e(),
          _vm.showSave
            ? _c(
                "deBtn",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.save")) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticClass: "de-form-item",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.show,
            "label-width": "180px",
            "label-position": "top",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("datasource.doris_host"),
                prop: "configuration.host",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.configuration.host,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.configuration, "host", $$v)
                  },
                  expression: "form.configuration.host",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("datasource.data_base"),
                prop: "configuration.dataBase",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.configuration.dataBase,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.configuration, "dataBase", $$v)
                  },
                  expression: "form.configuration.dataBase",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("datasource.user_name") } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.configuration.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.configuration, "username", $$v)
                  },
                  expression: "form.configuration.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("datasource.password") } },
            [
              _c("dePwd", {
                model: {
                  value: _vm.form.configuration.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.configuration, "password", $$v)
                  },
                  expression: "form.configuration.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("datasource.query_port"),
                prop: "configuration.port",
              },
            },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  autocomplete: "off",
                  type: "number",
                  min: "0",
                },
                model: {
                  value: _vm.form.configuration.port,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.configuration, "port", $$v)
                  },
                  expression: "form.configuration.port",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("datasource.http_port"),
                prop: "configuration.httpPort",
              },
            },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  autocomplete: "off",
                  type: "number",
                  min: "0",
                },
                model: {
                  value: _vm.form.configuration.httpPort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.configuration, "httpPort", $$v)
                  },
                  expression: "form.configuration.httpPort",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "de-expand",
              on: {
                click: function ($event) {
                  _vm.showPriority = !_vm.showPriority
                },
              },
            },
            [
              _vm._v(_vm._s(_vm.$t("datasource.priority"))),
              _vm.showPriority
                ? _c("i", { staticClass: "el-icon-arrow-up" })
                : _c("i", { staticClass: "el-icon-arrow-down" }),
            ]
          ),
          _vm.showPriority
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.replication_num"),
                              prop: "configuration.replicationNum",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: "1",
                              },
                              model: {
                                value: _vm.form.configuration.replicationNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "replicationNum",
                                    $$v
                                  )
                                },
                                expression: "form.configuration.replicationNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.bucket_num"),
                              prop: "configuration.bucketNum",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: "1",
                              },
                              model: {
                                value: _vm.form.configuration.bucketNum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "bucketNum",
                                    $$v
                                  )
                                },
                                expression: "form.configuration.bucketNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.initial_pool_size"),
                              prop: "configuration.initialPoolSize",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: "0",
                                size: "small",
                              },
                              model: {
                                value: _vm.form.configuration.initialPoolSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "initialPoolSize",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.configuration.initialPoolSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.min_pool_size"),
                              prop: "configuration.minPoolSize",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: "0",
                              },
                              model: {
                                value: _vm.form.configuration.minPoolSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "minPoolSize",
                                    $$v
                                  )
                                },
                                expression: "form.configuration.minPoolSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.max_pool_size"),
                              prop: "configuration.maxPoolSize",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: "0",
                              },
                              model: {
                                value: _vm.form.configuration.maxPoolSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "maxPoolSize",
                                    $$v
                                  )
                                },
                                expression: "form.configuration.maxPoolSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }