var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "kv-row item" },
        [
          _c(
            "el-row",
            {
              attrs: {
                type: "flex",
                gutter: 20,
                justify: "space-between",
                align: "middle",
              },
            },
            [
              _c("span", { staticStyle: { "margin-left": "10px" } }),
              _c("i", {
                staticClass: "el-icon-top",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.moveTop(index)
                  },
                },
              }),
              _c("i", {
                staticClass: "el-icon-bottom",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.moveBottom(index)
                  },
                },
              }),
              _vm.unShowSelect === false
                ? _c(
                    "el-col",
                    { staticClass: "item" },
                    [
                      !_vm.suggestions
                        ? _c("el-input", {
                            attrs: {
                              disabled: _vm.isReadOnly,
                              size: "small",
                              maxlength: "200",
                              placeholder: _vm.keyText,
                              "show-word-limit": "",
                            },
                            on: { change: _vm.change },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          })
                        : _vm._e(),
                      _vm.suggestions
                        ? _c("el-autocomplete", {
                            attrs: {
                              disabled: _vm.isReadOnly,
                              maxlength: 400,
                              size: "small",
                              "fetch-suggestions": _vm.querySearch,
                              placeholder: _vm.keyText,
                              "show-word-limit": "",
                            },
                            on: { change: _vm.change },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.unShowSelect === true
                ? _c(
                    "el-col",
                    { staticClass: "item" },
                    [
                      _vm.suggestions
                        ? _c("el-input", {
                            attrs: {
                              disabled: _vm.isReadOnly,
                              size: "small",
                              maxlength: "200",
                              placeholder: _vm.keyText,
                              "show-word-limit": "",
                            },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { staticClass: "item" },
                [
                  !_vm.needMock
                    ? _c("el-input", {
                        attrs: {
                          disabled: _vm.isReadOnly,
                          size: "small",
                          placeholder: _vm.unShowSelect
                            ? _vm.$t("commons.description")
                            : _vm.valueText,
                          "show-word-limit": "",
                        },
                        on: { change: _vm.change },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.showDesc
                ? _c(
                    "el-col",
                    { staticClass: "item" },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          maxlength: "200",
                          placeholder: _vm.$t("commons.description"),
                          "show-word-limit": "",
                        },
                        model: {
                          value: item.description,
                          callback: function ($$v) {
                            _vm.$set(item, "description", $$v)
                          },
                          expression: "item.description",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { staticClass: "item kv-delete" },
                [
                  _c("el-button", {
                    staticClass: "el-icon-delete-solid",
                    attrs: {
                      size: "mini",
                      circle: "",
                      disabled: _vm.isDisable(index),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.remove(index)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }