var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "authConfig",
      attrs: {
        model: _vm.authConfig,
        rules: _vm.rule,
        "label-position": "right",
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("datasource.verification_method"),
            prop: "verification",
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("datasource.verification_method"),
                filterable: "",
                size: "small",
              },
              on: { change: _vm.change },
              model: {
                value: _vm.authConfig.verification,
                callback: function ($$v) {
                  _vm.$set(_vm.authConfig, "verification", $$v)
                },
                expression: "authConfig.verification",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm.authConfig.verification != undefined &&
              _vm.authConfig.verification != "No Auth"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("datasource.username"),
                        prop: "username",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "ms-http-input",
                        attrs: {
                          placeholder: _vm.$t("datasource.username"),
                          size: "small",
                        },
                        model: {
                          value: _vm.authConfig.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.authConfig, "username", $$v)
                          },
                          expression: "authConfig.username",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm.authConfig.verification != undefined &&
              _vm.authConfig.verification != "No Auth"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("datasource.password"),
                        prop: "password",
                      },
                    },
                    [
                      _c("dePwd", {
                        attrs: { placeholder: _vm.$t("datasource.password") },
                        model: {
                          value: _vm.authConfig.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.authConfig, "password", $$v)
                          },
                          expression: "authConfig.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }