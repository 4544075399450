var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "de-template-list" },
    [
      _c(
        "el-input",
        {
          staticClass: "de-input-search",
          attrs: {
            placeholder: _vm.$t("system_parameter_setting.search_keywords"),
            size: "small",
            clearable: "",
          },
          model: {
            value: _vm.templateFilterText,
            callback: function ($$v) {
              _vm.templateFilterText = $$v
            },
            expression: "templateFilterText",
          },
        },
        [
          _c("svg-icon", {
            attrs: { slot: "prefix", "icon-class": "de-search" },
            slot: "prefix",
          }),
        ],
        1
      ),
      !_vm.templateListComputed.length && _vm.templateFilterText === ""
        ? _c("el-empty", {
            attrs: {
              image: _vm.noneImg,
              description: _vm.$t("components.no_classification"),
            },
          })
        : _vm._e(),
      !_vm.templateListComputed.length && _vm.templateFilterText !== ""
        ? _c("el-empty", {
            attrs: {
              image: _vm.nothingImg,
              description: _vm.$t("components.relevant_content_found"),
            },
          })
        : _vm._e(),
      _c(
        "ul",
        _vm._l(_vm.templateListComputed, function (ele) {
          return _c(
            "li",
            {
              key: ele.name,
              class: [{ select: _vm.activeTemplate === ele.id }],
              on: {
                click: function ($event) {
                  return _vm.nodeClick(ele)
                },
              },
            },
            [
              _c("img", {
                staticStyle: { "margin-right": "8px", "border-radius": "4px" },
                attrs: {
                  src: _vm.iconImgRul(ele.icon),
                  width: "24",
                  height: "24",
                },
              }),
              _c("span", [_vm._v(_vm._s(ele.name))]),
              _vm.showPositionCheck("system-setting")
                ? _c(
                    "span",
                    {
                      staticClass: "more",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click", size: "small" },
                          on: {
                            command: function (type) {
                              return _vm.clickMore(type, ele)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("i", { staticClass: "el-icon-more" }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "de-template-dropdown",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    icon: "el-icon-upload2",
                                    command: "import",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("app_template.app_upload")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    icon: "el-icon-edit",
                                    command: "edit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("commons.edit")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    icon: "el-icon-delete",
                                    command: "delete",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("commons.delete")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm.templateFilterText === "" && _vm.showPositionCheck("system-setting")
        ? _c(
            "deBtn",
            {
              staticStyle: { width: "100%" },
              attrs: { icon: "el-icon-plus", secondary: "" },
              on: {
                click: function ($event) {
                  return _vm.add()
                },
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("panel.add_app_category")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }