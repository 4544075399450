var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c(
          "el-popover",
          { attrs: { placement: "right", width: "400", trigger: "click" } },
          [
            _c("background-color-selector", {
              attrs: { chart: _vm.chart },
              on: { onChangeBackgroundForm: _vm.onChangeBackgroundForm },
            }),
            _c(
              "el-button",
              {
                staticClass: "shape-item",
                attrs: { slot: "reference", size: "mini" },
                slot: "reference",
              },
              [
                _vm._v(_vm._s(_vm.$t("chart.background"))),
                _c("i", { staticClass: "el-icon-setting el-icon--right" }),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }