var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "operator-bar" }, [
    _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
    _c("div", { staticClass: "btn-grounp" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }