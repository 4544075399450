var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      staticClass: "de-pwd-input",
      attrs: {
        value: _vm.value,
        placeholder: _vm.placeholder,
        type: _vm.type,
        disabled: _vm.disabled,
      },
      on: { input: _vm.handleInput, change: _vm.handleChange },
    },
    [
      !_vm.showPwd || _vm.buttonDisabled
        ? _c("svg-icon", {
            attrs: { slot: "suffix", "icon-class": "de_pwd_invisible" },
            on: { click: _vm.handleClick },
            slot: "suffix",
          })
        : _c("svg-icon", {
            attrs: { slot: "suffix", "icon-class": "de_pwd_visible" },
            on: { click: _vm.handleClick },
            slot: "suffix",
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }