var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-footer" },
    [
      _c(
        "el-button",
        { attrs: { size: _vm.btnSize }, on: { click: _vm.cancel } },
        [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: _vm.btnSize },
          on: { click: _vm.confirm },
          nativeOn: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("commons.confirm")))]
      ),
      _vm.isShow
        ? _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.saveAsEdit },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                },
              },
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }