var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "span",
        {
          staticStyle: {
            "line-height": "35px",
            position: "absolute",
            top: "10px",
            right: "10px",
          },
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.toDir } },
            [_vm._v("\n      " + _vm._s(_vm.$t("commons.close")) + "\n    ")]
          ),
        ],
        1
      ),
      _c(
        "de-container",
        [
          _c("de-main-container", { staticClass: "ms-main-container" }, [
            _vm._v("\n      this is test\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }