var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticStyle: {
          position: "absolute",
          top: "55px",
          width: "100%",
          height: "calc(100% - 55px)",
        },
      })
    : _vm.homeLink
    ? _c("div", [
        _c("iframe", {
          staticStyle: {
            position: "absolute",
            top: "55px",
            width: "100%",
            height: "calc(100% - 55px)",
          },
          attrs: { id: "mobsf", src: _vm.homeLink, frameborder: "0" },
        }),
      ])
    : _c(
        "el-row",
        { staticClass: "main_container" },
        [
          _c("el-row", { staticClass: "head" }, [
            _c("span", { staticClass: "hint_head" }, [
              _vm._v(_vm._s(_vm.$t("wizard.welcome_title"))),
            ]),
            _c("br"),
            _c("span", { staticClass: "hint_content" }, [
              _vm._v(_vm._s(_vm.$t("wizard.welcome_hint"))),
            ]),
          ]),
          _c(
            "el-row",
            { staticClass: "card_container" },
            _vm._l(_vm.cardList, function (cardDetail, index) {
              return _c(
                "info-card",
                { key: index },
                [
                  _c(cardDetail.component, {
                    tag: "component",
                    attrs: { "img-index": index, details: cardDetail },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }