var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dataset-excel",
    },
    [
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showLeft,
              expression: "!showLeft",
            },
          ],
          staticClass: "arrow-right",
          on: {
            click: function ($event) {
              _vm.showLeft = true
            },
          },
        },
        [_c("i", { staticClass: "el-icon-d-arrow-right" })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLeft,
              expression: "showLeft",
            },
          ],
          staticClass: "table-list",
        },
        [
          _c("p", { staticClass: "select-ds" }, [
            _c(
              "span",
              [
                _vm._v(
                  _vm._s(_vm.$t("deDataset.select_data_table ")) + "\n        "
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "right" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("dataset.excel_info_1"))
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("dataset.excel_info_2"))
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("dataset.excel_info_3")) +
                          "\n          "
                      ),
                    ]),
                    _c("svg-icon", {
                      attrs: { "icon-class": "icon_info_outlined" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("i", {
              staticClass: "el-icon-d-arrow-left",
              on: {
                click: function ($event) {
                  _vm.showLeft = false
                },
              },
            }),
          ]),
          _c(
            "el-upload",
            {
              attrs: {
                action: _vm.baseUrl + "/dataset/table/excel/upload",
                multiple: false,
                "show-file-list": false,
                "file-list": _vm.fileList,
                data: _vm.param,
                accept: ".xls,.xlsx,.csv",
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.uploadSuccess,
                "on-error": _vm.uploadFail,
                name: "file",
                headers: _vm.headers,
              },
            },
            [
              _c(
                "deBtn",
                {
                  staticClass: "search",
                  attrs: {
                    icon: "el-icon-upload2",
                    loading: _vm.uploading,
                    secondary: "",
                    disabled: _vm.uploading,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("deDataset.upload_data")) + "\n      ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-checkbox-list" },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "de-tree",
                attrs: {
                  data: _vm.excelData,
                  "default-expanded-keys": _vm.defaultExpandedKeys,
                  "default-checked-keys": _vm.defaultCheckedKeys,
                  "node-key": "id",
                  props: _vm.props,
                  "show-checkbox": "",
                  "highlight-current": "",
                },
                on: {
                  "node-click": _vm.handleNodeClick,
                  "check-change": _vm.handleCheckChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var data = ref.data
                      return _c(
                        "span",
                        {
                          staticClass: "custom-tree-node",
                          attrs: { title: data.excelLabel },
                        },
                        [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(_vm._s(data.excelLabel)),
                          ]),
                          (data.nameExist && !_vm.param.tableId) ||
                          data.empty ||
                          data.overLength
                            ? _c(
                                "span",
                                { staticClass: "error-name-exist" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "ds-icon-scene",
                                    attrs: { "icon-class": "exclamationmark" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-detail" },
        [
          !_vm.excelData.length
            ? _c("el-empty", {
                staticStyle: { "padding-top": "172px" },
                attrs: {
                  "image-size": 125,
                  image: _vm.errImg,
                  description: _vm.$t("deDataset.excel_data_first"),
                },
              })
            : [
                _c(
                  "div",
                  { staticClass: "dataset" },
                  [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("dataset.name"))),
                    ]),
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.$t("commons.name"),
                        size: "small",
                      },
                      on: { change: _vm.changeDatasetName },
                      model: {
                        value: _vm.sheetObj.datasetName,
                        callback: function ($$v) {
                          _vm.$set(_vm.sheetObj, "datasetName", $$v)
                        },
                        expression: "sheetObj.datasetName",
                      },
                    }),
                    (_vm.sheetObj.nameExist && !_vm.param.tableId) ||
                    _vm.sheetObj.empty ||
                    _vm.sheetObj.overLength
                      ? _c(
                          "div",
                          {
                            staticClass: "el-form-item__error",
                            staticStyle: { left: "107px", top: "52px" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.sheetObj.nameExist
                                      ? "deDataset.already_exists"
                                      : _vm.sheetObj.overLength
                                      ? "dataset.char_can_not_more_50"
                                      : "dataset.pls_input_name"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "data" },
                  [
                    _c("div", { staticClass: "result-num" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("dataset.preview_show") +
                              " 1000 " +
                              _vm.$t("dataset.preview_item")
                          ) +
                          "\n        "
                      ),
                    ]),
                    _c(
                      "ux-grid",
                      {
                        ref: "plxTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          height: _vm.height,
                          "checkbox-config": { highlight: true },
                          "width-resize": true,
                        },
                      },
                      _vm._l(_vm.sheetObj.fields, function (field) {
                        return _c("ux-table-column", {
                          key: field.fieldName + _vm.sheetObj.id,
                          attrs: {
                            "column-key": field.fieldName + _vm.sheetObj.id,
                            "min-width": "200px",
                            field: field.fieldName,
                            title: field.remarks,
                            "field-type": field.fieldType,
                            resizable: true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-dropdown",
                                      {
                                        key: field.fieldName + field.fieldType,
                                        attrs: {
                                          placement: "bottom-start",
                                          trigger: "click",
                                        },
                                        on: {
                                          command: function (type) {
                                            return _vm.handleCommand(
                                              type,
                                              field
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "type-switch" },
                                          [
                                            field.fieldType === "TEXT"
                                              ? _c("svg-icon", {
                                                  staticClass:
                                                    "field-icon-text",
                                                  attrs: {
                                                    "icon-class": "field_text",
                                                  },
                                                })
                                              : _vm._e(),
                                            field.fieldType === "DATETIME"
                                              ? _c("svg-icon", {
                                                  staticClass:
                                                    "field-icon-time",
                                                  attrs: {
                                                    "icon-class": "field_time",
                                                  },
                                                })
                                              : _vm._e(),
                                            field.fieldType === "LONG" ||
                                            field.fieldType === "DOUBLE"
                                              ? _c("svg-icon", {
                                                  staticClass:
                                                    "field-icon-value",
                                                  attrs: {
                                                    "icon-class": "field_value",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right",
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            staticClass: "de-card-dropdown",
                                            staticStyle: { width: "178px" },
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          _vm._l(
                                            _vm.fieldOptions,
                                            function (item) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  key: item.value,
                                                  attrs: {
                                                    command: item.value,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      item.value === "TEXT"
                                                        ? _c("svg-icon", {
                                                            staticClass:
                                                              "field-icon-text",
                                                            attrs: {
                                                              "icon-class":
                                                                "field_text",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      item.value === "DATETIME"
                                                        ? _c("svg-icon", {
                                                            staticClass:
                                                              "field-icon-time",
                                                            attrs: {
                                                              "icon-class":
                                                                "field_time",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      item.value === "LONG" ||
                                                      item.value === "DOUBLE"
                                                        ? _c("svg-icon", {
                                                            staticClass:
                                                              "field-icon-value",
                                                            attrs: {
                                                              "icon-class":
                                                                "field_value",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#8492a6",
                                                        "font-size": "14px",
                                                        "margin-left": "10px",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.label))]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          "margin-left": "10px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(field.remarks) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }