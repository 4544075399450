var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        display: "flex",
        "justify-content": "center",
      },
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card about-card", class: _vm.dynamicCardClass },
        [
          _c(
            "div",
            {
              staticClass: "clearfix license-header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/DataEase-white.png"),
                  alt: "",
                  width: "300",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "license-content" }, [
            _vm.license.status === "Fail"
              ? _c("div", [_vm._v(_vm._s(_vm.$t("about.invalid_license")))])
              : _vm._e(),
            _vm.license.status !== "Fail"
              ? _c("div", [
                  _c("table", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("about.auth_to")))]),
                      _c("td", [_vm._v(_vm._s(_vm.license.corporation))]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("about.expiration_time"))),
                      ]),
                      _c("td", [
                        _vm.license.status === "expired"
                          ? _c("label", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(_vm.license.expired) +
                                  " " +
                                  _vm._s(_vm.$t("about.expirationed"))
                              ),
                            ])
                          : _vm._e(),
                        _vm.license.status === "valid"
                          ? _c("label", [_vm._v(_vm._s(_vm.license.expired))])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("about.auth_num")))]),
                      _c("td", [_vm._v(_vm._s(_vm.license.count))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("about.version")))]),
                      _c("td", [
                        _vm.license.edition
                          ? _c("span", [
                              _vm.license.edition === "Standard"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("about.standard"))),
                                  ])
                                : _vm._e(),
                              _vm.license.edition === "Enterprise"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("about.enterprise"))),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("about.version_num")))]),
                      _c("td", [_c("span", [_vm._v(_vm._s(_vm.build))])]),
                    ]),
                    _vm.license.serialNo
                      ? _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("about.serial_no")))]),
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(_vm.license.serialNo))]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.license.remark
                      ? _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("about.remark")))]),
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(_vm.license.remark))]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "md-padding" }),
            _vm.user.isAdmin
              ? _c(
                  "div",
                  {
                    staticClass: "lic_rooter",
                    attrs: {
                      layout: "row",
                      "layout-align": "space-between center",
                    },
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: "",
                          multiple: false,
                          "show-file-list": false,
                          "file-list": _vm.fileList,
                          accept: ".key",
                          name: "file",
                          "before-upload": _vm.beforeUpload,
                        },
                      },
                      [
                        _c("a", { staticClass: "md-primary pointer" }, [
                          _vm._v(_vm._s(_vm.$t("about.update_license"))),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "md-primary pointer",
                        on: { click: _vm.support },
                      },
                      [_vm._v(_vm._s(_vm.$t("about.support")))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }