var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "demo_main" },
    [
      _c(
        "el-row",
        { staticClass: "demo_content", staticStyle: { "margin-top": "5%" } },
        [
          _c("span", { staticClass: "icon iconfont icon-fasongyoujian" }),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("dataease@fit2cloud.com"),
          ]),
        ]
      ),
      _c("el-row", { staticClass: "demo_content" }, [
        _c("span", { staticClass: "icon iconfont icon-dianhua" }),
        _c("span", { staticStyle: { "margin-left": "10px" } }, [
          _vm._v("400-052-0755"),
        ]),
      ]),
      _c("el-row", { staticClass: "demo_content" }, [
        _c("span", { staticClass: "icon iconfont icon-github" }),
        _c("span", { staticClass: "demo_git" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/dataease/dataease/issues",
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(_vm.$t("wizard.open_source_community")))]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }