var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c(
          "el-popover",
          { attrs: { placement: "right", width: "400", trigger: "click" } },
          [
            _vm.aidedDesign
              ? _c(
                  "el-col",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "aidedForm",
                        attrs: { "label-width": "110px", size: "mini" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item form-item-slider",
                            attrs: { label: "辅助网格" },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { size: "mini" },
                              on: { change: _vm.onChangePanelStyle },
                              model: {
                                value: _vm.aidedDesign.showGrid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.aidedDesign, "showGrid", $$v)
                                },
                                expression: "aidedDesign.showGrid",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "shape-item",
                attrs: { slot: "reference", size: "mini" },
                slot: "reference",
              },
              [
                _vm._v("辅助设计 "),
                _c("i", { staticClass: "el-icon-setting el-icon--right" }),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }