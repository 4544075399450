var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c("el-row", { staticStyle: { height: "25px" } }, [
        _c("span", { staticClass: "table-name title-text" }, [
          _vm._v(_vm._s(_vm.table.name)),
        ]),
      ]),
      _c("el-divider"),
      _c(
        "el-checkbox",
        {
          staticStyle: { "font-weight": "400" },
          attrs: {
            disabled: !(_vm.fields.length > 0),
            indeterminate: _vm.isIndeterminate,
          },
          on: { change: _vm.handleCheckAllChange },
          model: {
            value: _vm.checkAll,
            callback: function ($$v) {
              _vm.checkAll = $$v
            },
            expression: "checkAll",
          },
        },
        [_vm._v(_vm._s(_vm.$t("dataset.check_all")))]
      ),
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.handleCheckedFieldsChange },
          model: {
            value: _vm.checkedFields,
            callback: function ($$v) {
              _vm.checkedFields = $$v
            },
            expression: "checkedFields",
          },
        },
        _vm._l(_vm.fields, function (f) {
          return _c(
            "el-checkbox",
            {
              key: f.id,
              staticStyle: {
                display: "block",
                "margin-top": "4px",
                width: "100%",
                "font-weight": "400",
              },
              attrs: { label: f.id },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    flex: "1",
                  },
                },
                [
                  _c("span", [
                    f.deType === 0
                      ? _c(
                          "span",
                          [
                            f.deType === 0
                              ? _c("svg-icon", {
                                  staticClass: "field-icon-text",
                                  attrs: { "icon-class": "field_text" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    f.deType === 1
                      ? _c(
                          "span",
                          [
                            f.deType === 1
                              ? _c("svg-icon", {
                                  staticClass: "field-icon-time",
                                  attrs: { "icon-class": "field_time" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    f.deType === 2 || f.deType === 3
                      ? _c(
                          "span",
                          [
                            f.deType === 2 || f.deType === 3
                              ? _c("svg-icon", {
                                  staticClass: "field-icon-value",
                                  attrs: { "icon-class": "field_value" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    f.deType === 5
                      ? _c(
                          "span",
                          [
                            f.deType === 5
                              ? _c("svg-icon", {
                                  staticClass: "field-icon-location",
                                  attrs: { "icon-class": "field_location" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "flex",
                        flex: "1",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "text-overflow": "ellipsis",
                            "white-space": "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          },
                        },
                        [_vm._v(_vm._s(f.name))]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }