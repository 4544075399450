var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "de-date-pick" },
    [
      _c("el-date-picker", {
        attrs: {
          value: _vm.value,
          size: "small",
          disabled: _vm.disabled,
          type: "daterange",
          "range-separator": "-",
          "start-placeholder": _vm.$t("commons.date.start_date"),
          "end-placeholder": _vm.$t("commons.date.end_date"),
        },
        on: { input: _vm.handleChange },
      }),
      _c("svg-icon", {
        staticClass: "calendar-outlined",
        attrs: { "icon-class": "icon_calendar_outlined" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }