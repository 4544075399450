var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dataset-union",
      on: { mouseup: _vm.mouseupDrag },
    },
    [
      _c(
        "div",
        {
          staticClass: "union-editer-container",
          style: { height: _vm.unionHeight + "px" },
        },
        [
          _vm.dataset.length === 0
            ? _c(
                "div",
                { staticStyle: { padding: "24px" } },
                [
                  _c(
                    "deBtn",
                    { attrs: { type: "primary" }, on: { click: _vm.selectDs } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("chart.select_dataset")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "union-container" },
                [
                  _c("node-item", {
                    attrs: {
                      "current-node": _vm.dataset[0],
                      "node-index": 0,
                      "origin-data": _vm.dataset,
                    },
                    on: {
                      deleteNode: _vm.deleteNode,
                      notifyParent: _vm.calc,
                      editUnion: _vm.unionConfig,
                      cancelUnionEdit: _vm.cancelUnion,
                    },
                  }),
                  _vm.dataset.length > 0
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.dataset[0].childrenDs,
                          function (item, index) {
                            return _c("union-node", {
                              key: index,
                              attrs: {
                                "node-index": index,
                                "children-node": item,
                                "children-list": _vm.dataset[0].childrenDs,
                                "parent-node": _vm.dataset[0],
                                "origin-data": _vm.dataset,
                              },
                              on: {
                                notifyParent: _vm.calc,
                                cancelUnionEdit: _vm.cancelUnion,
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
        ]
      ),
      _c(
        "div",
        { staticClass: "preview-container" },
        [
          _c(
            "div",
            { staticClass: "sql-title" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("deDataset.data_preview")) +
                  "\n      "
              ),
              _c("span", { staticClass: "result-num" }, [
                _vm._v(
                  _vm._s(
                    "(" +
                      _vm.$t("dataset.preview_show") +
                      " 1000 " +
                      _vm.$t("dataset.preview_item") +
                      ")"
                  )
                ),
              ]),
              _c("span", {
                staticClass: "drag",
                on: { mousedown: _vm.mousedownDrag },
              }),
              _c(
                "el-button",
                {
                  staticClass: "de-text-btn posi-right",
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.previewData },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("deDataset.data_preview")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("union-preview", {
            attrs: {
              "union-height": _vm.unionHeight,
              table: _vm.previewTable,
              dataset: _vm.dataset,
            },
          }),
        ],
        1
      ),
      _vm.selectDsDialog
        ? _c(
            "el-drawer",
            {
              directives: [{ name: "closePress", rawName: "v-closePress" }],
              attrs: {
                title: _vm.$t("chart.select_dataset"),
                visible: _vm.selectDsDialog,
                "custom-class": "de-user-drawer sql-dataset-drawer",
                size: "600px",
                direction: "rtl",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.selectDsDialog = $event
                },
              },
            },
            [
              _c("dataset-tree", {
                attrs: {
                  "fix-height": true,
                  "show-mode": "union",
                  "custom-type": _vm.customType,
                  "clear-empty-dir": true,
                },
                on: { getTable: _vm.firstDs },
              }),
              _c(
                "div",
                { staticClass: "de-foot" },
                [
                  _c(
                    "deBtn",
                    {
                      attrs: { secondary: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeSelectDs()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n      ")]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { disabled: !_vm.tempDs.id, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSelectDs()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.editUnion
        ? _c(
            "el-drawer",
            {
              directives: [{ name: "closePress", rawName: "v-closePress" }],
              attrs: {
                title:
                  _vm.unionParam.type === "add"
                    ? _vm.$t("dataset.add_union_relation")
                    : _vm.$t("dataset.edit_union_relation"),
                visible: _vm.editUnion,
                "custom-class": "de-user-drawer union-dataset-drawer",
                size: "840px",
                direction: "rtl",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editUnion = $event
                },
              },
            },
            [
              _c("union-edit", { attrs: { "union-param": _vm.unionParam } }),
              _c(
                "div",
                { staticClass: "de-foot" },
                [
                  _c(
                    "deBtn",
                    {
                      attrs: { secondary: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeEditUnion()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n      ")]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmEditUnion()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }