var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-layout-content",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "sys-setting" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "de-tabs",
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  lazy: "",
                  label: _vm.$t("system_parameter_setting.basic_setting"),
                  name: "zero",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  lazy: "",
                  label: _vm.$t(
                    "system_parameter_setting.mailbox_service_settings"
                  ),
                  name: "first",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  lazy: "",
                  label: _vm.$t("sysParams.map"),
                  name: "ten",
                },
              }),
              _vm.engineMode === "simple"
                ? _c("el-tab-pane", {
                    attrs: {
                      lazy: "",
                      label: _vm.$t(
                        "system_parameter_setting.engine_mode_setting"
                      ),
                      name: "six",
                    },
                  })
                : _vm._e(),
              _vm.engineMode === "cluster"
                ? _c("el-tab-pane", {
                    attrs: {
                      lazy: "",
                      label: _vm.$t(
                        "system_parameter_setting.engine_mode_setting"
                      ),
                      name: "seven",
                    },
                  })
                : _vm._e(),
              _vm.engineMode === "cluster"
                ? _c("el-tab-pane", {
                    attrs: {
                      lazy: "",
                      label: _vm.$t("system_parameter_setting.kettle_setting"),
                      name: "eight",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "tabs-container",
              class: [_vm.activeName !== "eight" ? "is-center" : "pad-center"],
            },
            [
              _c(
                "div",
                { staticClass: "min-w600" },
                [
                  _vm.activeName === "zero"
                    ? _c("basic-setting", {
                        attrs: { "is-plugin-loaded": _vm.isPluginLoaded },
                      })
                    : _vm._e(),
                  _vm.activeName === "first" ? _c("email-setting") : _vm._e(),
                  _vm.activeName === "ten"
                    ? _c("map-setting", { ref: "mapSetting" })
                    : _vm._e(),
                  _vm.activeName === "six" ? _c("simple-mode") : _vm._e(),
                  _vm.activeName === "seven" ? _c("cluster-mode") : _vm._e(),
                  _vm.activeName === "eight" ? _c("kettle-setting") : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }