var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "demo_main" },
    [
      _c("el-row", { staticClass: "demo_content" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("wizard.online_document_hint")))]),
      ]),
      _c("el-row", { staticClass: "demo_bottom" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://dataease.io/docs/dev_manual/dev_manual/",
              target: "_blank",
            },
          },
          [_vm._v(_vm._s(_vm.$t("wizard.click_show")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }