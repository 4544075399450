var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-table" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "de-dataset-name", attrs: { span: 16 } },
            [
              _c(
                "span",
                {
                  staticClass: "title-text",
                  staticStyle: { "line-height": "26px" },
                  attrs: { title: _vm.table.name },
                },
                [_vm._v("\n        " + _vm._s(_vm.table.name) + "\n      ")]
              ),
              ["db", "sql"].includes(_vm.param.modelInnerType)
                ? [
                    _vm.table.mode === 0
                      ? _c("span", { staticClass: "de-tag primary" }, [
                          _vm._v(_vm._s(_vm.$t("dataset.direct_connect"))),
                        ])
                      : _vm._e(),
                    _vm.table.mode === 1
                      ? _c("span", { staticClass: "de-tag warning" }, [
                          _vm._v(_vm._s(_vm.$t("dataset.sync_data"))),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.syncStatus === "Underway"
                ? _c(
                    "span",
                    {
                      staticClass: "blue-color",
                      staticStyle: { "line-height": "26px" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.dataset_sync")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _c("span", { staticClass: "create-by" }, [
                _vm._v(_vm._s(_vm.$t("dataset.create_by"))),
              ]),
              _c("span", { staticClass: "create-by" }, [
                _vm._v(":" + _vm._s(_vm.table.creatorName || "N/A")),
              ]),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    width: "420",
                    trigger: "hover",
                  },
                  on: { show: _vm.showTab, hide: _vm.hideTab },
                },
                [
                  _c("dataset-detail", {
                    attrs: {
                      type: "dataset",
                      data: _vm.table,
                      "tab-status": _vm.tabStatus,
                    },
                  }),
                  _c("svg-icon", {
                    staticClass: "detail",
                    attrs: {
                      slot: "reference",
                      "icon-class": "icon_info_outlined",
                    },
                    slot: "reference",
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 8 } },
            [
              _vm.hasDataPermission("manage", _vm.param.privileges)
                ? _c(
                    "deBtn",
                    {
                      attrs: {
                        disabled: !_vm.previewDataSuccess,
                        type: "primary",
                        icon: "el-icon-download",
                      },
                      on: { click: _vm.exportDataset },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dataset.export_dataset")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.table.type === "excel" &&
              _vm.hasDataPermission("manage", _vm.param.privileges)
                ? _c(
                    "el-dropdown",
                    {
                      staticStyle: { "margin-left": "12px" },
                      attrs: {
                        size: "small",
                        trigger: "click",
                        placement: "bottom-end",
                      },
                      on: {
                        command: function (type) {
                          return _vm.clickEditExcel(type)
                        },
                      },
                    },
                    [
                      _c("deBtn", { attrs: { type: "primary" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("dataset.edit_excel")) +
                            "\n        "
                        ),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "0" } },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "icon_add-entry_outlined",
                                },
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("dataset.excel_replace") +
                                      _vm.$t("chart.chart_data")
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "1" } },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "icon_doc-replace_outlined",
                                },
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("dataset.excel_add") +
                                      _vm.$t("chart.chart_data")
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              ["sql", "union"].includes(_vm.table.type) &&
              _vm.hasDataPermission("manage", _vm.param.privileges)
                ? _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.editDataset(_vm.table.type)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              _vm.table.type === "union"
                                ? "dataset.edit_union"
                                : "dataset.edit_sql"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "de-tabs",
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("dataset.data_preview"),
                name: "dataPreview",
              },
            },
            [
              _c("tab-data-preview", {
                attrs: {
                  param: _vm.param,
                  table: _vm.table,
                  fields: _vm.fields,
                  data: _vm.data,
                  page: _vm.page,
                  form: _vm.tableViewRowForm,
                },
                on: { reSearch: _vm.reSearch },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("dataset.field_manage"),
                lazy: true,
                name: "fieldEdit",
              },
            },
            [
              _vm.tabActive === "fieldEdit"
                ? _c("field-edit", {
                    attrs: { param: _vm.param, table: _vm.table },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.table.mode === 1 && ["api", "sql", "db"].includes(_vm.table.type)
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("dataset.update_info"),
                    name: "updateInfo",
                  },
                },
                [
                  _vm.tabActive == "updateInfo"
                    ? _c("update-info", {
                        attrs: { param: _vm.param, table: _vm.table },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.table.mode === 1 && ["excel"].includes(_vm.table.type)
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("dataset.update_records"),
                    name: "updateInfo",
                  },
                },
                [
                  _vm.tabActive == "updateInfo"
                    ? _c("update-records", {
                        attrs: { param: _vm.param, table: _vm.table },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isPluginLoaded &&
          _vm.hasDataPermission("manage", _vm.param.privileges)
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    lazy: true,
                    label: _vm.$t("dataset.row_permissions"),
                    name: "rowPermissions",
                  },
                },
                [
                  _vm.isPluginLoaded && _vm.tabActive == "rowPermissions"
                    ? _c("plugin-com", {
                        ref: "RowPermissions",
                        attrs: {
                          "component-name": "RowPermissions",
                          obj: _vm.table,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isPluginLoaded &&
          _vm.hasDataPermission("manage", _vm.param.privileges)
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    lazy: true,
                    label: _vm.$t("dataset.column_permissions"),
                    name: "columnPermissions",
                  },
                },
                [
                  _vm.isPluginLoaded && _vm.tabActive == "columnPermissions"
                    ? _c("plugin-com", {
                        ref: "ColumnPermissions",
                        attrs: {
                          "component-name": "ColumnPermissions",
                          obj: _vm.table,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showExport
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "de-dialog-form form-tree-cont",
              attrs: {
                visible: _vm.showExport,
                width: "800px",
                title: _vm.$t("dataset.export_dataset"),
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showExport = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "exportForm",
                  staticClass: "de-form-item",
                  attrs: {
                    model: _vm.exportForm,
                    rules: _vm.exportFormRules,
                    "before-close": _vm.closeExport,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.exportDatasetRequest($event)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("dataset.filename"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("dataset.pls_input_filename"),
                        },
                        model: {
                          value: _vm.exportForm.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.exportForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "exportForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("dataset.export_filter"),
                        prop: "expressionTree",
                      },
                    },
                    [
                      _c("div", { staticClass: "tree-cont" }, [
                        _c(
                          "div",
                          { staticClass: "content" },
                          [_c("rowAuth", { ref: "rowAuth" })],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "tip" }, [
                _vm._v("提示：最多支持导出10万条数据"),
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "deBtn",
                    {
                      attrs: { secondary: "" },
                      on: { click: _vm.closeExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")) + "\n      ")]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exportDatasetRequest },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")) + "\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }