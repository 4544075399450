var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "de-card-model", style: _vm.classBackground },
    [
      _c("div", { staticClass: "card-img-model", style: _vm.classImg }, [
        _c("img", { attrs: { src: _vm.model.snapshot, alt: "" } }),
      ]),
      _c(
        "div",
        { staticClass: "card-info" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.model.name,
                placement: "top",
              },
            },
            [
              _c("span", { staticClass: "de-model-text" }, [
                _vm._v(_vm._s(_vm.model.name)),
              ]),
            ]
          ),
          _c(
            "el-dropdown",
            {
              attrs: { size: "medium", trigger: "click" },
              on: { command: _vm.handleCommand },
            },
            [
              _c("i", { staticClass: "el-icon-more" }),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "de-card-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _vm._t("default", [
                    _c("el-dropdown-item", { attrs: { command: "rename" } }, [
                      _c("i", { staticClass: "el-icon-edit" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("chart.rename")) +
                          "\n          "
                      ),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "delete" } }, [
                      _c("i", { staticClass: "el-icon-delete" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("chart.delete")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }