var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": _vm.closeOnClickModal,
        title: _vm.title,
        width: _vm.width,
        visible: _vm.visible,
        "destroy-on-close": "",
        "append-to-body": _vm.appendToBody,
      },
      on: { close: _vm.handleClose },
      scopedSlots: _vm._u(
        [
          {
            key: "footer",
            fn: function () {
              return [
                _vm._t("footer", [
                  _vm.withFooter
                    ? _c(
                        "div",
                        { staticClass: "dialog-footer" },
                        [
                          _c("ms-dialog-footer", {
                            on: {
                              cancel: _vm.handleCancel,
                              confirm: _vm.handleConfirm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [_vm._t("header"), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }