var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "el-row",
        [
          _vm.hasDataPermission("manage", _vm.param.privileges) &&
          _vm.enableUpdate
            ? _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-setting", size: "mini" },
                  on: { click: _vm.showConfig },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("dataset.update_setting")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh", size: "mini" },
              on: { click: _vm.refreshLog },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("commons.refresh")) + "\n    ")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                data: _vm.taskLogData,
                border: "",
                height: _vm.height,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: _vm.$t("dataset.task_name") },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  label: _vm.$t("dataset.start_time"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("timestampFormatDate")(scope.row.startTime)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: _vm.$t("dataset.end_time") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("timestampFormatDate")(scope.row.endTime)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: _vm.$t("dataset.status") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === "Completed"
                          ? _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(_vm.$t("dataset.completed"))),
                            ])
                          : _vm._e(),
                        scope.row.status === "Underway"
                          ? _c("span", { staticClass: "blue-color" }, [
                              _c("i", { staticClass: "el-icon-loading" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("dataset.underway")) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        scope.row.status === "Error"
                          ? _c(
                              "span",
                              { staticStyle: { color: "red" } },
                              [
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showErrorMassage(
                                          scope.row.info
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("dataset.error")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px", "text-align": "right" } },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.page.currentPage,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.page.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.page.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "dialog-css",
          attrs: {
            title: _vm.$t("dataset.detail"),
            visible: _vm.show_error_massage,
            "show-close": false,
            width: "50%",
          },
        },
        [
          _c("span", { staticClass: "err-msg" }, [
            _vm._v(_vm._s(_vm.error_massage) + "\n    "),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_error_massage = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "dialog-css",
          attrs: {
            title: _vm.table.name + " " + _vm.$t("dataset.update_setting"),
            visible: _vm.update_setting,
            "show-close": false,
            width: "50%",
          },
        },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "dialog-css",
              attrs: {
                title: _vm.update_task_dialog_title,
                visible: _vm.update_task,
                "show-close": false,
                width: "50%",
                "append-to-body": "",
              },
            },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    {
                      ref: "taskForm",
                      attrs: {
                        form: _vm.taskForm,
                        model: _vm.taskForm,
                        "label-width": "100px",
                        size: "mini",
                        rules: _vm.taskFormRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("dataset.task_name"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "50%" },
                            attrs: {
                              size: "mini",
                              placeholder: _vm.$t("dataset.task_name"),
                            },
                            model: {
                              value: _vm.taskForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskForm, "name", $$v)
                              },
                              expression: "taskForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("dataset.update_type"),
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.taskForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.taskForm, "type", $$v)
                                },
                                expression: "taskForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("dataset.all_scope"),
                                  value: "all_scope",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("dataset.add_scope"),
                                  value: "add_scope",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("dataset.execute_rate"),
                            prop: "rate",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "mini" },
                              on: { change: _vm.onRateChange },
                              model: {
                                value: _vm.taskForm.rate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.taskForm, "rate", $$v)
                                },
                                expression: "taskForm.rate",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("dataset.execute_once"),
                                  value: "SIMPLE",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("dataset.simple_cron"),
                                  value: "SIMPLE_CRON",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("dataset.cron_config"),
                                  value: "CRON",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.taskForm.rate === "CRON"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-popover",
                                {
                                  model: {
                                    value: _vm.cronEdit,
                                    callback: function ($$v) {
                                      _vm.cronEdit = $$v
                                    },
                                    expression: "cronEdit",
                                  },
                                },
                                [
                                  _c("cron", {
                                    on: {
                                      close: function ($event) {
                                        _vm.cronEdit = false
                                      },
                                    },
                                    model: {
                                      value: _vm.taskForm.cron,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "cron", $$v)
                                      },
                                      expression: "taskForm.cron",
                                    },
                                  }),
                                  _c("el-input", {
                                    staticStyle: { width: "50%" },
                                    attrs: { slot: "reference", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        _vm.cronEdit = true
                                      },
                                    },
                                    slot: "reference",
                                    model: {
                                      value: _vm.taskForm.cron,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.taskForm, "cron", $$v)
                                      },
                                      expression: "taskForm.cron",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskForm.rate === "SIMPLE_CRON"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-form",
                                { attrs: { inline: true } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: _vm.$t("cron.every") } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          type: "number",
                                          min: "1",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSimpleCronChange()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.taskForm.extraData
                                              .simple_cron_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.taskForm.extraData,
                                              "simple_cron_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "taskForm.extraData.simple_cron_value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "form-item" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            size: "mini",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSimpleCronChange()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.taskForm.extraData
                                                .simple_cron_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.taskForm.extraData,
                                                "simple_cron_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "taskForm.extraData.simple_cron_type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "cron.minute_default"
                                              ),
                                              value: "minute",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label:
                                                _vm.$t("cron.hour_default"),
                                              value: "hour",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t("cron.day_default"),
                                              value: "day",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-form-item", {
                                    staticClass: "form-item",
                                    attrs: { label: _vm.$t("cron.every_exec") },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskForm.rate !== "SIMPLE"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("dataset.start_time"),
                                prop: "startTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: _vm.$t(
                                    "dataset.select_data_time"
                                  ),
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.taskForm.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.taskForm, "startTime", $$v)
                                  },
                                  expression: "taskForm.startTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskForm.rate !== "SIMPLE"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("dataset.end_time"),
                                prop: "end",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.taskForm.end,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.taskForm, "end", $$v)
                                    },
                                    expression: "taskForm.end",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("dataset.no_limit"),
                                      value: "0",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("dataset.set_end_time"),
                                      value: "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskForm.end === "1"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: _vm.$t(
                                    "dataset.select_data_time"
                                  ),
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.taskForm.endTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.taskForm, "endTime", $$v)
                                  },
                                  expression: "taskForm.endTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "dialog_cancel_button",
                      attrs: { size: "mini" },
                      on: { click: _vm.closeTask },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.saveTask(_vm.taskForm)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.addTask(undefined)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("dataset.add_task")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "mini",
                    data: _vm.taskData,
                    height: "240",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: _vm.$t("dataset.task_name") },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: _vm.$t("dataset.execute_rate"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.rate === "SIMPLE"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataset.execute_once"))
                                  ),
                                ])
                              : _vm._e(),
                            scope.row.rate === "SIMPLE_CRON"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("dataset.simple_cron"))),
                                ])
                              : _vm._e(),
                            scope.row.rate === "CRON"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("dataset.cron_config"))),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: _vm.$t("dataset.task.task_status"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === "Underway"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "green" } },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: { "font-size": "12px" },
                                        attrs: { type: "success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeTaskStatus(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("dataset.task.underway")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.status === "Stopped"
                              ? _c("span", [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("dataset.task.stopped"))
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            scope.row.status === "Pending"
                              ? _c(
                                  "span",
                                  { staticClass: "blue-color" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: { "font-size": "12px" },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeTaskStatus(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("dataset.task.pending"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.status === "Exec"
                              ? _c("span", { staticClass: "blue-color" }, [
                                  _c("i", { staticClass: "el-icon-loading" }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("dataset.underway")) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("dataset.operate") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-edit",
                                circle: "",
                                disabled:
                                  scope.row.rate === "SIMPLE" ||
                                  scope.row.status === "Stopped",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addTask(scope.row)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTask(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _vm.table.type !== "api"
            ? _c(
                "el-row",
                { staticStyle: { height: "26px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("dataset.incremental_update_type")) +
                              ":"
                          ),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: { change: _vm.incrementalUpdateTypeChange },
                              model: {
                                value: _vm.incrementalUpdateType,
                                callback: function ($$v) {
                                  _vm.incrementalUpdateType = $$v
                                },
                                expression: "incrementalUpdateType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "incrementalAdd" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataset.incremental_add"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "incrementalDelete" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataset.incremental_delete"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.table.type !== "api"
            ? _c(
                "el-row",
                { staticStyle: { height: "26px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticStyle: { height: "26px" }, attrs: { span: 4 } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                height: "26px",
                                "line-height": "26px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("dataset.param")) + ":")]
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.insertParamToCodeMirror(
                                    "${__last_update_time__}"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("dataset.last_update_time")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.insertParamToCodeMirror(
                                    "${__current_update_time__}"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("dataset.current_update_time"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.table.type !== "api"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticStyle: { "min-width": "200px" } },
                    [
                      _c("codemirror", {
                        ref: "myCm",
                        staticClass: "codemirror",
                        attrs: { options: _vm.sqlOption },
                        on: {
                          ready: _vm.onCmReady,
                          focus: _vm.onCmFocus,
                          input: _vm.onCmCodeChange,
                        },
                        model: {
                          value: _vm.sql,
                          callback: function ($$v) {
                            _vm.sql = $$v
                          },
                          expression: "sql",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.update_setting = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.close")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.saveIncrementalConfig },
                },
                [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }