var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card_main",
      style: { background: _vm.details.bgColor },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.goToWeb($event)
        },
      },
    },
    [
      _c(
        "el-col",
        { staticStyle: { width: "230px" } },
        [
          _c("el-row", { staticClass: "card_head" }, [
            _vm._v("\n      " + _vm._s(_vm.details.head) + "\n    "),
          ]),
          _c("el-row", { staticClass: "card_content" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.details.content) },
            }),
          ]),
          _c("el-row", { staticClass: "card_bottom" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("wizard.apply")) + "\n      "),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]),
        ],
        1
      ),
      _c("svg-icon", {
        staticClass: "img-position",
        attrs: { "icon-class": _vm.details.img },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }