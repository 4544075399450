var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      !_vm.sceneMode
        ? _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dsLoading,
                  expression: "dsLoading",
                },
              ],
            },
            [
              _c("el-row", { staticClass: "title-css" }, [
                _c("span", { staticClass: "title-text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("dataset.datalist")) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "form-item" },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              placeholder: _vm.$t("dataset.search"),
                              "prefix-icon": "el-icon-search",
                              clearable: "",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { staticClass: "custom-tree-container" }, [
                _c(
                  "div",
                  { staticClass: "block", style: _vm.treeStyle },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        "default-expanded-keys": _vm.expandedArray,
                        data: _vm.treeData,
                        "node-key": "id",
                        "expand-on-click-node": false,
                        "filter-node-method": _vm.filterNode,
                      },
                      on: {
                        "node-click": _vm.nodeClick,
                        "node-expand": _vm.nodeExpand,
                        "node-collapse": _vm.nodeCollapse,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        flex: "1",
                                        width: "0",
                                      },
                                    },
                                    [
                                      data.type === "scene"
                                        ? _c(
                                            "span",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "scene",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "6px",
                                            "white-space": "nowrap",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                          },
                                          attrs: { title: data.name },
                                        },
                                        [_vm._v(_vm._s(data.name))]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3067343551
                      ),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.sceneMode
        ? _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dsLoading,
                  expression: "dsLoading",
                },
              ],
            },
            [
              _c(
                "el-row",
                { staticClass: "title-css scene-title" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "title-text scene-title-name",
                      attrs: { title: _vm.currGroup.name },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.currGroup.name) + "\n      "
                      ),
                    ]
                  ),
                  _c("el-button", {
                    staticStyle: { float: "right" },
                    attrs: { icon: "el-icon-back", size: "mini", circle: "" },
                    on: { click: _vm.back },
                  }),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "form-item" },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              placeholder: _vm.$t("dataset.search"),
                              "prefix-icon": "el-icon-search",
                              clearable: "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { staticClass: "custom-tree-container" }, [
                _c(
                  "div",
                  { staticClass: "block", style: _vm.treeStyle },
                  [
                    _c("el-tree", {
                      staticClass: "tree-list",
                      attrs: {
                        data: _vm.tableData,
                        "node-key": "id",
                        "expand-on-click-node": true,
                        "highlight-current": "",
                      },
                      on: { "node-click": _vm.sceneClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node-list" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        flex: "1",
                                        width: "0",
                                      },
                                      attrs: { id: data.id },
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          data.type === "db"
                                            ? _c("svg-icon", {
                                                staticClass: "ds-icon-db",
                                                attrs: {
                                                  "icon-class": "ds-db",
                                                },
                                              })
                                            : _vm._e(),
                                          data.type === "sql"
                                            ? _c("svg-icon", {
                                                staticClass: "ds-icon-sql",
                                                attrs: {
                                                  "icon-class": "ds-sql",
                                                },
                                              })
                                            : _vm._e(),
                                          data.type === "excel"
                                            ? _c("svg-icon", {
                                                staticClass: "ds-icon-excel",
                                                attrs: {
                                                  "icon-class": "ds-excel",
                                                },
                                              })
                                            : _vm._e(),
                                          data.type === "custom"
                                            ? _c("svg-icon", {
                                                staticClass: "ds-icon-custom",
                                                attrs: {
                                                  "icon-class": "ds-custom",
                                                },
                                              })
                                            : _vm._e(),
                                          data.type === "union"
                                            ? _c("svg-icon", {
                                                staticClass: "ds-icon-union",
                                                attrs: {
                                                  "icon-class": "ds-union",
                                                },
                                              })
                                            : _vm._e(),
                                          data.type === "api"
                                            ? _c("svg-icon", {
                                                staticClass: "ds-icon-api",
                                                attrs: {
                                                  "icon-class": "ds-api",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      data.type === "db" || data.type === "sql"
                                        ? _c("span", [
                                            data.mode === 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "6px",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-s-operation",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            data.mode === 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "6px",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-alarm-clock",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "6px",
                                            "white-space": "nowrap",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                          },
                                          attrs: { title: data.name },
                                        },
                                        [_vm._v(_vm._s(data.name))]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2042440326
                      ),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }