var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-container" }, [
    _c(
      "div",
      {
        staticClass: "container-wrapper",
        class: [_vm.needInnerPadding ? "layout-inner-padding" : ""],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }