var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _vm.description
        ? _c("span", { staticClass: "kv-description" }, [
            _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
          ])
        : _vm._e(),
      _vm._l(_vm.parameters, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "item kv-row" },
          [
            _c(
              "el-row",
              {
                attrs: {
                  type: "flex",
                  gutter: 20,
                  justify: "space-between",
                  align: "middle",
                },
              },
              [
                _c("span", { staticStyle: { "margin-left": "10px" } }),
                _c("i", {
                  staticClass: "el-icon-top",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.moveTop(index)
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-bottom",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.moveBottom(index)
                    },
                  },
                }),
                _c(
                  "el-col",
                  { staticClass: "item" },
                  [
                    !_vm.suggestions
                      ? _c("el-input", {
                          attrs: {
                            disabled: _vm.isReadOnly,
                            size: "small",
                            maxlength: "200",
                            placeholder: _vm.keyText,
                            "show-word-limit": "",
                          },
                          on: { change: _vm.change },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _vm.type === "body"
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "kv-type",
                                            attrs: { disabled: _vm.isReadOnly },
                                            on: {
                                              change: function ($event) {
                                                return _vm.typeChange(item)
                                              },
                                            },
                                            model: {
                                              value: item.type,
                                              callback: function ($$v) {
                                                _vm.$set(item, "type", $$v)
                                              },
                                              expression: "item.type",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: { value: "text" },
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "json" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        })
                      : _vm._e(),
                    _vm.suggestions
                      ? _c("el-autocomplete", {
                          attrs: {
                            disabled: _vm.isReadOnly,
                            size: "small",
                            "fetch-suggestions": _vm.querySearch,
                            placeholder: _vm.keyText,
                            "show-word-limit": "",
                          },
                          on: { change: _vm.change },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isActive && item.type !== "file"
                  ? _c(
                      "el-col",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          staticClass: "input-with-autocomplete",
                          attrs: {
                            disabled: _vm.isReadOnly,
                            size: "small",
                            placeholder: _vm.valueText,
                            "value-key": "name",
                            "highlight-first-item": "",
                          },
                          on: { select: _vm.change },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticClass: "item" },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        maxlength: "200",
                        placeholder: _vm.$t("commons.description"),
                        "show-word-limit": "",
                      },
                      model: {
                        value: item.description,
                        callback: function ($$v) {
                          _vm.$set(item, "description", $$v)
                        },
                        expression: "item.description",
                      },
                    }),
                    _vm.suggestions
                      ? _c("el-autocomplete", {
                          attrs: {
                            disabled: _vm.isReadOnly,
                            size: "small",
                            "fetch-suggestions": _vm.querySearch,
                            placeholder: _vm.keyText,
                            "show-word-limit": "",
                          },
                          on: { change: _vm.change },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "item kv-delete" },
                  [
                    _c("el-button", {
                      staticClass: "el-icon-delete-solid",
                      attrs: {
                        size: "mini",
                        circle: "",
                        disabled: _vm.isDisable(index) || _vm.isReadOnly,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.remove(index)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }