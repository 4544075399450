var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticClass: "demo-form-inline",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.show,
            "label-width": "180px",
            "label-position": "top",
            size: "small",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("datasource.type"), prop: "type" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "datasource.please_choose_type"
                            ),
                            filterable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeType()
                            },
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.allTypes, function (item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.label, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("datasource.host"),
                        prop: "configuration.host",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.configuration.host,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.configuration, "host", $$v)
                          },
                          expression: "form.configuration.host",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("datasource.data_base"),
                        prop: "configuration.dataBase",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.configuration.dataBase,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.configuration, "dataBase", $$v)
                          },
                          expression: "form.configuration.dataBase",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("datasource.user_name") } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.configuration.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.configuration, "username", $$v)
                          },
                          expression: "form.configuration.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("datasource.password") } },
                    [
                      _c("el-input", {
                        attrs: { "show-password": "" },
                        model: {
                          value: _vm.form.configuration.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.configuration, "password", $$v)
                          },
                          expression: "form.configuration.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("datasource.port"),
                        prop: "configuration.port",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          type: "number",
                          min: "0",
                        },
                        model: {
                          value: _vm.form.configuration.port,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.configuration, "port", $$v)
                          },
                          expression: "form.configuration.port",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("datasource.extra_params") } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.configuration.extraParams,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.configuration, "extraParams", $$v)
                          },
                          expression: "form.configuration.extraParams",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.validaDatasource },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("commons.validate")) + "\n    ")]
          ),
          _vm.showEdit
            ? _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.edit } },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.edit")) + "\n    ")]
              )
            : _vm._e(),
          _vm.showSave
            ? _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: { click: _vm.save },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.save")) + "\n    ")]
              )
            : _vm._e(),
          _vm.showCancel
            ? _c(
                "el-button",
                {
                  attrs: { type: "info", size: "small" },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("commons.cancel")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }