var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
      staticClass: "preview-outer-body market-main",
    },
    [
      _c(
        "el-row",
        { staticStyle: { position: "absolute", left: "5px", top: "5px" } },
        [
          _c("span", {
            staticClass: "icon iconfont icon-close icon20 insert",
            on: {
              click: function ($event) {
                return _vm.closePreview()
              },
            },
          }),
        ]
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: "12" } }, [
            _c("span", { staticClass: "title-left" }, [
              _vm._v(_vm._s(_vm.currentApp.name)),
            ]),
          ]),
        ],
        1
      ),
      _vm.currentTemplateShowList && _vm.currentTemplateShowList.length > 0
        ? _c(
            "el-row",
            { staticClass: "template-main-preview" },
            [
              _c(
                "el-row",
                { staticClass: "preview-slider" },
                [
                  _c(
                    "el-row",
                    { staticClass: "top-list", style: _vm.topSlideStyle },
                    [
                      _vm._l(
                        _vm.currentTemplateShowList,
                        function (templateItem) {
                          return _c("template-market-preview-item", {
                            key: templateItem.id,
                            attrs: {
                              template: templateItem,
                              active: _vm.active(templateItem),
                            },
                            on: { previewTemplate: _vm.previewTemplate },
                          })
                        }
                      ),
                      _c(
                        "el-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.hasResult,
                              expression: "!hasResult",
                            },
                          ],
                          staticClass: "custom-position",
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  "font-size": "75px",
                                  "margin-bottom": "16px",
                                },
                                attrs: { "icon-class": "no_result" },
                              }),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("commons.no_result"))),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "24px" } },
                [
                  _c("app-template-log", {
                    staticClass: "log-area",
                    attrs: {
                      "app-template-id": _vm.curTemplate.id,
                      position: "templateLog",
                    },
                    on: { applyNew: _vm.applyNew },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "el-row",
            { staticClass: "template-main-preview" },
            [
              _c("el-empty", {
                attrs: {
                  image: _vm.noneImg,
                  description: _vm.$t("app_template.no_apps"),
                },
              }),
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value:
                _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
              expression:
                "$store.getters.loadingMap[$store.getters.currentPath]",
            },
          ],
          staticClass: "market-dialog-css",
          attrs: {
            title: _vm.$t("panel.apply_template"),
            visible: _vm.applyNewVisible,
            width: "80%",
            height: "90vh",
            top: "5vh",
            "append-to-body": "true",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.applyNewVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "ds-from-main" },
            [
              _vm.applyNewVisible
                ? _c("DsAppForm", {
                    attrs: {
                      params: _vm.dsParams,
                      "t-data": _vm.tData,
                      "ds-types": _vm.dsTypes,
                      "opt-type": "appApply",
                      "attach-params": _vm.attachParams,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }