var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editor", {
    attrs: { lang: _vm.mode, theme: _vm.theme, height: _vm.height },
    on: { init: _vm.editorInit },
    model: {
      value: _vm.formatData,
      callback: function ($$v) {
        _vm.formatData = $$v
      },
      expression: "formatData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }