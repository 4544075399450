import { render, staticRenderFns } from "./AddDB.vue?vue&type=template&id=47ee9d50&scoped=true&"
import script from "./AddDB.vue?vue&type=script&lang=js&"
export * from "./AddDB.vue?vue&type=script&lang=js&"
import style0 from "./AddDB.vue?vue&type=style&index=0&id=47ee9d50&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ee9d50",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\zhich\\Desktop\\ZcyProject\\zcy-dataEase\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47ee9d50')) {
      api.createRecord('47ee9d50', component.options)
    } else {
      api.reload('47ee9d50', component.options)
    }
    module.hot.accept("./AddDB.vue?vue&type=template&id=47ee9d50&scoped=true&", function () {
      api.rerender('47ee9d50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dataset/add/AddDB.vue"
export default component.exports