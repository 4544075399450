var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "testcase-template" }, [
    _c("div", {
      staticClass: "template-img",
      style: _vm.classBackground,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.appPreview($event)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }