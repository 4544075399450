var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _c("div", { staticClass: "de-template" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value:
                  _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
                expression:
                  "$store.getters.loadingMap[$store.getters.currentPath]",
              },
            ],
            staticClass: "tabs-container flex-tabs",
          },
          [
            _c(
              "div",
              { staticClass: "de-tabs-left" },
              [
                _c("template-list", {
                  ref: "templateList",
                  attrs: {
                    "template-type": _vm.currentTemplateType,
                    "template-list": _vm.templateList,
                    "show-position": _vm.showPosition,
                  },
                  on: {
                    templateDelete: _vm.templateListDelete,
                    templateEdit: _vm.templateEdit,
                    showCurrentTemplate: _vm.showCurrentTemplate,
                    templateImport: _vm.templateImport,
                    showTemplateEditDialog: _vm.showTemplateEditDialog,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "de-tabs-right" },
              [
                _vm.currentTemplateLabel
                  ? _c(
                      "div",
                      { staticClass: "active-template" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.currentTemplateLabel) +
                            "  (" +
                            _vm._s(_vm.currentTemplateShowList.length) +
                            ")\n          "
                        ),
                        _vm.showPositionCheck("system-setting")
                          ? _c(
                              "deBtn",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-upload2",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.templateImport(
                                      _vm.currentTemplateId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("app_template.app_upload")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.currentTemplateShowList.length
                  ? _c("el-empty", {
                      attrs: {
                        image: _vm.noneImg,
                        description: _vm.$t("app_template.no_apps"),
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentTemplateId !== "",
                        expression: "currentTemplateId !== ''",
                      },
                    ],
                    staticClass: "template-box",
                    attrs: { id: "template-box" },
                  },
                  _vm._l(_vm.currentTemplateShowList, function (item) {
                    return _c("template-item", {
                      key: item.id,
                      attrs: {
                        width: _vm.templateCurWidth,
                        model: item,
                        "show-position": _vm.showPosition,
                      },
                      on: {
                        applyNew: function ($event) {
                          return _vm.applyNew(item)
                        },
                        previewApp: function ($event) {
                          return _vm.previewApp(item)
                        },
                        command: function (key) {
                          return _vm.handleCommand(key, item)
                        },
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "de-dialog-form",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.editTemplate,
            "append-to-body": "",
            width: "600px",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editTemplate = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "templateEditForm",
              staticClass: "de-form-item",
              attrs: {
                model: _vm.templateEditForm,
                rules: _vm.templateEditFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.dialogTitleLabel, prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.templateEditForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateEditForm, "name", $$v)
                      },
                      expression: "templateEditForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("app_template.app_group_icon"),
                    prop: "icon",
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        width: "148px!important",
                        height: "148px!important",
                        overflow: "hidden",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          class: { disabled: _vm.uploadDisabled },
                          attrs: {
                            action: "",
                            accept: ".jpeg,.jpg,.png,.gif,.svg",
                            "list-type": "picture-card",
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "http-request": _vm.upload,
                            "file-list": _vm.fileList,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            top: "25vh",
                            width: "600px",
                            "append-to-body": true,
                            "destroy-on-close": true,
                            visible: _vm.dialogVisible,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogVisible = $event
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: { width: "100%", src: _vm.dialogImageUrl },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "deBtn",
                {
                  attrs: { secondary: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n      ")]
              ),
              _c(
                "deBtn",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveTemplateEdit(_vm.templateEditForm)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.confirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "de-dialog-form",
          attrs: {
            title: _vm.templateDialog.title,
            visible: _vm.templateDialog.visible,
            "show-close": true,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.templateDialog, "visible", $event)
            },
          },
        },
        [
          _vm.templateDialog.visible
            ? _c("template-import", {
                attrs: {
                  pid: _vm.templateDialog.pid,
                  "opt-type": _vm.templateOptType,
                  "app-template-info": _vm.currentAppTemplateInfo,
                },
                on: {
                  refresh: function ($event) {
                    return _vm.showCurrentTemplate(
                      _vm.currentTemplateId,
                      _vm.currentTemplateLabel
                    )
                  },
                  closeEditTemplateDialog: _vm.closeEditTemplateDialog,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "de-dialog-form",
          attrs: {
            title: _vm.$t("app_template.move_item"),
            visible: _vm.moveItemDialogShow,
            "show-close": true,
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moveItemDialogShow = $event
            },
          },
        },
        [
          _c("template-move-list", {
            attrs: {
              "template-list": _vm.templateList,
              "source-template-info": _vm.currentMoveItem,
            },
            on: {
              closeDialog: function ($event) {
                _vm.moveItemDialogShow = false
              },
              templateMoveClose: _vm.templateMoveClose,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }