var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dataset-db",
      on: { mouseup: _vm.mouseupDrag },
    },
    [
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showLeft,
              expression: "!showLeft",
            },
          ],
          staticClass: "arrow-right",
          on: {
            click: function ($event) {
              _vm.showLeft = true
            },
          },
        },
        [_c("i", { staticClass: "el-icon-d-arrow-right" })]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLeft,
            expression: "showLeft",
          },
        ],
        staticClass: "drag-left",
        style: { left: _vm.LeftWidth + "px" },
        on: { mousedown: _vm.mousedownDrag },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLeft,
              expression: "showLeft",
            },
          ],
          staticClass: "table-list",
          style: { width: _vm.LeftWidth + "px" },
        },
        [
          _c("p", { staticClass: "select-ds" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("deDataset.select_data_source")) +
                "\n      "
            ),
            _c("i", {
              staticClass: "el-icon-d-arrow-left",
              on: {
                click: function ($event) {
                  _vm.showLeft = false
                },
              },
            }),
          ]),
          _c(
            "el-select",
            {
              staticClass: "ds-list",
              attrs: {
                filterable: "",
                "popper-class": "db-multiple-select-pop",
                placeholder: _vm.$t("dataset.pls_slc_data_source"),
                size: "small",
              },
              model: {
                value: _vm.dataSource,
                callback: function ($$v) {
                  _vm.dataSource = $$v
                },
                expression: "dataSource",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c("p", { staticClass: "select-ds" }, [
            _vm._v(_vm._s(_vm.$t("datasource.data_table"))),
          ]),
          _c("el-input", {
            staticClass: "search",
            attrs: {
              size: "small",
              placeholder: _vm.$t("deDataset.by_table_name"),
              "prefix-icon": "el-icon-search",
              clearable: "",
            },
            model: {
              value: _vm.searchTable,
              callback: function ($$v) {
                _vm.searchTable = $$v
              },
              expression: "searchTable",
            },
          }),
          !_vm.tableData.length && _vm.searchTable !== ""
            ? _c("div", { staticClass: "el-empty" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-empty__description",
                    staticStyle: { "margin-top": "80px", color: "#5e6d82" },
                  },
                  [_vm._v("\n        没有找到相关内容\n      ")]
                ),
              ])
            : _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dsLoading,
                      expression: "dsLoading",
                    },
                  ],
                  staticClass: "table-checkbox-list",
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.checkTableList,
                        callback: function ($$v) {
                          _vm.checkTableList = $$v
                        },
                        expression: "checkTableList",
                      },
                    },
                    _vm._l(_vm.tableData, function (t) {
                      return _c(
                        "el-tooltip",
                        {
                          key: t.name,
                          attrs: {
                            disabled: t.enableCheck,
                            effect: "dark",
                            content:
                              _vm.$t("dataset.table_already_add_to") +
                              ": " +
                              t.datasetPath,
                            placement: "right",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              class: [
                                {
                                  active: _vm.activeName === t.name,
                                  "not-allow": !t.enableCheck,
                                },
                              ],
                              attrs: { title: t.name },
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveName(t)
                                },
                              },
                            },
                            [
                              !t.enableCheck
                                ? _c("svg-icon", {
                                    staticStyle: { "margin-right": "8px" },
                                    attrs: { "icon-class": "Checkbox" },
                                  })
                                : _c("el-checkbox", {
                                    attrs: {
                                      label: t.name,
                                      disabled: !t.enableCheck,
                                    },
                                  }),
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.showTableNameWithComment(t))),
                              ]),
                              t.nameExist
                                ? _c(
                                    "span",
                                    { staticClass: "error-name-exist" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ds-icon-scene",
                                        attrs: {
                                          "icon-class": "exclamationmark",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-detail" },
        [
          _c(
            "div",
            { staticClass: "top-table-detail" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("dataset.connect_mode"),
                    size: "small",
                  },
                  model: {
                    value: _vm.mode,
                    callback: function ($$v) {
                      _vm.mode = $$v
                    },
                    expression: "mode",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: _vm.$t("dataset.direct_connect"),
                      value: "0",
                    },
                  }),
                  _vm.engineMode !== "simple"
                    ? _c("el-option", {
                        attrs: {
                          label: _vm.$t("dataset.sync_data"),
                          value: "1",
                          disabled: _vm.disabledSync,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.mode === "1"
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("dataset.connect_mode"),
                        size: "small",
                      },
                      model: {
                        value: _vm.syncType,
                        callback: function ($$v) {
                          _vm.syncType = $$v
                        },
                        expression: "syncType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("dataset.sync_now"),
                          value: "sync_now",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("dataset.sync_latter"),
                          value: "sync_latter",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.dataSource
            ? _c("el-empty", {
                staticStyle: { "padding-top": "160px" },
                attrs: {
                  size: "125",
                  description: _vm.$t("dataset.pls_slc_data_source"),
                  image: _vm.noSelectTable,
                },
              })
            : _vm.activeName
            ? [
                _c(
                  "div",
                  { staticClass: "dataset" },
                  [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("dataset.name"))),
                    ]),
                    _c("el-input", {
                      attrs: { size: "small", clearable: "" },
                      on: { change: _vm.validateName },
                      model: {
                        value: _vm.activeTable.datasetName,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeTable, "datasetName", $$v)
                        },
                        expression: "activeTable.datasetName",
                      },
                    }),
                    _vm.activeTable.nameExist
                      ? _c(
                          "div",
                          {
                            staticClass: "el-form-item__error",
                            staticStyle: { left: "107px", top: "52px" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("deDataset.already_exists")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading",
                      },
                    ],
                    staticClass: "data",
                  },
                  [
                    _c("span", { staticClass: "result-num" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("dataset.preview_show") +
                            " 1000 " +
                            _vm.$t("dataset.preview_item")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "table-grid" },
                      [
                        _c(
                          "ux-grid",
                          {
                            ref: "plxTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              height: _vm.height,
                              "checkbox-config": { highlight: true },
                              "width-resize": true,
                            },
                          },
                          _vm._l(_vm.fields, function (field) {
                            return _c("ux-table-column", {
                              key: field.fieldName,
                              attrs: {
                                "min-width": "200px",
                                field: field.fieldName,
                                title: field.remarks,
                                resizable: true,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm.avilibelTable
            ? _c("el-empty", {
                staticStyle: { "padding-top": "160px" },
                attrs: {
                  size: "125",
                  description: _vm.$t("deDataset.is_currently_available"),
                  image: _vm.noAvilibelTableImg,
                },
              })
            : !_vm.activeName
            ? _c("el-empty", {
                staticStyle: { "padding-top": "160px" },
                attrs: {
                  size: "125",
                  description: _vm.$t("deDataset.left_to_edit"),
                  image: _vm.noSelectTable,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }