var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logic", style: _vm.marginLeft }, [
    _c("div", { staticClass: "logic-left" }, [
      _c(
        "div",
        { staticClass: "operate-title" },
        [
          _vm.x
            ? _c(
                "span",
                {
                  staticClass: "mrg-title",
                  staticStyle: { "{\n            color": "'#bfbfbf'" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.logic === "or" ? "OR" : "AND") +
                      "\n      "
                  ),
                ]
              )
            : _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mrg-title",
                      staticStyle: {
                        "{\n              color": "'rgba(0,0,0,.65)'",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.logic === "or" ? "OR" : "AND")
                      ),
                      _c("i", { staticClass: "el-icon-arrow-down" }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "and" } }, [
                        _vm._v("AND"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "or" } }, [
                        _vm._v("OR"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm.x
        ? _c("span", { staticClass: "operate-icon" }, [
            _c("i", {
              staticClass: "el-icon-delete",
              on: {
                click: function ($event) {
                  return _vm.$emit("removeRelationList")
                },
              },
            }),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "logic-right" },
      [
        _vm._l(_vm.relationList, function (item, index) {
          return [
            item.child
              ? _c("logic-relation", {
                  key: index,
                  attrs: {
                    x: item.x,
                    logic: item.logic,
                    "relation-list": item.child,
                  },
                  on: {
                    del: function (idx) {
                      return _vm.del(idx, item.child)
                    },
                    addCondReal: function (type, logic) {
                      return _vm.add(type, item.child, logic)
                    },
                    removeRelationList: function ($event) {
                      return _vm.removeRelationList(index)
                    },
                  },
                })
              : _c("filter-filed", {
                  key: index,
                  attrs: { item: item, index: index },
                  on: {
                    del: function ($event) {
                      return _vm.$emit("del", index)
                    },
                  },
                }),
          ]
        }),
        _c("div", { staticClass: "logic-right-add" }, [
          _c(
            "button",
            {
              staticClass: "operand-btn",
              on: {
                click: function ($event) {
                  return _vm.addCondReal("condition")
                },
              },
            },
            [
              _vm._v(
                "\n        + " +
                  _vm._s(_vm.$t("auth.add_condition")) +
                  "\n      "
              ),
            ]
          ),
          _vm.x < 2
            ? _c(
                "button",
                {
                  staticClass: "operand-btn",
                  on: {
                    click: function ($event) {
                      return _vm.addCondReal("relation")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        + " +
                      _vm._s(_vm.$t("auth.add_relationship")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }