var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    {
      staticStyle: {
        height: "400px",
        "overflow-y": "auto",
        "margin-bottom": "10px",
      },
    },
    [
      _c("el-tree", {
        attrs: {
          data: _vm.tData,
          "node-key": "id",
          "expand-on-click-node": false,
          "highlight-current": "",
        },
        on: { "node-click": _vm.nodeClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c("span", { class: _vm.treeClass(data, node) }, [
                _c(
                  "span",
                  { staticStyle: { display: "flex", flex: "1", width: "0" } },
                  [
                    data.type === "scene"
                      ? _c(
                          "span",
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "scene" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "6px",
                          "white-space": "nowrap",
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                        },
                        attrs: { title: data.name },
                      },
                      [_vm._v(_vm._s(data.name))]
                    ),
                  ]
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }