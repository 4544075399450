var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
      staticClass: "template-import",
    },
    [
      _c(
        "el-form",
        {
          ref: "templateImportForm",
          staticClass: "de-form-item",
          attrs: { model: _vm.templateInfo, rules: _vm.templateInfoRules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("app_template.app_name"), prop: "name" } },
            [
              _c(
                "div",
                { staticClass: "flex-template" },
                [
                  _c("el-input", {
                    attrs: { clearable: "", size: "small" },
                    model: {
                      value: _vm.templateInfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateInfo, "name", $$v)
                      },
                      expression: "templateInfo.name",
                    },
                  }),
                  _c(
                    "deBtn",
                    {
                      staticClass: "el-icon-upload2",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { secondary: "" },
                      on: { click: _vm.goFile },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("app_template.app_upload")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("input", {
                    ref: "files",
                    attrs: {
                      id: "input",
                      type: "file",
                      accept: ".zip",
                      hidden: "",
                    },
                    on: { change: _vm.handleFileChange },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("el-row", { staticClass: "preview", style: _vm.classBackground }),
      _c(
        "el-row",
        { staticClass: "de-root-class" },
        [
          _c(
            "deBtn",
            {
              attrs: { secondary: "" },
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n    ")]
          ),
          _c(
            "deBtn",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }