var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-move-tree" },
    [
      _c("el-input", {
        staticStyle: { "margin-bottom": "16px" },
        attrs: {
          placeholder: _vm.$t("deDataset.search_by_name"),
          clearable: "",
          size: "small",
        },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c(
        "div",
        { staticClass: "tree" },
        [
          _c("el-tree", {
            ref: "tree",
            staticClass: "de-tree",
            attrs: {
              data: _vm.tData,
              "node-key": "id",
              "expand-on-click-node": false,
              "highlight-current": "",
              "filter-node-method": _vm.filterNode,
            },
            on: { "node-click": _vm.nodeClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c("span", { class: _vm.treeClass(data, node) }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "flex", flex: "1", width: "0" },
                      },
                      [
                        data.modelInnerType === "group"
                          ? _c(
                              "span",
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "scene" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("span", {
                          staticStyle: {
                            "margin-left": "6px",
                            "white-space": "nowrap",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                          },
                          attrs: { title: data.name },
                          domProps: {
                            innerHTML: _vm._s(_vm.highlights(data.name)),
                          },
                        }),
                      ]
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }