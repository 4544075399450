var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "DsConfig",
          attrs: {
            model: _vm.form,
            rules: _vm.rule,
            size: "small",
            disabled: _vm.disabled,
            "label-width": "180px",
            "label-position": "right",
          },
        },
        [
          _vm.form.type == "api"
            ? [
                _c(
                  "div",
                  { staticClass: "de-row-rules flex-space" },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("datasource.data_table"))),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "min-width": "80px",
                          "font-size": "14px",
                          padding: "8px 15px",
                        },
                        attrs: {
                          icon: "el-icon-plus",
                          size: "small",
                          type: "primary",
                        },
                        on: {
                          click: function () {
                            return _vm.addApiItem()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("commons.add")))]
                    ),
                  ],
                  1
                ),
                !_vm.form.apiConfiguration.length
                  ? _c("el-empty", {
                      attrs: {
                        image: _vm.noneImg,
                        description: _vm.$t("datasource.no_data_table"),
                      },
                    })
                  : _vm._l(_vm.form.apiConfiguration, function (api) {
                      return _c(
                        "div",
                        {
                          key: api.id,
                          staticClass: "api-card",
                          style: {
                            cursor: _vm.disabled ? "not-allowed" : "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addApiItem(api)
                            },
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { span: 19 },
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(api.name)),
                                  ]),
                                  api.status === "Error"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "de-tag",
                                          staticStyle: {
                                            color: "#646a73",
                                            background: "rgba(31, 35, 41, 0.1)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("datasource.invalid"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  api.status === "Success"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "de-tag",
                                          staticStyle: {
                                            color: "green",
                                            background:
                                              "rgba(52, 199, 36, 0.2)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("datasource.valid"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { span: 5 },
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "de-copy-icon",
                                    attrs: {
                                      "icon-class": "de-copy",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.copyItem(api)
                                      },
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          ref: "apiTable" + api.name,
                                          refInFor: true,
                                          attrs: {
                                            placement: "top",
                                            width: "200",
                                            disabled: _vm.disabled,
                                            "popper-class": "api-table-delete",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              "icon-class": "icon_info_filled",
                                            },
                                          }),
                                          _c("div", { staticClass: "tips" }, [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "datasource.delete_this_item"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "foot" },
                                            [
                                              _c(
                                                "deBtn",
                                                {
                                                  staticClass: "small",
                                                  attrs: { secondary: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelItem(api)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "fu.search_bar.cancel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "deBtn",
                                                {
                                                  staticClass: "small",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteItem(api)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("fu.search_bar.ok")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("svg-icon", {
                                            staticClass: "de-delete-icon",
                                            class: [
                                              _vm.disabled ? "not-allow" : "",
                                            ],
                                            attrs: {
                                              slot: "reference",
                                              "icon-class": "de-delete",
                                            },
                                            slot: "reference",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "req-title" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("datasource.method"))),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("datasource.url"))),
                            ]),
                          ]),
                          _c("div", { staticClass: "req-value" }, [
                            _c("span", [_vm._v(_vm._s(api.method))]),
                            _c("span", { attrs: { title: api.url } }, [
                              _vm._v(_vm._s(api.url)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
              ]
            : _vm._e(),
          _vm.form.type !== "es" && _vm.form.type !== "api"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("datasource.host"),
                    prop: "configuration.host",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("datasource._ip_address"),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.form.configuration.host,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "host", $$v)
                      },
                      expression: "form.configuration.host",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == "es"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("datasource.datasource_url"),
                    prop: "configuration.url",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "datasource.please_input_datasource_url"
                      ),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.form.configuration.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "url", $$v)
                      },
                      expression: "form.configuration.url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type !== "es" && _vm.form.type !== "api"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("datasource.data_base"),
                    prop: "configuration.dataBase",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("datasource.please_input_data_base"),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.form.configuration.dataBase,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "dataBase", $$v)
                      },
                      expression: "form.configuration.dataBase",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == "oracle" && _vm.form.type !== "api"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("datasource.connection_mode"),
                    prop: "configuration.connectionType",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "sid" },
                      model: {
                        value: _vm.form.configuration.connectionType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.configuration,
                            "connectionType",
                            $$v
                          )
                        },
                        expression: "form.configuration.connectionType",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("datasource.oracle_sid")) + "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "serviceName" },
                      model: {
                        value: _vm.form.configuration.connectionType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.configuration,
                            "connectionType",
                            $$v
                          )
                        },
                        expression: "form.configuration.connectionType",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("datasource.oracle_service_name")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == "hive"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.auth_method") } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "de-select",
                      model: {
                        value: _vm.form.configuration.authMethod,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.configuration, "authMethod", $$v)
                        },
                        expression: "form.configuration.authMethod",
                      },
                    },
                    _vm._l(_vm.authMethodList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === "hive" &&
          _vm.form.configuration.authMethod === "kerberos"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.client_principal") } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.configuration.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "username", $$v)
                      },
                      expression: "form.configuration.username",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === "hive" &&
          _vm.form.configuration.authMethod === "kerberos"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.keytab_Key_path") } },
                [
                  _c("dePwd", {
                    model: {
                      value: _vm.form.configuration.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "password", $$v)
                      },
                      expression: "form.configuration.password",
                    },
                  }),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("datasource.kerbers_info")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === "hive" &&
          _vm.form.configuration.authMethod === "kerberos"
            ? _c("span")
            : _vm._e(),
          _vm.form.type !== "es" &&
          _vm.form.type !== "api" &&
          _vm.form.configuration.authMethod !== "kerberos"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.user_name") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("components.one_user_name"),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.form.configuration.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "username", $$v)
                      },
                      expression: "form.configuration.username",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type !== "es" &&
          _vm.form.type !== "api" &&
          _vm.form.configuration.authMethod !== "kerberos"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.password") } },
                [
                  _c("dePwd", {
                    attrs: {
                      placeholder: _vm.$t("components.input_a_password"),
                    },
                    model: {
                      value: _vm.form.configuration.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "password", $$v)
                      },
                      expression: "form.configuration.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === "es"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.user_name") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("components.one_user_name"),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.form.configuration.esUsername,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "esUsername", $$v)
                      },
                      expression: "form.configuration.esUsername",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type === "es"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.password") } },
                [
                  _c("dePwd", {
                    attrs: {
                      placeholder: _vm.$t("components.input_a_password"),
                    },
                    model: {
                      value: _vm.form.configuration.esPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "esPassword", $$v)
                      },
                      expression: "form.configuration.esPassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type !== "es" &&
          _vm.form.type !== "oracle" &&
          _vm.form.type !== "api"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.extra_params") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.$t("fu.search_bar.please_input") +
                        _vm.$t("datasource.extra_params"),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.form.configuration.extraParams,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "extraParams", $$v)
                      },
                      expression: "form.configuration.extraParams",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type !== "es" && _vm.form.type !== "api"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("datasource.port"),
                    prop: "configuration.port",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      type: "number",
                      placeholder: _vm.$t("components.enter_the_port"),
                      min: "0",
                    },
                    model: {
                      value: _vm.form.configuration.port,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.configuration, "port", $$v)
                      },
                      expression: "form.configuration.port",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["oracle", "sqlServer", "pg", "redshift", "db2"].includes(
            _vm.form.type
          )
            ? _c(
                "el-form-item",
                { staticClass: "schema-label" },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.$t("datasource.schema"))),
                        _c("i", { staticClass: "required" }),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-plus",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getSchema()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("datasource.get_schema")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "de-select",
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("fu.search_bar.please_select"),
                      },
                      on: {
                        change: _vm.validatorSchema,
                        blur: _vm.validatorSchema,
                      },
                      model: {
                        value: _vm.form.configuration.schema,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.configuration, "schema", $$v)
                        },
                        expression: "form.configuration.schema",
                      },
                    },
                    _vm._l(_vm.schemas, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                  _vm.configurationSchema
                    ? _c("div", { staticClass: "el-form-item__error" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("datasource.please_choose_schema")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.form.type == "oracle"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.charset") } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "de-select",
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("datasource.please_choose_charset"),
                      },
                      model: {
                        value: _vm.form.configuration.charset,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.configuration, "charset", $$v)
                        },
                        expression: "form.configuration.charset",
                      },
                    },
                    _vm._l(_vm.datasourceType.charset, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == "oracle"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("datasource.targetCharset") } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "de-select",
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t(
                          "datasource.please_choose_targetCharset"
                        ),
                      },
                      model: {
                        value: _vm.form.configuration.targetCharset,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.configuration, "targetCharset", $$v)
                        },
                        expression: "form.configuration.targetCharset",
                      },
                    },
                    _vm._l(_vm.datasourceType.targetCharset, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !["es", "api", "mongo"].includes(_vm.form.type)
            ? _c(
                "span",
                {
                  staticClass: "de-expand de-mar0",
                  on: {
                    click: function ($event) {
                      _vm.showPriority = !_vm.showPriority
                    },
                  },
                },
                [
                  _vm._v(_vm._s(_vm.$t("datasource.priority"))),
                  _vm.showPriority
                    ? _c("i", { staticClass: "el-icon-arrow-up" })
                    : _c("i", { staticClass: "el-icon-arrow-down" }),
                ]
              )
            : _vm._e(),
          _vm.showPriority
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.initial_pool_size"),
                              prop: "configuration.initialPoolSize",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: 0,
                                size: "small",
                              },
                              model: {
                                value: _vm.form.configuration.initialPoolSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "initialPoolSize",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.configuration.initialPoolSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.min_pool_size"),
                              prop: "configuration.minPoolSize",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: 0,
                              },
                              model: {
                                value: _vm.form.configuration.minPoolSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "minPoolSize",
                                    $$v
                                  )
                                },
                                expression: "form.configuration.minPoolSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("datasource.max_pool_size"),
                              prop: "configuration.maxPoolSize",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                autocomplete: "off",
                                type: "number",
                                min: 0,
                              },
                              model: {
                                value: _vm.form.configuration.maxPoolSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.configuration,
                                    "maxPoolSize",
                                    $$v
                                  )
                                },
                                expression: "form.configuration.maxPoolSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _vm.datasourceType.isJdbc
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("datasource.query_timeout"),
                                  prop: "configuration.queryTimeout",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      autocomplete: "off",
                                      type: "number",
                                      min: 0,
                                    },
                                    model: {
                                      value:
                                        _vm.form.configuration.queryTimeout,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.configuration,
                                          "queryTimeout",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.configuration.queryTimeout",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _vm._v(_vm._s(_vm.$t("panel.second"))),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-drawer",
        {
          directives: [{ name: "closePress", rawName: "v-closePress" }],
          attrs: {
            title: _vm.api_table_title,
            visible: _vm.edit_api_item,
            "custom-class": "api-datasource-drawer ds-configuration-input",
            size: "840px",
            "before-close": _vm.closeEditItem,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.edit_api_item = $event
            },
          },
        },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, "align-center": "", space: 144 } },
            [
              _vm.active === 1
                ? _c("el-step", {
                    attrs: { title: _vm.$t("datasource.api_step_1") },
                  })
                : _c("el-step", {
                    attrs: {
                      icon: "el-icon-circle-check",
                      title: _vm.$t("datasource.api_step_1"),
                    },
                  }),
              _c("el-step", {
                attrs: { title: _vm.$t("datasource.api_step_2") },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 1,
                  expression: "active === 1",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "apiItemBasicInfo",
                  attrs: {
                    size: "small",
                    model: _vm.apiItem,
                    "label-position": "top",
                    "label-width": "100px",
                    rules: _vm.rule,
                  },
                },
                [
                  _c("div", { staticClass: "row-rules" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("datasource.base_info"))),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("commons.name"), prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("commons.input_name"),
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.apiItem.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.apiItem, "name", $$v)
                          },
                          expression: "apiItem.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("datasource.request"),
                        prop: "url",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: _vm.$t("datasource.path_all_info"),
                            size: "small",
                          },
                          model: {
                            value: _vm.apiItem.url,
                            callback: function ($$v) {
                              _vm.$set(_vm.apiItem, "url", $$v)
                            },
                            expression: "apiItem.url",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: { slot: "prepend", size: "small" },
                              slot: "prepend",
                              model: {
                                value: _vm.apiItem.method,
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiItem, "method", $$v)
                                },
                                expression: "apiItem.method",
                              },
                            },
                            _vm._l(_vm.reqOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "row-rules mr40" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("datasource.req_param"))),
                        ]),
                      ]),
                      _c(
                        "el-form-item",
                        [
                          _vm.edit_api_item
                            ? _c("api-http-request-form", {
                                attrs: {
                                  headers: _vm.apiItem.request.headers,
                                  request: _vm.apiItem.request,
                                  response: _vm.responseData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("datasource.isUseJsonPath") } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            disabled: !_vm.apiItem.useJsonPath,
                            placeholder: _vm.$t("datasource.jsonpath_info"),
                            size: "small",
                          },
                          model: {
                            value: _vm.apiItem.jsonPath,
                            callback: function ($$v) {
                              _vm.$set(_vm.apiItem, "jsonPath", $$v)
                            },
                            expression: "apiItem.jsonPath",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: { slot: "prepend", size: "small" },
                              slot: "prepend",
                              model: {
                                value: _vm.apiItem.useJsonPath,
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiItem, "useJsonPath", $$v)
                                },
                                expression: "apiItem.useJsonPath",
                              },
                            },
                            _vm._l(_vm.isUseJsonPath, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "append",
                                disabled: !_vm.apiItem.useJsonPath,
                              },
                              on: { click: _vm.showApiData },
                              slot: "append",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("datasource.show_api_data")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.apiItem.useJsonPath,
                          expression: "apiItem.useJsonPath",
                        },
                      ],
                      staticClass: "row-rules",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("datasource.column_info"))),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.apiItem.useJsonPath,
                          expression: "apiItem.useJsonPath",
                        },
                      ],
                      staticClass: "table-container de-svg-in-table",
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "apiItemTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.originFieldItem.jsonFields,
                            "row-key": "jsonPath",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "class-name": "checkbox-table",
                              prop: "originName",
                              label: _vm.$t("dataset.parse_filed"),
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.originName) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 2,
                  expression: "active === 2",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "apiItem",
                  attrs: {
                    size: "small",
                    model: _vm.apiItem,
                    "label-position": "top",
                    "label-width": "100px",
                    rules: _vm.rule,
                  },
                },
                [
                  _c("div", { staticClass: "row-rules" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("datasource.column_info"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "table-container de-svg-in-table" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "apiItemTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.apiItem.jsonFields,
                            "row-key": "jsonPath",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "class-name": "checkbox-table",
                              prop: "originName",
                              label: _vm.$t("dataset.parse_filed"),
                              "show-overflow-tooltip": true,
                              width: "255",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-checkbox",
                                      {
                                        key: scope.row.jsonPath,
                                        attrs: {
                                          disabled:
                                            scope.row.disabled ||
                                            _vm.apiItem.useJsonPath,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleCheckAllChange(
                                              _vm.apiItem,
                                              scope.row,
                                              "plxTable"
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.checked,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "checked", $$v)
                                          },
                                          expression: "scope.row.checked",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.originName) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: _vm.$t("dataset.field_rename"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: scope.row.children,
                                        size: "small",
                                        type: "text",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.fieldNameChange(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "name", $$v)
                                        },
                                        expression: "scope.row.name",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "deExtractType",
                              label: _vm.$t("dataset.field_type"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "select-type",
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "120px",
                                        },
                                        attrs: {
                                          disabled: scope.row.children,
                                          size: "small",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.fieldTypeChange(
                                              scope.row
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.deExtractType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "deExtractType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.deExtractType",
                                        },
                                      },
                                      _vm._l(_vm.fieldOptions, function (item) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { float: "left" },
                                              },
                                              [
                                                item.value === "0"
                                                  ? _c("svg-icon", {
                                                      staticClass:
                                                        "field-icon-text",
                                                      attrs: {
                                                        "icon-class":
                                                          "field_text",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                item.value === "2" ||
                                                item.value === "3"
                                                  ? _c("svg-icon", {
                                                      staticClass:
                                                        "field-icon-value",
                                                      attrs: {
                                                        "icon-class":
                                                          "field_value",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  float: "left",
                                                  color: "#8492a6",
                                                  "font-size": "12px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "select-svg-icon" },
                                      [
                                        scope.row.deType === 0
                                          ? _c(
                                              "span",
                                              [
                                                _c("svg-icon", {
                                                  staticClass:
                                                    "field-icon-text",
                                                  attrs: {
                                                    "icon-class": "field_text",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        [2, 3].includes(scope.row.deType)
                                          ? _c(
                                              "span",
                                              [
                                                _c("svg-icon", {
                                                  staticClass:
                                                    "field-icon-value",
                                                  attrs: {
                                                    "icon-class": "field_value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row-rules" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dataset.data_preview"))),
                    ]),
                  ]),
                  _vm.showEmpty
                    ? _c("el-empty", {
                        staticStyle: { "margin-top": "24px" },
                        attrs: {
                          size: "125",
                          image: _vm.noneImg,
                          description: _vm.$t("暂无数据，请在数据结构勾选字段"),
                        },
                      })
                    : _c(
                        "ux-grid",
                        {
                          ref: "plxTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            height: _vm.height,
                            "checkbox-config": { highlight: true },
                            "width-resize": true,
                          },
                        },
                        _vm._l(_vm.apiItem.fields, function (field) {
                          return _c(
                            "ux-table-column",
                            {
                              key: field.name + field.deExtractType,
                              attrs: {
                                "min-width": "200px",
                                field: field.name,
                                resizable: true,
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  field.deExtractType === 0
                                    ? _c("svg-icon", {
                                        staticClass: "field-icon-text",
                                        attrs: { "icon-class": "field_text" },
                                      })
                                    : _vm._e(),
                                  field.deExtractType === 1
                                    ? _c("svg-icon", {
                                        staticClass: "field-icon-time",
                                        attrs: { "icon-class": "field_time" },
                                      })
                                    : _vm._e(),
                                  field.deExtractType === 2 ||
                                  field.deExtractType === 3
                                    ? _c("svg-icon", {
                                        staticClass: "field-icon-value",
                                        attrs: { "icon-class": "field_value" },
                                      })
                                    : _vm._e(),
                                  field.deExtractType === 5
                                    ? _c("svg-icon", {
                                        staticClass: "field-icon-location",
                                        attrs: {
                                          "icon-class": "field_location",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(field.name))]),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "foot" },
            [
              _c(
                "el-button",
                { staticClass: "btn normal", on: { click: _vm.closeEditItem } },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 1,
                      expression: "active === 1",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary", disabled: _vm.disabledNext },
                  on: { click: _vm.next },
                },
                [_vm._v(_vm._s(_vm.$t("fu.steps.next")) + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 2,
                      expression: "active === 2",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.before },
                },
                [_vm._v(_vm._s(_vm.$t("fu.steps.prev")) + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 2,
                      expression: "active === 2",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveItem },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }