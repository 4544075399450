var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 16 } },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "radio-span",
                    attrs: { size: "mini" },
                    on: { change: _vm.onChangePanelStyle },
                    model: {
                      value: _vm.panel.resultMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.panel, "resultMode", $$v)
                      },
                      expression: "panel.resultMode",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "all" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("panel.view")))]),
                    ]),
                    _c("el-radio", { attrs: { label: "custom" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("panel.panel")) + " ")]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "slider-area", attrs: { span: 8 } },
              [
                _c("el-slider", {
                  staticStyle: { "margin-left": "5px" },
                  attrs: {
                    disabled: _vm.panel.resultMode === "all",
                    "show-input": "",
                    "show-input-controls": false,
                    "show-tooltip": false,
                    "input-size": "mini",
                    min: 1,
                    max: 10000,
                  },
                  on: { change: _vm.onChangePanelStyle },
                  model: {
                    value: _vm.panel.resultCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.panel, "resultCount", $$v)
                    },
                    expression: "panel.resultCount",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-row", [
          _c(
            "span",
            {
              staticStyle: {
                color: "#909399",
                "font-size": "8px",
                "margin-left": "3px",
              },
            },
            [
              _vm._v(
                "\n        Tips: " +
                  _vm._s(_vm.$t("panel.panel_view_result_tips")) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }