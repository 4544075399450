var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "children-node node-container",
      style: { height: _vm.nodeHeight },
    },
    [
      _c(
        "div",
        { staticClass: "node-line" },
        [
          _vm.childrenNode.unionToParent.unionType === "left"
            ? _c("svg-icon", {
                staticClass: "join-icon",
                attrs: { "icon-class": "left-join" },
                on: { click: _vm.unionEdit },
              })
            : _vm.childrenNode.unionToParent.unionType === "right"
            ? _c("svg-icon", {
                staticClass: "join-icon",
                attrs: { "icon-class": "right-join" },
                on: { click: _vm.unionEdit },
              })
            : _vm.childrenNode.unionToParent.unionType === "inner"
            ? _c("svg-icon", {
                staticClass: "join-icon",
                attrs: { "icon-class": "inner-join" },
                on: { click: _vm.unionEdit },
              })
            : _c("svg-icon", {
                staticClass: "join-icon",
                attrs: { "icon-class": "no-join" },
                on: { click: _vm.unionEdit },
              }),
          _c("svg", { staticClass: "join-svg-container" }, [
            _c("path", {
              attrs: {
                fill: "none",
                stroke: "#dcdfe6",
                d: _vm.pathParam + _vm.lineLength,
              },
            }),
          ]),
        ],
        1
      ),
      _c("node-item", {
        attrs: {
          "current-node": _vm.childrenNode,
          "node-index": _vm.nodeIndex,
          "origin-data": _vm.originData,
        },
        on: {
          deleteNode: _vm.deleteNode,
          notifyParent: _vm.calc,
          editUnion: _vm.unionConfig,
          cancelUnionEdit: _vm.cancelUnion,
        },
      }),
      _c(
        "div",
        _vm._l(_vm.childrenNode.childrenDs, function (item, index) {
          return _c("union-node", {
            key: index,
            attrs: {
              "node-index": index,
              "children-node": item,
              "children-list": _vm.childrenNode.childrenDs,
              "parent-node": _vm.childrenNode,
              "origin-data": _vm.originData,
            },
            on: { notifyParent: _vm.calc, cancelUnionEdit: _vm.cancelUnion },
          })
        }),
        1
      ),
      _vm.editUnion
        ? _c(
            "el-drawer",
            {
              directives: [{ name: "closePress", rawName: "v-closePress" }],
              attrs: {
                title:
                  _vm.unionParam.type === "add"
                    ? _vm.$t("dataset.add_union_relation")
                    : _vm.$t("dataset.edit_union_relation"),
                visible: _vm.editUnion,
                "custom-class": "de-user-drawer union-dataset-drawer",
                size: "840px",
                direction: "rtl",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editUnion = $event
                },
              },
            },
            [
              _c("union-edit", { attrs: { "union-param": _vm.unionParam } }),
              _c(
                "div",
                { staticClass: "de-foot" },
                [
                  _c(
                    "deBtn",
                    {
                      attrs: { secondary: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeEditUnion()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.cancel")))]
                  ),
                  _c(
                    "deBtn",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmEditUnion()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataset.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }