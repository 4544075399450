var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "union-container" }, [
    _c("div", { staticClass: "union-header" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("dataset.union_relation")) + "\n    "),
      _c(
        "div",
        { staticClass: "union-header-operator" },
        [
          _c(
            "span",
            { staticClass: "select-svg-icon" },
            [
              _vm.unionParam.node.unionToParent.unionType === "left"
                ? _c("svg-icon", {
                    staticClass: "join-icon",
                    attrs: { "icon-class": "left-join" },
                  })
                : _vm.unionParam.node.unionToParent.unionType === "right"
                ? _c("svg-icon", {
                    staticClass: "join-icon",
                    attrs: { "icon-class": "right-join" },
                  })
                : _vm.unionParam.node.unionToParent.unionType === "inner"
                ? _c("svg-icon", {
                    staticClass: "join-icon",
                    attrs: { "icon-class": "inner-join" },
                  })
                : _c("svg-icon", {
                    staticClass: "join-icon",
                    attrs: { "icon-class": "no-join" },
                  }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "union-selector",
              attrs: { size: "small" },
              model: {
                value: _vm.unionParam.node.unionToParent.unionType,
                callback: function ($$v) {
                  _vm.$set(_vm.unionParam.node.unionToParent, "unionType", $$v)
                },
                expression: "unionParam.node.unionToParent.unionType",
              },
            },
            _vm._l(_vm.unionOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "deBtn",
            {
              staticClass: "union-add",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addUnion },
            },
            [_vm._v(_vm._s(_vm.$t("dataset.add_union_field")))]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "union-body" }, [
      _c("div", { staticClass: "union-body-header" }, [
        _c(
          "span",
          {
            staticClass: "column",
            attrs: { title: _vm.unionParam.parent.currentDs.name },
          },
          [_vm._v(_vm._s(_vm.unionParam.parent.currentDs.name))]
        ),
        _c(
          "span",
          {
            staticClass: "column",
            attrs: { title: _vm.unionParam.node.currentDs.name },
          },
          [_vm._v(_vm._s(_vm.unionParam.node.currentDs.name))]
        ),
        _c("span", { staticClass: "column-last" }, [
          _vm._v(_vm._s(_vm.$t("dataset.operator"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "union-body-container" },
        _vm._l(
          _vm.unionParam.node.unionToParent.unionFields,
          function (field, index) {
            return _c(
              "div",
              { key: index, staticClass: "union-body-item" },
              [
                _c(
                  "span",
                  { staticClass: "column" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "select-field",
                        attrs: {
                          placeholder: _vm.$t("dataset.pls_slc_union_field"),
                          filterable: "",
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: field.parentField.id,
                          callback: function ($$v) {
                            _vm.$set(field.parentField, "id", $$v)
                          },
                          expression: "field.parentField.id",
                        },
                      },
                      _vm._l(_vm.parentFieldList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          },
                          [
                            _c("span", [
                              item.deType === 0
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 0
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-text",
                                            attrs: {
                                              "icon-class": "field_text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.deType === 1
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 1
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-time",
                                            attrs: {
                                              "icon-class": "field_time",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.deType === 2 || item.deType === 3
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 2 || item.deType === 3
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-value",
                                            attrs: {
                                              "icon-class": "field_value",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.deType === 5
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 5
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-location",
                                            attrs: {
                                              "icon-class": "field_location",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.name) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("svg-icon", {
                  staticClass: "join-icon",
                  attrs: { "icon-class": "join-join" },
                }),
                _c(
                  "span",
                  { staticClass: "column" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "select-field",
                        attrs: {
                          placeholder: _vm.$t("dataset.pls_slc_union_field"),
                          filterable: "",
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: field.currentField.id,
                          callback: function ($$v) {
                            _vm.$set(field.currentField, "id", $$v)
                          },
                          expression: "field.currentField.id",
                        },
                      },
                      _vm._l(_vm.nodeFieldList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          },
                          [
                            _c("span", [
                              item.deType === 0
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 0
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-text",
                                            attrs: {
                                              "icon-class": "field_text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.deType === 1
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 1
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-time",
                                            attrs: {
                                              "icon-class": "field_time",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.deType === 2 || item.deType === 3
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 2 || item.deType === 3
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-value",
                                            attrs: {
                                              "icon-class": "field_value",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.deType === 5
                                ? _c(
                                    "span",
                                    [
                                      item.deType === 5
                                        ? _c("svg-icon", {
                                            staticClass: "field-icon-location",
                                            attrs: {
                                              "icon-class": "field_location",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.name) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "column-last" },
                  [
                    _c("el-button", {
                      attrs: {
                        disabled:
                          _vm.unionParam.node.unionToParent.unionFields &&
                          _vm.unionParam.node.unionToParent.unionFields
                            .length === 1,
                        type: "text",
                        icon: "el-icon-delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeUnionItem(index)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }
        ),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }