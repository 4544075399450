import { render, staticRenderFns } from "./DsConfiguration.vue?vue&type=template&id=2eed551a&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./DsConfiguration.vue?vue&type=script&lang=js&"
export * from "./DsConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./DsConfiguration.vue?vue&type=style&index=0&id=2eed551a&lang=scss&"
import style1 from "./DsConfiguration.vue?vue&type=style&index=1&id=2eed551a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eed551a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\zhich\\Desktop\\ZcyProject\\zcy-dataEase\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2eed551a')) {
      api.createRecord('2eed551a', component.options)
    } else {
      api.reload('2eed551a', component.options)
    }
    module.hot.accept("./DsConfiguration.vue?vue&type=template&id=2eed551a&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&", function () {
      api.rerender('2eed551a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/datasource/DsConfiguration.vue"
export default component.exports