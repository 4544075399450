var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-preview" },
    [
      _c(
        "ux-grid",
        {
          ref: "plxTable",
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            height: _vm.height,
            "checkbox-config": { highlight: true },
            "width-resize": true,
          },
        },
        _vm._l(_vm.fields, function (field) {
          return _c("ux-table-column", {
            key: field.fieldName,
            attrs: {
              "min-width": "200px",
              field: field.fieldName,
              title: field.remarks,
              resizable: true,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }