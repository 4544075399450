var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        ((_obj = {}), (_obj["template-item-main-active"] = _vm.active), _obj),
        "template-item-main",
      ],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.previewTemplate($event)
        },
      },
    },
    [
      _c("div", {
        staticClass: "template-item-img",
        style: _vm.classBackground,
      }),
      _c("span", { staticClass: "demonstration" }, [
        _vm._v(_vm._s(_vm.template.name)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }