import { render, staticRenderFns } from "./index.vue?vue&type=template&id=114e3f8a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=114e3f8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "114e3f8a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\zhich\\Desktop\\ZcyProject\\zcy-dataEase\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('114e3f8a')) {
      api.createRecord('114e3f8a', component.options)
    } else {
      api.reload('114e3f8a', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=114e3f8a&scoped=true&", function () {
      api.rerender('114e3f8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/about/index.vue"
export default component.exports