var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-form",
        {
          ref: "viewAttributeForm",
          attrs: {
            model: _vm.viewAttributeForm,
            "label-width": "70px",
            size: "mini",
          },
        },
        [
          _vm._v("background_color\n    "),
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: _vm.$t("panel.background_color") },
            },
            [
              _c("el-color-picker", {
                staticStyle: { cursor: "pointer", "z-index": "1004" },
                attrs: { predefine: _vm.predefineColors, size: "mini" },
                on: { change: _vm.viewAttributeChange },
                model: {
                  value: _vm.viewAttributeForm.color,
                  callback: function ($$v) {
                    _vm.$set(_vm.viewAttributeForm, "color", $$v)
                  },
                  expression: "viewAttributeForm.color",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: _vm.$t("panel.opacity") },
            },
            [
              _c("el-slider", {
                attrs: {
                  "show-input": "",
                  "show-input-controls": false,
                  "input-size": "mini",
                },
                model: {
                  value: _vm.viewAttributeForm.alpha,
                  callback: function ($$v) {
                    _vm.$set(_vm.viewAttributeForm, "alpha", $$v)
                  },
                  expression: "viewAttributeForm.alpha",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }