var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "de-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.getters.loadingMap[_vm.$store.getters.currentPath],
          expression: "$store.getters.loadingMap[$store.getters.currentPath]",
        },
      ],
    },
    [_c("ds-main", { ref: "dsMain" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }